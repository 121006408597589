import React from 'react';
import { Tour } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, RollbackOutlined } from '@ant-design/icons';
import { MenuOutlined, BulbOutlined } from '@ant-design/icons';

export const CustomTour = (props) => {



    const actionNameCapped = props.isTouch ? 'Tap' : 'Click';
    const tooltipNameCapped = props.isTouch ? 'Tap' : 'Hover over';



    const steps = [
        {
            title: 'Pick a day from the calendar dropdown',
            description: <div>{`${actionNameCapped}`}<CaretLeftOutlined /> or <CaretRightOutlined />to quickly change one day.</div>,
            cover: (
                <img
                    alt="donut0.gif"
                    src="/images/donut0.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: `24-hour overview of your day`,
            description: <div>{`${tooltipNameCapped}`} to see the details of each event.{props.isTouch ? ' Tap anywhere else to hide these details' : ''}</div>,
            cover: (
                <img
                    alt="donut1.gif"
                    src="/images/donut1.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: `Connect events to see the duration`,
            description: <div>Connect events on the <RollbackOutlined style={{ transform: 'rotate(180deg) scaleX(-1)' }} /> page to see them reflected here.</div>,
            cover: (
                <img
                    alt="donut2.gif"
                    src="/images/donut2.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
            // nextButtonProps: { onClick: props.onFinishTour }
        },
        {
            title: `Swipe to pan and pinch to zoom...`,
            description: <div>if you are on a touch screen; if not, drag to pan and mouse wheel (middle button) scroll to zoom.</div>,
            cover: (
                <img
                    alt="donut3.gif"
                    src="/images/donut3.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
            nextButtonProps: { onClick: props.onFinishTour }  // this is the second last step, but consider it finished if click next -- so that if user closes the tour by cross, it will not show again
        },
        {
            title: 'That\'s all for this page! There are more tips on other pages.',
            description: <> To see these tips again, press <BulbOutlined /> from the <MenuOutlined /> menu </>,
            nextButtonProps: { onClick: props.onFinishTour }
        },
    ];

    return <Tour
        open={props.open}
        steps={steps}
        onClose={props.onCloseTour}
        disabledInteraction={true}
        style={{ maxWidth: props.screenWidth }}
    // mask={false}
    />

};


import React, { Component } from 'react';
import {
  Spin, Result, Row, Modal
} from 'antd';
import { checkActionCode, applyActionCode, reload } from 'firebase/auth';
import * as ROUTES from "../../constants/routes";


export default class VerifyEmail extends Component {
  state = {
    error: '',
    validCode: null,
    verifiedCode: false,
    timeoutId: null,
    showModal: false,
  }



  handleSignOut = () => {
    const { firebase, actionCode } = this.props;
    this.setState({ showModal: false });
    localStorage.setItem('pendingMessages', null);
    firebase.auth.signOut().then(() => {
      this.verifyEmail(actionCode);

    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  // promptForAccountSwitch = () => {
  //   Modal.confirm({
  //     title: 'You are signed in as a different user',
  //     content: `Would you like to sign out and try again? If you are in the middle of something, you'll lose unsaved data if you sign out now. You can also cancel and sign out at another time.`,
  //     onOk: () => this.handleSignOut(),
  //     onCancel: () => {
  //       this.setState({ error: 'signed in as a different user', validCode: false, verifiedCode: true })
  //     },
  //     okText: 'Sign Out',
  //     cancelText: 'Cancel'
  //   });
  // };

  promptForAccountSwitch = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false, error: "signed in as a different user", validCode: false, verifiedCode: true });
  };




  //authUser is likely null, since it's opening a link from an email, not signed in
  verifyEmail = () => {
    const { firebase, navigate, actionCode } = this.props;
    applyActionCode(firebase.auth, actionCode)
      .then(() => {
        // Email address has been verified. Proceed to redirect user
        // if authUser, then redirect to home, else redirect to landing
        this.setState({ validCode: true, verifiedCode: true }, () => {
          // Set a timeout and save its ID
          const timeoutId = setTimeout(() => {
            if (firebase.auth?.currentUser) {
              this.onClickHome();
            } else {
              navigate(ROUTES.LANDING);
            }
          }, 3000);
          this.setState({ timeoutId });
        });

      }).catch(error => {
        // Error in applying the code.
        this.setState({ error, validCode: false, verifiedCode: true });
      });
  };


  // Lifecycle method to clear the timeout if the component unmounts
  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);  // Clear the timeout using the saved ID
    }
  }


  componentDidMount() {

    const { firebase, actionCode } = this.props;

    checkActionCode(firebase.auth, actionCode)
      .then((info) => {

        // console.log('info is', info.data, 'user is', firebase.auth.currentUser);

        // No user is signed in, or currently signed-in user is the one who the action code is for, so just proceed with verification
        if (!firebase.auth.currentUser || firebase.auth.currentUser.uid === info.data.userId || (!info.data.userId && info.data.email === firebase.auth.currentUser.email)) {
          this.verifyEmail();
        }
        else {
          this.promptForAccountSwitch();
        }

      }).catch(error => {
        // Action code is invalid or expired.
        this.setState({ error, validCode: false, verifiedCode: true });
      });

  }


  onClickHome = e => {
    e?.preventDefault();

    // reload so that the emailVerified property is updated, hence the homepage is no longer locked by withEmailVerification
    reload(this.props.firebase.auth.currentUser)
      .then(() => {
        this.props.navigate(ROUTES.HOME);
      })
      .catch(error => console.error(error.message));
  }

  renderModal() {
    return (
      <Modal
        title="You are signed in as a different user"
        open={this.state.showModal}
        onOk={this.handleSignOut}
        onCancel={this.closeModal}
        okText="Sign Out"
        cancelText="Cancel"
      >
        <p>Would you like to sign out and try again? If you are in the middle of something, you'll lose unsaved data if you sign out now. You can also cancel and sign out at another time.</p>
      </Modal>
    );
  }

  render() {
    const {
      error,
      validCode,
      verifiedCode,
    } = this.state;

    let component;

    if (!verifiedCode) {
      component = <Row type="flex" justify="center" style={{ margin: "80px auto" }}><Spin /></Row>;
    } else if (verifiedCode && validCode) {
      component = (
        <Result
          status="success"
          title="Your email has been verified"
          subTitle="Redirecting you..."
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    } else if (verifiedCode && !validCode) {
      const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || error;
      component = (
        <Result
          status="warning"
          title="There was an issue verifying your email address"
          subTitle={formattedError}
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    }

    return (
      <>
        {this.renderModal()}
        {component}
      </>
    );
  }
}

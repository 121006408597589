import React, { useContext, useState, useEffect } from 'react';
import { Button, Popconfirm, Modal, Input, Checkbox } from 'antd';
import { FirebaseContext } from '../Firebase';
import { reauthenticateWithCredential, EmailAuthProvider, signOut } from "firebase/auth";
import { deleteAccount } from '../../functions';
import { useNavigate } from 'react-router-dom';


const DeleteAccount = ({ authUser }) => {
    const firebase = useContext(FirebaseContext);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);


    const navigate = useNavigate(); // Use useNavigate hook for navigation

    useEffect(() => {
        // This cleanup function runs when the component unmounts
        return () => {
            // Clear the timeout if it exists
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]); // Dependencies array includes timeoutId

    const handleDeleteAccount = async () => {
        const user = firebase.auth.currentUser;
        const credential = EmailAuthProvider.credential(user?.email, password);
        setLoading(true);

        try {
            await reauthenticateWithCredential(user, credential);
            // console.log('Re-authentication successful.');

            // Fetch the new token after re-authentication
            const firebaseToken = await user.getIdToken(true);

            // Now call your delete account API
            await deleteAccount(firebaseToken)
                .then(response => {
                    // console.log('Account deletion successful:', response.data);
                    setCompleted(1);
                    setLoading(false);
                    localStorage.setItem('pendingMessages', null);

                    const id = setTimeout(() => {
                        signOut(firebase.auth);
                        navigate('/'); // Redirect to the home page or login page
                    }, 3000); // Delay
                    // Handle further actions after successful deletion, like redirecting

                    // Store the timeout ID in state
                    setTimeoutId(id);
                })
                .catch(error => {
                    setCompleted(-1);
                    // console.error('Failed to delete account:', error);
                    const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || 'server error in deleting account';
                    setErrorMsg(formattedError);
                    setLoading(false);
                });



        } catch (error) {
            // console.error('Re-authentication failed:', error);
            const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || 'error authenticating';
            setErrorMsg(formattedError);
            setCompleted(-1);
            setLoading(false);
        }

    };

    const confirmDelete = () => {
        setIsModalVisible(true);
    };


    const handleModalCancel = () => {
        setIsModalVisible(false);
        setIsCheckboxChecked(false);
        setPassword('');
        setLoading(false);
        setCompleted(0);
        setErrorMsg('');
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <Popconfirm
                title="Are you sure you want to delete your account?"
                onConfirm={confirmDelete}
                okText="Proceed"
                cancelText="Cancel"
            >
                <Button danger>Delete My Account</Button>
            </Popconfirm>


            <Modal
                title={"Delete Account"}
                open={isModalVisible}
                onOk={handleDeleteAccount}
                onCancel={handleModalCancel}
                okText="Delete Account"
                cancelText="Cancel"
                okButtonProps={{ disabled: !isCheckboxChecked || !password, loading: loading }}
                footer={completed === 1 || completed === -1 ? null : undefined}
            >

                <p>{authUser?.email}</p>

                {completed === 1 && <p>Your account has been successfully deleted. Redirecting you shortly...</p>}
                {completed === -1 && <p>Failed to delete your account. The error message is "{errorMsg}". Please try again.</p>}

                {completed === 0 && <> <Checkbox
                    checked={isCheckboxChecked}
                    onChange={e => setIsCheckboxChecked(e.target.checked)}
                    style={{ margin: '20px' }}
                >
                    I understand this will permanently delete my account and all associated data. This action cannot be undone and all data will be irretrievable.
                </Checkbox>

                    <p>Please enter your password to confirm deletion:</p>

                    <Input.Password
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"
                        style={{ marginBottom: '20px' }}
                    />
                </>}
            </Modal>
        </div >
    );
};

export default DeleteAccount;







// import React, { useContext } from 'react';
// import { Button, Popconfirm } from 'antd';
// import { FirebaseContext } from '../Firebase';
// import { signOut } from "firebase/auth";

// const DeleteAccount = (props) => {

//     const firebase = useContext(FirebaseContext);

//     const confirmDelete = () => {
//         console.log('Delete account confirmed');
//     }


//     return (
//         <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>

//             <Popconfirm
//                 // key="comment-basic-delete"
//                 title="Are you sure you want to delete your account?"
//                 description="This will permanently delete your account and all associated data. Are you sure you want to proceed?"
//                 onConfirm={confirmDelete}
//                 okText="Proceed"
//                 cancelText="Cancel"
//                 style={{ margin: 20 }}
//             >
//                 <Button danger>Delete My Account</Button>
//             </Popconfirm >

//             <Button onClick={() => signOut(firebase.auth)}>Sign Out</Button>
//         </div >
//     );
// };

// export default DeleteAccount;

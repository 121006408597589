// ChatList.js
import React, { useCallback } from 'react';
import { List } from 'antd';
import ChatItem from './chatItem';


const ChatList = ({ chat, handleResend, handleDelete, handleDetailsToggle, handleTeachBot, db, uid, fetchEventDetailsForItem, eventDetailsMap, itemRefs, header, isOnline, demo = false }) => {

    // console.log('chatList rendering');


    const renderItem = useCallback((item) => (
        <ChatItem
            key={item.time}
            item={item}
            onResend={handleResend}
            onDelete={handleDelete}
            onDetailsToggle={() => handleDetailsToggle(item.time)}
            handleTeachBot={() => handleTeachBot(item)}
            fetchEventDetails={() => fetchEventDetailsForItem(item)}
            eventDetails={eventDetailsMap[item.time]}
            db={db}
            uid={uid}
            isOnline={isOnline}
            demo={demo}
        // itemRef={(el) => setChatItemRef(item.time.toString(), el)}
        />
    ), [handleResend, handleDelete, handleTeachBot, handleDetailsToggle, db, uid, fetchEventDetailsForItem, eventDetailsMap, isOnline]);


    return (
        <List
            className="chat-list"
            itemLayout="horizontal"
            dataSource={chat}
            renderItem={renderItem}
            bordered
            header={header}
            locale={{ emptyText: '0 recent notes' }}
        />

    );
};

export default React.memo(ChatList);
// export default ChatList;



import { siteName, siteUrl } from '../../constants';


export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: `${process.env.PUBLIC_URL}/images/logo_full.svg`,
  },
  content: {
    className: 'banner0-content',
    children: 'Time Told, Moments Held',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};

export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'How is Tellti.me different?' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${process.env.PUBLIC_URL}/images/parrot_talk.svg`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Easily record your time',
            },
            { name: 'content', children: 'No more struggling with finicky inputs, or having to remember to start and stop timers. Just talk to the Tellti.me Parrot.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${process.env.PUBLIC_URL}/images/parrot_context.svg`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'You are understood',
            },
            {
              name: 'content',
              children: 'The Parrot knows the context, so you don\'t have to repeat yourself. It also knows to link your start and end times for you.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${process.env.PUBLIC_URL}/images/parrot_charts.svg`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Easy to find insights',
            },
            {
              name: 'content',
              children: 'With charts to help you find patterns and rhythms in your day, you can experiment to streamline your work and find good routines.',
            },
          ],
        },
      },
    ],
  },
};


export const SimDataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.2, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Try out some examples' }],
  }
};

export const availabilityDataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.2, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Web, phone and tablet' }],
  }
};



export const footer = [
  // {
  //   title: '',
  //   icon: `${process.env.PUBLIC_URL}/images/logo_text.svg`,
  //   // children: [{ title: 'Tellti.me', link: '/' },]
  // },
  {
    title: 'About',
    children: [
      { title: siteName, link: '/about' },
    ],
  },
  {
    title: 'Terms',
    children: [
      { title: 'Terms of Service', link: "/terms" },
      { title: 'Privacy Policy', link: "/privacy" },
    ],
  },
  {
    title: 'Help',
    children: [
      { title: 'Contact', link: '/help' },
    ],
  },
];

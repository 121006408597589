import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { ref, query, orderByChild, startAt, endAt, get, update } from 'firebase/database';

export const fetchRecordsForDate = async (uid, date, timezone, db) => {

    const startDate = moment.tz(date, timezone).startOf('day').unix();
    const endDate = moment.tz(date, timezone).endOf('day').unix();
    const userRecordsRef = ref(db, `u_records/${uid}`);

    if (startDate && endDate && uid) {
        // Define the queries for end_time and time
        // here the +1 and -1 is to remove any record that starts exactly at day end, or ends exactly at day start
        const endRecordsQuery = query(userRecordsRef, orderByChild('end_time'), startAt(startDate + 1), endAt(endDate));
        const timeRecordsQuery = query(userRecordsRef, orderByChild('time'), startAt(startDate), endAt(endDate - 1));

        // Execute both queries in parallel
        const [endRecordsSnapshot, timeRecordsSnapshot] = await Promise.all([get(endRecordsQuery), get(timeRecordsQuery)]);
        const endRecords = endRecordsSnapshot.val() || {};
        const timeRecords = timeRecordsSnapshot.val() || {};

        // Merge results, excluding duplicates
        const fetchedKeys = new Set(Object.keys(endRecords));
        for (const key in timeRecords) {
            if (!fetchedKeys.has(key)) {
                endRecords[key] = timeRecords[key];
            }
        }

        return endRecords;
    } else {
        return {};
    }
};


// Fetch edits for the records from u_edits
export const fetchEditsForDate = async (uid, recordIds, db) => {
    const edits = {};

    // Prepare promises for parallel fetching
    const fetchPromises = recordIds.map((recordId) => {
        const recordEditRef = ref(db, `u_edits/${uid}/${recordId}`);
        return get(recordEditRef)
            .then(snapshot => {
                const editData = snapshot.val();
                if (editData) {
                    edits[recordId] = editData;
                }
            })
            .catch(error => {
                console.error(`Error fetching edit for record ${recordId}:`, error);
            });
    });

    // Execute all promises in parallel
    await Promise.all(fetchPromises);

    return edits;
};




export const fetchDataProcess = async (uid, date, timezone, db) => {

    const startDate = moment.tz(date, timezone).startOf('day').unix();
    const endDate = moment.tz(date, timezone).endOf('day').unix();


    // Fetch original records
    const records = await fetchRecordsForDate(uid, date, timezone, db);
    // Fetch edits for those records
    let edits = await fetchEditsForDate(uid, Object.keys(records), db);


    let editedData = [];
    let toFetch = {};

    // Iterate over records to apply edits or split them as necessary
    for (const [recordId, item] of Object.entries(records)) {
        const editInfo = edits[recordId];

        let record = {
            ...item,
            time: editInfo?.time ?? item.time,
            event: editInfo?.name ?? item.event,
            recordId,
        };

        if (editInfo && editInfo.end_time) {
            record.end_time = editInfo.end_time;
        }

        if (editInfo && editInfo['split_span']) {
            // Handle split_span by creating two separate records
            const startTimeRecord = { ...record, event: `${item.event} (start)` };
            const endTimeRecord = { ...record, event: `${item.event} (end)`, time: record.end_time };

            editedData.push(startTimeRecord);
            editedData.push(endTimeRecord);
            continue;
        }

        if (editInfo && editInfo['span']) {
            const keys = Object.keys(editInfo['span']);
            for (const targetKey of keys) {
                if (toFetch[targetKey]) {
                    // If the key already exists, add to its existing value
                    toFetch[targetKey][recordId] = 'source';
                } else {
                    // If the key doesn't exist, initialize it
                    toFetch[targetKey] = { [recordId]: 'source' };
                }
            }
        }



        if (editInfo && editInfo['of_span']) {
            const keys = Object.keys(editInfo['of_span']);
            for (const sourceKey of keys) {
                if (toFetch[sourceKey]) {
                    // If the key already exists, add to its existing value
                    toFetch[sourceKey][recordId] = 'target';
                } else {
                    // If the key doesn't exist, initialize it
                    toFetch[sourceKey] = { [recordId]: 'target' };
                }
            }
        }


        editedData.push(record);

    };


    // Fetch other records that are part of a span
    // remove any that's already in the fetched records
    const toFetchFiltered = Object.keys(toFetch).filter(key => !(key in records));

    let records2add = [];

    if (toFetchFiltered.length > 0) {
        const uEditsRef = ref(db, `u_edits/${uid}`);

        try {

            const fetchPromises = toFetchFiltered.map(key => get(ref(db, `u_records/${uid}/${key}`)));
            const results = await Promise.all(fetchPromises);
            let obsoleteKeys = [];
            const records = results.map((snapshot, index) => {
                if (snapshot.exists()) {
                    return { ...snapshot.val(), recordId: toFetchFiltered[index] };
                } else {
                    obsoleteKeys.push(toFetchFiltered[index]); // Capture the key that resulted in a null
                    return null;
                }
            });

            records2add = records.filter(record => record !== null);

            // also perform db cleanup

            if (obsoleteKeys.length > 0) {

                let updates = {};

                // Iterate over each key in obsoleteKeys to handle its associated recordIds
                obsoleteKeys.forEach(key => {

                    // remove it if it's still in u_edits
                    updates[`u_edits/${uid}/${key}`] = null;
                    // Get the object containing recordIds and their relationships for this key
                    const recordIdInfo = toFetch[key];


                    // Iterate over each recordId in the recordIdInfo
                    Object.entries(recordIdInfo).forEach(([recordId, relationshipType]) => {
                        if (relationshipType === 'target') {
                            // If the relationship type is 'target', delete from 'of_span'
                            updates[`${recordId}/of_span/${key}`] = null;
                        } else if (relationshipType === 'source') {
                            // If the relationship type is 'source', delete from 'span'
                            updates[`${recordId}/span/${key}`] = null;
                        }
                    });
                });

                // Perform the updates on the database
                update(uEditsRef, updates).then(() => {
                    console.log('Obsolete keys removed successfully:', obsoleteKeys);
                }).catch(error => {
                    console.error('Error removing obsolete keys:', error);
                });
            }

        } catch (error) {
            console.error('Error processing records:', error);
        }
    }

    // Fetch other edits, especially those that contain span information
    // remove any that's already in the fetched records
    const editsToFetchFiltered = Object.keys(toFetch).filter(key => !(key in edits));

    if (editsToFetchFiltered.length > 0) {

        try {

            const fetchPromises = editsToFetchFiltered.map(key => get(ref(db, `u_edits/${uid}/${key}`)));
            const results = await Promise.all(fetchPromises);

            // const records = results.map((snapshot, index) => {
            //     if (snapshot.exists()) {
            //         return { [editsToFetchFiltered[index]]: snapshot.val() };
            //     } else {
            //         return null;
            //     }
            // });

            const newEdits = results.reduce((acc, snapshot, index) => {
                if (snapshot.exists()) {
                    // Set the key as the editToFetchFiltered value and assign the snapshot value
                    acc[editsToFetchFiltered[index]] = snapshot.val();
                }
                return acc;
            }, {});


            edits = { ...edits, ...newEdits };



        } catch (error) {
            console.error('Error fetching records:', error);
        }
    }


    editedData = editedData.concat(records2add);


    // To create spans, loop through the records again

    let processedData = [];
    let toRemove = [];

    for (const editedDatum of editedData) {

        const editInfo = edits[editedDatum.recordId];

        let record = {
            ...editedDatum
        };

        // if it's a duration, it has to overlap with the date of interest
        if (record.end_time && !(record.end_time > startDate && record.time < endDate)) {
            continue;
        }

        else if (!editInfo?.span) {

            processedData.push(record);

        } else {

            for (const [targetRecordId, adjustObject] of Object.entries(editInfo.span)) {
                const targetRecord = editedData.find((item) => item.recordId === targetRecordId);

                if (!targetRecord) {
                    continue;
                }

                for (const [adjust, value] of Object.entries(adjustObject)) {
                    if (!value) continue;  // Continue means skip; only proceed if the value is true or is an object


                    // combine records
                    let newRecord = { ...record };

                    // Determine the source node based on the adjust value
                    if (adjust.charAt(0) === '1') {
                        newRecord.time = record.end_time;
                    }

                    // Determine the target node based on the adjust value
                    if (adjust.charAt(1) === '0') {
                        newRecord.end_time = targetRecord.time;
                    } else if (adjust.charAt(1) === '1') {
                        newRecord.end_time = targetRecord.end_time;
                    }


                    newRecord.event = value?.name ?? `${record.event} -> ${targetRecord.event}`;

                    // only if newRecords have both time and end_time, and also they overlap with the time period of interest
                    if (newRecord.time && newRecord.end_time && newRecord.end_time > startDate && newRecord.time < endDate) {
                        newRecord.recordId = `${record.recordId}-${targetRecordId}-${adjust}`;
                        processedData.push(newRecord);
                        // if either records only has "time" without "end_time", then it should be removed, 
                        // in addition to the new records being created


                        if (!targetRecord.end_time) {
                            toRemove.push(targetRecordId);
                        }

                        if (!record.end_time) {
                            toRemove.push(record.recordId);
                        }
                    }

                }



            }

            processedData.push(record);

        }



    };

    return processedData.filter((item) => !toRemove.includes(item.recordId));

};





const roundAndFormatTime = (momentObj) => {
    if (!momentObj) return '';

    // Clone to avoid modifying the original moment object
    const roundedMoment = momentObj.clone();

    // Round to nearest minute
    const seconds = roundedMoment.seconds();
    if (seconds >= 30) {
        // Add the remainder to round up
        roundedMoment.add(60 - seconds, 'seconds');
    } else {
        // Subtract seconds to round down
        roundedMoment.subtract(seconds, 'seconds');
    }

    // Now format it
    return roundedMoment.format('h:mma').replace('am', 'a').replace('pm', 'p');
};



const overlaps = (record1, record2) => {
    return record1.trimmed_end_time > record2.trimmed_time && record1.trimmed_time < record2.trimmed_end_time;
}

const calculateOverlapDuration = (record1, record2) => {
    const startMax = Math.max(record1.trimmed_time, record2.trimmed_time);
    const endMin = Math.min(record1.trimmed_end_time, record2.trimmed_end_time);
    return (endMin - startMax) / 60; // Convert seconds to minutes if your timestamps are in seconds
}


export const processRecordsForGraph = (records, date, timezone) => {



    const processedData = records.map((item, index) => {

        const epsilon = 0.0001;  // small value to prevent overlapping events

        const startDate = moment.tz(date, timezone).startOf('day').unix();
        const endDate = moment.tz(date, timezone).endOf('day').unix();

        // trim event time so that they are fully contained within calendar day
        const trimmed_time = Math.max(item.time, startDate);
        const trimmed_end_time = item.hasOwnProperty('end_time') ? Math.min(item.end_time, endDate) : null;

        const startMoment = moment.unix(trimmed_time).tz(timezone);
        // console.log('event is', item.event, 'trimmed_end_time is', trimmed_end_time, 'trimmed_time is', trimmed_time);
        const endMoment = trimmed_end_time && trimmed_end_time > trimmed_time && moment.unix(trimmed_end_time).tz(timezone);
        // because of the trimming, the endMoment can be 11:59:59 and truncation would be inappropriate; rounding would be better
        const startText = roundAndFormatTime(startMoment);
        const endText = endMoment ? roundAndFormatTime(endMoment) : '';
        const midnightMoment = moment(date).tz(timezone).startOf('day');

        const durationMin = Math.round(endMoment && endMoment.diff(startMoment, 'seconds') / 60); // Duration in minutes
        // const durationMin = item.duration; // Duration in minutes
        // number of minutes since midnight
        const startMin = Math.round(startMoment.diff(midnightMoment, 'seconds') / 60);
        const endMin = endMoment && Math.round(endMoment.diff(midnightMoment, 'seconds') / 60);
        // rangeMin start-time with small jiggle to prevent overlapping events
        // it appears that end-time in rangeMin doesn't require jiggle, only start-time
        // in singleton events, the width is kept at ~ 2 minutes
        const rangeMin = endMoment ? [startMin + epsilon * index, endMin] : [startMin > 0 ? startMin - 1 + epsilon * index : 0 + epsilon * index, startMin < (60 * 24) ? startMin + 1 : 60 * 24];
        const durationRange = endMoment ? [0, -1] : [0.5, 0];  // use minus sign to flip polar coordinates graph the right side out for the connector to labels
        const percent = durationMin && durationMin / (60 * 24);
        const event_id = String(index);

        return {
            ...item,
            startText,
            endText,
            durationMin,
            startMin,
            endMin,
            rangeMin,
            durationRange,
            percent,
            event_id,
            startMoment,
            endMoment: moment(endMoment, timezone).format('h:mm:ss'),
            trimmed_time,
            trimmed_end_time,
        };
    }).sort((a, b) => a.trimmed_time - b.trimmed_time);;

    // console.log('processedData is', processedData);


    processedData.forEach((record, index) => {
        // record.duration = calculateDuration(record.startTime, record.endTime);
        let nettedDurationInMinutes = record.durationMin; // Start with original duration
        let maxEnd = record.trimmed_time; // Initialize maxEnd with the current record's start time, because no overlap can occur before that

        processedData.forEach((otherRecord, otherIndex) => {
            if (index !== otherIndex && (otherRecord.trimmed_time > record.trimmed_time || otherRecord.trimmed_end_time < record.trimmed_end_time)) { // Don't compare a record with itself; and also don't try to subtract from another event that fully contains this one
                // the latter decision is purely for flavor, just so that the netting can show more results

                if (overlaps(record, otherRecord)) {
                    const truncatedOtherRecord = { ...otherRecord, trimmed_time: Math.max(otherRecord.trimmed_time, maxEnd) }; // Truncate other record to the maxEnd
                    if (truncatedOtherRecord.trimmed_time >= truncatedOtherRecord.trimmed_end_time) {
                        return; // Returning from a callback function in .forEach() is akin to using continue in a traditional loop—it ends the current iteration's callback execution and proceeds to the next item in the array.
                    }
                    const overlapDuration = calculateOverlapDuration(record, truncatedOtherRecord);
                    maxEnd = Math.min(record.trimmed_end_time, otherRecord.trimmed_end_time); // Update maxEnd to the furthest end of the overlap
                    nettedDurationInMinutes -= overlapDuration; // Subtract overlap
                }
            }
        });

        record.nettedDurationInMinutes = Math.round(nettedDurationInMinutes);
    });

    // this implementation is possibly wrong
    // processedData.forEach((record, index) => {
    //     record.nettedDurationInMinutes = record.durationMin; // Start with original duration
    //     let maxEnd = record.trimmed_time; // Initialize maxEnd with the current record's start time, to not double-count overlaps

    //     processedData.forEach((otherRecord, otherIndex) => {
    //         if (index !== otherIndex) { // Don't compare a record with itself
    //             if (overlaps(record, otherRecord)) {
    //                 const truncatedOtherRecord = { ...otherRecord, trimmed_time: Math.max(otherRecord.trimmed_time, maxEnd) }; // Truncate other record to maxEnd to prevent double counting
    //                 if (truncatedOtherRecord.trimmed_time >= truncatedOtherRecord.trimmed_end_time) {
    //                     // Skip if the overlap has been fully accounted for
    //                     return; 
    //                 }
    //                 const overlapDuration = calculateOverlapDuration(record, truncatedOtherRecord);
    //                 // Only subtract overlap if it extends beyond maxEnd, and update maxEnd
    //                 if (truncatedOtherRecord.trimmed_time < record.trimmed_end_time) {
    //                     record.nettedDurationInMinutes -= overlapDuration;
    //                     maxEnd = Math.max(maxEnd, truncatedOtherRecord.trimmed_end_time); // Update maxEnd to the end of this overlap
    //                 }
    //             }
    //         }
    //     });
    // });



    return processedData;
};


import G6 from '@antv/g6';

import { Haptics, ImpactStyle } from '@capacitor/haptics';

const erasingDuration = 1200; // time in milliseconds
const creatingDuration = 500;




const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
};

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};

const hapticsVibrate = async () => {
    await Haptics.vibrate();
};

const hapticsSelectionStart = async () => {
    await Haptics.selectionStart();
};

const hapticsSelectionChanged = async () => {
    await Haptics.selectionChanged();
};

const hapticsSelectionEnd = async () => {
    await Haptics.selectionEnd();
};



export const createCustomNode = (isDarkMode) => {

    G6.registerNode(
        'round-rect',
        {
            drawShape: function drawShape(cfg, group) {
                const width = cfg.style.width;
                const stroke = cfg.style.stroke;
                const rect = group.addShape('rect', {
                    attrs: {
                        x: -width / 2,
                        y: -12,
                        width,
                        height: 24,
                        radius: 12,
                        stroke,
                        lineWidth: 1.2,
                        fill: cfg.style.stroke, // fill attribute is needed, for the entire node, not just the border, to be interactive; you can fill with a transparent color
                        fillOpacity: 0,
                    },
                    name: 'rect-shape',
                });
                group.addShape('circle', {
                    attrs: {
                        x: width / 2,
                        y: 0,
                        r: 3,
                        fill: stroke,
                    },
                    name: 'circle-shape2',
                });

                // // Add label with custom font styles
                // if (cfg.eventFormatted) {
                //     console.log('event is', cfg.eventFormatted);
                //     const event = cfg.eventFormatted;
                //     group.addShape('text', {
                //         attrs: {
                //             x: 0, // Center the text horizontally
                //             y: 0, // Center the text vertically
                //             textAlign: 'center',
                //             textBaseline: 'middle',
                //             text: event,
                //             fontSize: 14, // Font size
                //             fill: isDarkMode ? '#ccc' : '#444', // The text color of the time label
                //             fontFamily: 'Arial, sans-serif', // Font family
                //             // fontWeight: 'bold', // Font weight
                //         },
                //         name: 'text-shape',
                //     });
                // }

                if (cfg.hmTime) {
                    group.addShape('text', {
                        attrs: {
                            text: cfg.hmTime, // The text displayed
                            x: -width / 2 - 10, // Position to the left of the node
                            y: 0, // Align to the center in the y axis
                            textAlign: 'right', // Align text to the right
                            textBaseline: 'middle', // Align text in the middle vertically
                            fill: isDarkMode ? '#ccc' : '#444', // The text color of the time label
                            fontSize: 14, // The font size
                        },
                        name: 'time-label', // Name for the label which can be used to reference it later
                    });
                };
                return rect;
            },
            getAnchorPoints: function getAnchorPoints() {
                return [
                    // [0, 0.5],
                    [1, 0.5],
                ];
            },
            update: function update(cfg, item) {
                const group = item.getContainer();
                const children = group.get('children');
                const node = children[0];
                const circleLeft = children[1];
                const circleRight = children[2];

                const stroke = cfg.style.stroke;

                if (stroke) {
                    node.attr('stroke', stroke);
                    circleLeft.attr('fill', stroke);
                    circleRight.attr('fill', stroke);
                }

                const nodeLabel = children.find(child => child.get('name') === 'time-label');
                // Update node label text and style if needed
                // here it's the time label
                if (nodeLabel) {
                    nodeLabel.attr({
                        // Ensure the color for the node label is set correctly
                        text: cfg.hmTime,
                        // this fill would work after refresh
                        fill: isDarkMode ? '#ccc' : '#444', // or any specific color you want for the node label
                    });
                }

                const name = group.find((element) => element.get('name') === 'text-shape');

                if (name) {
                    name.attr({
                        text: cfg.label || cfg.name, // Assuming the label is stored in cfg.label or cfg.name
                        // this fill would work after refresh
                        fill: isDarkMode ? '#ccc' : '#444',
                    });
                }


            },
            setState(name, value, item) {
                const group = item.getContainer();
                const shape = group.get('children')[0]; // Assuming the first child is the node shape

                if (name === 'hover') {
                    const lineDash = [4, 2, 1, 2];
                    if (value) {
                        let index = 0;
                        shape.animate(
                            () => {
                                index++;
                                if (index > 9) {
                                    index = 0;
                                }
                                return {
                                    lineDash,
                                    lineDashOffset: -index,
                                };
                            },
                            {
                                repeat: true,
                                duration: 3000,
                            },
                        );
                    } else {
                        shape.stopAnimate();
                        shape.attr({
                            lineDash: null,
                        });
                    }
                } else if (name === 'clicked' && value) {

                    shape.attr({
                        strokeOpacity: 0.5,
                        fillOpacity: 0.5,
                    });

                }
                else {
                    shape.attr({
                        strokeOpacity: 1,
                        fillOpacity: 0, // do not set fill to null: fill attribute is needed, for the entire node, not just the border, to be interactive
                    });
                }
            },
        },
        'single-node',
    );

};


export const createCustomEdge = (isDarkMode) => {
    G6.registerEdge('flowing-dashed', {
        setState(name, value, item) {
            const shape = item.get('keyShape');
            const model = item.getModel();
            const length = shape.getTotalLength(); // Get the total length of the edge
            let totalLength = length;

            if (name === 'hover') {
                let index = 0;
                if (value) {
                    // When mouse enters
                    shape.attr({
                        lineDash: [4, 2, 1, 2],
                    });
                    shape.animate(
                        () => {
                            index += 1; // speed of the animation
                            if (index > 9) {  // unless it's too small, the max value doesn't affect animation speed
                                index = 0;
                            }
                            return {
                                lineDash: [4, 2, 1, 2],
                                lineDashOffset: -index,
                            };
                        },
                        {
                            repeat: true,
                            duration: 3000,
                        },
                    );
                } else {
                    // When mouse leaves
                    shape.stopAnimate();
                    shape.attr({
                        lineDash: null,
                    });
                }
            } else if (name === 'erasing') {
                if (value) {

                    // haptic feedback for erasure
                    // Haptics.vibrate();
                    // hapticsSelectionStart();
                    hapticsImpactLight();

                    // Animate the lineDash to make it seem like it's being erased
                    shape.animate(ratio => {
                        const dashLength = totalLength * (1 - ratio);
                        // const lineDashOffset = model.isGrowing ? 0 : -totalLength * ratio;
                        const lineDashOffset = -totalLength * ratio;
                        // const lineDash = model.isGrowing ? [length - dashLength, dashLength] : [dashLength, length - dashLength];
                        const lineDash = [dashLength, length - dashLength];
                        return { lineDash, lineDashOffset, opacity: 1 }; // Offset to make the dashed part start from the end of the edge
                    },
                        {
                            // duration: model.isGrowing ? creatingDuration : erasingDuration, // Duration of the erasing effect
                            duration: erasingDuration, // Duration of the erasing effect
                            easing: 'easeLinear', // Linear easing to have a uniform erasing effect
                            callback: () => {
                                // model.isGrowing = false; // Reset isGrowing after animation
                                shape.attr({ opacity: 1 });
                                // hapticsSelectionEnd();
                                hapticsImpactLight();
                            }
                        },
                    );
                } else {
                    // When mouse leaves
                    shape.stopAnimate();
                    shape.attr({
                        lineDash: null,
                        opacity: 1,
                    });
                }
            } else if (name === 'creating') {
                if (value) {

                    // shape.attr({
                    //     lineDash: null,
                    //     opacity: 1
                    // });

                    // Animate the lineDash to make it seem like it's being erased
                    shape.animate(ratio => {
                        const dashLength = totalLength * (1 - ratio);
                        const lineDashOffset = 0;
                        const lineDash = [length - dashLength, dashLength];
                        return { lineDash, lineDashOffset, opacity: 1 }; // Offset to make the dashed part start from the end of the edge
                    },
                        {
                            duration: creatingDuration, // Duration of the erasing effect
                            easing: 'easeLinear', // Linear easing to have a uniform erasing effect
                            callback: () => {
                                shape.attr({ opacity: 1 });
                            }
                        },
                    );


                    // shape.attr({
                    //     lineDash: [0, totalLength],
                    //     lineDashOffset: 0,
                    //     opacity: 1
                    // });

                    // shape.animate({
                    //     lineDash: [totalLength, 0],
                    //     opacity: 1
                    // }, {
                    //     duration: creatingDuration, // Adjust duration as needed
                    //     easing: 'easeLinear',
                    //     callback: () => {
                    //         shape.attr({
                    //             lineDash: null,
                    //             opacity: 1
                    //         });
                    //     }
                    // });

                } else {
                    shape.stopAnimate();
                    shape.attr({
                        lineDash: null,
                        opacity: 1,
                    });
                }
            }

        },
        update: function update(cfg, item) {
            const group = item.getContainer();
            const name = group.find((element) => element.get('name') === 'text-shape');

            if (name) {
                name.attr({
                    text: cfg.label || cfg.name, // Assuming the label is stored in cfg.label or cfg.name
                    // this fill would work after refresh
                    fill: isDarkMode ? '#ccc' : '#444',
                });
            }


        },
    },
        'quadratic');

};




export const createCustomBehavior = () => {


    // we only register it for the touch interface; non-touch interfaces will use the default 'create-edge' mode
    G6.registerBehavior('custom-create-edge', {
        getEvents() {
            return {
                'node:click': 'onClick',
                'node:touchstart': 'onClick',
                // 'mousemove': 'onMousemove',
                'canvas:touchstart': 'onCanvasTouchstart'
            };
        },
        onClick(e) {
            const node = e.item;
            const graph = this.graph;
            hapticsImpactLight();

            if (!this.addingEdge) {
                // No edge is being added, start adding
                this.startNode = node;
                this.addingEdge = true;
                graph.setItemState(node, 'clicked', true);

            } else {
                // Finish adding the edge
                const startNode = this.startNode;
                const endNode = node;

                // Check if we should end (similar to your shouldEnd)
                if (startNode === endNode) {
                    return;  // Ignore self connections
                }

                // Create edge logic here
                const isDuplicate = graph.getEdges().some(edge => {
                    const model = edge.getModel();
                    return model.source === startNode.getID() && model.target === endNode.getID();
                });

                if (!isDuplicate) {

                    // perform your operations
                    const newEdge = graph.addItem('edge', {
                        id: `edge-${startNode.getID()}-${endNode.getID()}`,
                        source: startNode.getID(),
                        target: endNode.getID(),
                        style: { stroke: '#f00', lineWidth: 2 },
                        type: 'line',  // Customize your edge type
                    });


                    // Call the afterCreateEdge method to handle additional logic
                    graph.cfg.afterCreateEdge(newEdge, graph);

                }

                // Reset state
                graph.setItemState(startNode, 'clicked', false);
                this.addingEdge = false;
            }
        },
        // onMousemove(e) {
        //     const graph = this.graph;
        //     if (this.addingEdge) {
        //         const point = graph.getPointByClient(e.clientX, e.clientY);
        //         console.log('point is', point);
        //         if (!this.edge) {
        //             this.edge = graph.addItem('edge', {
        //                 source: e.item.getModel().id,
        //                 target: point,
        //                 type: 'line',  // You can define a specific edge type for the temporary edge
        //                 style: {
        //                     stroke: '#888',
        //                     lineWidth: 3,
        //                     lineDash: [5, 5],
        //                     opacity: 0.7,
        //                 }
        //             });
        //         } else {
        //             graph.updateItem(this.edge, {
        //                 target: point
        //             });
        //         }
        //     }
        // },
        onCanvasTouchstart(e) {

            if (this.addingEdge) {
                // Cancel edge creation if clicked on canvas
                const graph = this.graph;
                const startNode = this.startNode;
                graph.setItemState(startNode, 'clicked', false);
                this.addingEdge = false;
                hapticsImpactLight();

            }
        },

    });
}


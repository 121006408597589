import { ref, query, orderByChild, startAt, endAt, get } from 'firebase/database';
import moment from 'moment-timezone';

// Fetch records for a specific date from u_records
export const fetchRecordsForDate = async (uid, startDate, endDate, db) => {
    const userRecordsRef = ref(db, `u_records/${uid}`);
    // Define the queries for end_time and time
    // here the +1 and -1 is to remove any record that starts exactly at day end, or ends exactly at day start
    const endRecordsQuery = query(userRecordsRef, orderByChild('end_time'), startAt(startDate + 1), endAt(endDate));
    const timeRecordsQuery = query(userRecordsRef, orderByChild('time'), startAt(startDate), endAt(endDate - 1));


    // Execute both queries in parallel
    const [endRecordsSnapshot, timeRecordsSnapshot] = await Promise.all([get(endRecordsQuery), get(timeRecordsQuery)]);
    const endRecords = endRecordsSnapshot.val() || {};
    const timeRecords = timeRecordsSnapshot.val() || {};

    // Merge results, excluding duplicates
    const fetchedKeys = new Set(Object.keys(endRecords));
    for (const key in timeRecords) {
        if (!fetchedKeys.has(key)) {
            endRecords[key] = timeRecords[key];
        }
    }

    return endRecords;
};

// Fetch edits for the records from u_edits
export const fetchEditsForDate = async (uid, recordIds, db) => {
    const edits = {};

    // Prepare promises for parallel fetching
    const fetchPromises = recordIds.map((recordId) => {
        const recordEditRef = ref(db, `u_edits/${uid}/${recordId}`);
        return get(recordEditRef)
            .then(snapshot => {
                const editData = snapshot.val();
                if (editData) {
                    edits[recordId] = editData;
                }
            })
            .catch(error => {
                console.error(`Error fetching edit for record ${recordId}:`, error);
            });
    });

    // Execute all promises in parallel
    await Promise.all(fetchPromises);

    return edits;
};


export const processRecordsForGraph = (records, timezone) => {
    let nodes = [];
    let edges = [];
    let nodeId = 0;
    const recordIdToNodeIdMap = new Map(); // Map record IDs to node objects
    const sourceToTargetMap = new Map();
    const targetToSourceMap = new Map();
    const idToNodeMap = new Map();


    const keyedData = records.map(([key, item]) => ({ ...item, 'record_id': key }));


    keyedData.forEach((item) => {
        if (typeof item.time === 'undefined') {
            // Skip this item if the time is undefined
            return;
        }

        let startNodeId = `${nodeId++}`;
        let startNodeName = (item.end_time !== null && item.end_time !== undefined) ? `${item.event} (start)` : item.event;
        let hmTime = moment.unix(item.time).tz(timezone).format('HH:mm'); // Format to human-readable time
        let startNode = {
            id: startNodeId,
            name: startNodeName,
            cluster: item.event,
            timestamp: item.time,
            hmTime,
            record_id: item.record_id,
        };
        nodes.push(startNode);
        idToNodeMap.set(startNodeId, startNode);
        recordIdToNodeIdMap.set(item.record_id, [startNodeId]);

        if (typeof item.end_time !== 'undefined') {
            let endNodeId = `${nodeId++}`;
            let hmTime = moment.unix(item.end_time).tz(timezone).format('HH:mm'); // Format to human-readable time
            let endNode = {
                id: endNodeId,
                name: `${item.event} (end)`,
                cluster: item.event,
                timestamp: item.end_time,
                hmTime,
                start_record_id: item.record_id,
            };
            nodes.push(endNode);
            idToNodeMap.set(endNodeId, endNode);
            recordIdToNodeIdMap.set(item.record_id, [startNodeId, endNodeId]);

            edges.push({
                source: startNodeId,
                target: endNodeId,
                name: item.event,
            });

            if (!sourceToTargetMap.has(startNodeId)) {
                sourceToTargetMap.set(startNodeId, new Set());
            }
            sourceToTargetMap.get(startNodeId).add(endNodeId);

            // For target to source mapping
            if (!targetToSourceMap.has(endNodeId)) {
                targetToSourceMap.set(endNodeId, new Set());
            }
            targetToSourceMap.get(endNodeId).add(startNodeId);


        }


    });

    return { nodes, edges, recordIdToNodeIdMap, sourceToTargetMap, targetToSourceMap, idToNodeMap };
};







// Main data fetching and processing function
export const fetchDataAndProcess = async (uid, date, timezone, db, prior = 0, post = 0) => {
    const startDate = moment.tz(date, timezone).startOf('day').subtract(prior ? prior : 0, 'hours').unix();
    const endDate = moment.tz(date, timezone).endOf('day').add(post ? post : 0, 'hours').unix();

    // Fetch records and edits
    const records = await fetchRecordsForDate(uid, startDate, endDate, db);
    const edits = await fetchEditsForDate(uid, Object.keys(records), db);

    // Process records to get nodes and initial edges
    const { nodes, edges, recordIdToNodeIdMap, sourceToTargetMap, targetToSourceMap, idToNodeMap } = processRecordsForGraph(Object.entries(records), timezone);

    // Add additional edges, and remove edges from edits

    for (const [recordId, editInfo] of Object.entries(edits)) {

        const nodePair = recordIdToNodeIdMap.get(recordId);

        // we no longer enable editing of time, end_time and name of edge for existing span events
        // hence we comment out all such processing

        // if (editInfo['event']) {
        //     if (nodePair) {
        //         const event = editInfo['event'];
        //         const node = idToNodeMap.get(nodePair[0]);
        //         if (node) {
        //             node.name = event;
        //             node.cluster = event;
        //         }
        //     }
        // }

        // if (editInfo['end_event']) {
        //     if (nodePair && nodePair.length === 2) {
        //         const event = editInfo['end_event'];
        //         const node = idToNodeMap.get(nodePair[1]);
        //         if (node) {
        //             node.name = event;
        //             node.cluster = event;
        //         }
        //     }
        // }

        // if (editInfo['name']) {
        //     if (nodePair && nodePair.length === 2) {
        //         // Assuming nodePair[0] is the start node and nodePair[1] is the end node
        //         const startNodeId = nodePair[0];
        //         const endNodeId = nodePair[1];

        //         // Remove the edge from the edges array
        //         const edgeIndex = edges.findIndex(edge => edge.source === startNodeId && edge.target === endNodeId);
        //         if (edgeIndex !== -1) {
        //             edges[edgeIndex].name = editInfo['name'];
        //         }
        //     }
        // }

        // if (editInfo['time']) {
        //     if (nodePair) {
        //         const time = editInfo['time'];
        //         const node = idToNodeMap.get(nodePair[0]);
        //         if (node) {
        //             node.timestamp = time;
        //             node.hmTime = moment.unix(time).tz(timezone).format('HH:mm');
        //         }
        //     }
        // }

        // if (editInfo['end_time']) {
        //     if (nodePair && nodePair.length === 2) {
        //         const time = editInfo['end_time'];
        //         const node = idToNodeMap.get(nodePair[1]);
        //         if (node) {
        //             node.timestamp = time;
        //             node.hmTime = moment.unix(time).tz(timezone).format('HH:mm');
        //         }
        //     }
        // }

        // Check for split_span and handle accordingly
        if (editInfo['split_span'] === true) {

            if (nodePair && nodePair.length === 2) {
                // Assuming nodePair[0] is the start node and nodePair[1] is the end node
                const startNodeId = nodePair[0];
                const endNodeId = nodePair[1];

                // Remove the edge from the edges array
                const edgeIndex = edges.findIndex(edge => edge.source === startNodeId && edge.target === endNodeId);
                if (edgeIndex !== -1) {
                    edges.splice(edgeIndex, 1);
                }

                // Update the maps accordingly
                sourceToTargetMap.get(startNodeId)?.delete(endNodeId);
                targetToSourceMap.get(endNodeId)?.delete(startNodeId);
            }
        }

        // check for span and handle accordingly
        if (!nodePair) continue; // Skip if there's no node pair for this recordId

        if (editInfo.span) {
            for (const [targetRecordId, adjustObject] of Object.entries(editInfo.span)) {

                for (const [adjust, value] of Object.entries(adjustObject)) {
                    if (!value) continue;  // Continue means skip; only proceed if the value is true or is an object

                    const targetNodePair = recordIdToNodeIdMap.get(targetRecordId);
                    if (!targetNodePair) continue;  // Skip if there's no node pair for this targetRecordId

                    let sourceNodeId, targetNodeId;

                    // Determine the source node based on the adjust value
                    if (adjust.charAt(0) === '0') {
                        sourceNodeId = nodePair[0];
                    } else if (adjust.charAt(0) === '1' && nodePair.length > 1) {
                        // so if an end_time was deleted, the nodePair will only have one element
                        // a previous span based on that would not get drawn
                        sourceNodeId = nodePair[1];
                    }

                    // Determine the target node based on the adjust value
                    if (adjust.charAt(1) === '0') {
                        targetNodeId = targetNodePair[0];
                    } else if (adjust.charAt(1) === '1' && targetNodePair.length > 1) {
                        // so if an end_time was deleted, the nodePair will only have one element
                        // a previous span based on that would not get drawn
                        targetNodeId = targetNodePair[1];
                    }

                    // If both sourceNodeId and targetNodeId are found, create and add the edge
                    // The string id "0" is still truthy btw
                    if (sourceNodeId && targetNodeId && sourceNodeId !== targetNodeId) {
                        // Check if the edge already exists
                        if (!sourceToTargetMap.get(sourceNodeId)?.has(targetNodeId)) {

                            const sourceName = idToNodeMap.get(sourceNodeId)?.name || '';
                            const targetName = idToNodeMap.get(targetNodeId)?.name || '';
                            const newEdge = {
                                source: sourceNodeId,
                                target: targetNodeId,
                                name: value?.name ?? sourceName + ' -> ' + targetName,
                            };

                            edges.push(newEdge);

                            sourceToTargetMap.set(sourceNodeId, new Set([...(sourceToTargetMap.get(sourceNodeId) || []), targetNodeId]));
                            targetToSourceMap.set(targetNodeId, new Set([...(targetToSourceMap.get(targetNodeId) || []), sourceNodeId]));

                        }
                    }
                }
            }
        }
    };


    // Sort the nodes by timestamp

    const getSourceTimestamp = (id, nodes) => {
        if (!targetToSourceMap.has(id)) return null;

        let latestTimestamp = null;
        targetToSourceMap.get(id).forEach(sourceId => {
            const node = idToNodeMap.get(sourceId);
            if (node && (latestTimestamp === null || node.timestamp > latestTimestamp)) {
                latestTimestamp = node.timestamp;
            }
        });

        return latestTimestamp;
    }

    const getTargetTimestamp = (id, nodes) => {
        if (!sourceToTargetMap.has(id)) return null;

        let earliestTimestamp = null;
        sourceToTargetMap.get(id).forEach(targetId => {
            const node = idToNodeMap.get(targetId);
            if (node && (earliestTimestamp === null || node.timestamp < earliestTimestamp)) {
                earliestTimestamp = node.timestamp;
            }
        });

        return earliestTimestamp;
    }


    // const sortedNodes = nodes.sort((a, b) => {
    //     // Primary sort based on timestamp
    //     if (a.timestamp !== b.timestamp) {
    //         return a.timestamp - b.timestamp;
    //     }

    //     // Secondary sort for tie-breaking, end nodes come first to minimize overlap (edge crossing)
    //     if (targetToSourceMap.has(a.id) && !targetToSourceMap.has(b.id)) {
    //         return -1; // End node a comes first
    //     }
    //     if (sourceToTargetMap.has(a.id) && !sourceToTargetMap.has(b.id)) {
    //         return 1; // Start node a comes after end node b
    //     }

    //     // Additional sorting logic when both are in targetIds or sourceIds
    //     if (targetToSourceMap.has(a.id) && targetToSourceMap.has(b.id)) {
    //         // Compare the timestamps of the corresponding source nodes
    //         const aSourceTimestamp = getSourceTimestamp(a.id); // Implement getSourceTimestamp
    //         const bSourceTimestamp = getSourceTimestamp(b.id);
    //         return bSourceTimestamp - aSourceTimestamp; // Later source node first
    //     }
    //     if (sourceToTargetMap.has(a.id) && sourceToTargetMap.has(b.id)) {
    //         // Compare the timestamps of the corresponding target nodes
    //         const aTargetTimestamp = getTargetTimestamp(a.id); // Implement getTargetTimestamp
    //         const bTargetTimestamp = getTargetTimestamp(b.id);
    //         return aTargetTimestamp - bTargetTimestamp; // Earlier target node first
    //     }

    //     return 0;
    // });


    const sortedNodes = nodes.sort((a, b) => {
        // Primary sort based on timestamp
        if (a.timestamp !== b.timestamp) {
            return a.timestamp - b.timestamp;
        }

        // Secondary sort for tie-breaking
        const isAEndNode = targetToSourceMap.has(a.id);
        const isBEndNode = targetToSourceMap.has(b.id);
        const isAStartNode = sourceToTargetMap.has(a.id);
        const isBStartNode = sourceToTargetMap.has(b.id);

        // if ((a.id === '19' && b.id === '12') || (a.id === '12' && b.id === '19')) {
        //     console.log(a, b);
        //     console.log(isAEndNode, isBEndNode, isAStartNode, isBStartNode);
        // }

        // If one is an end node and the other is not, the end node comes first
        if (isAEndNode !== isBEndNode) {
            return isAEndNode ? -1 : 1;
        }

        // If both are end nodes or both are start nodes, sort based on the timestamps of their linked nodes
        if (isAEndNode && isBEndNode) {
            // Both are end nodes, later source node comes first
            const aSourceTimestamp = getSourceTimestamp(a.id);
            const bSourceTimestamp = getSourceTimestamp(b.id);
            return bSourceTimestamp - aSourceTimestamp;
        } else if (isAStartNode && isBStartNode) {
            // Both are start nodes, earlier target node comes first
            const aTargetTimestamp = getTargetTimestamp(a.id);
            const bTargetTimestamp = getTargetTimestamp(b.id);
            return aTargetTimestamp - bTargetTimestamp;
        }

        // If they are the same or none of the above conditions met, they are considered equal in terms of sorting
        return 0;
    });



    return { nodes: sortedNodes, edges };
};


import { ref, get } from 'firebase/database';
import { db } from './config';

const mergeAuthAndDBUser = async (authUser, firebase) => {

    const userRef = ref(db, 'users/' + authUser.uid);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
        const dbUser = snapshot.val();
        // Merge auth and db user
        authUser = {
            ...authUser,
            ...dbUser,
        };
    }
    return authUser;
};


export default mergeAuthAndDBUser;
import React, { useState, useEffect } from 'react';
import { List, Avatar, Typography } from 'antd';
import { CheckSquareOutlined, LoadingOutlined } from '@ant-design/icons';
// import { ref, get } from 'firebase/database';

import { Comment } from '@ant-design/compatible';
import ReactMarkdown from 'react-markdown';
import moment from 'moment-timezone';
import { formatMarkdownContent } from '../Chat/utils';
// import CustomComment from './customComment';

const { Text } = Typography;

const ChatItem = ({ item, scrollToBottom }) => {


    const eventDetails = item.details;
    // new items, when created, have eventDetails -- by default show them
    const [showDetails, setShowDetails] = useState(eventDetails ? true : false);
    // const [eventDetails, setEventDetails] = useState([]);
    // const [isFetching, setIsFetching] = useState(false);
    const [toggle, setToggle] = useState(true);



    useEffect(() => {

        // console.log('eventDetails in ChatITem useEffect', item.time, eventDetails);

        if (eventDetails) {
            // call parent function if it's expansion; currently onDetailsToggle scrolls to the bottom if it's the last item
            setShowDetails(true);
            setToggle(false);
            scrollToBottom();
        }

    }, [eventDetails]);  // toggle is deliberately left out of the dependency array


    const toggleShowDetails = () => {
        // since the parent holds eventDetailsMap and the data is cached, we don't need to fetch from db again
        // so it's prudent to re-fetch the eventDetails even if our local state already has it

        if (showDetails) {
            setToggle(true);
        }

        setShowDetails(!showDetails);



    };


    const renderEventDetails = () => {
        if (!eventDetails) {
            return <p>Loading...</p>;
        }
        // show eventDetails if is an array
        if (showDetails && Array.isArray(eventDetails)) {

            const markdownContent = formatMarkdownContent(eventDetails, 'h:mma, ddd MMM D', 'start');

            return <ReactMarkdown className='react-markdown' components={markdownComponents}>{markdownContent}</ReactMarkdown>;
        } else {
            // otherwise show the error msg
            return <ReactMarkdown className='react-markdown' components={markdownComponents}>{eventDetails}</ReactMarkdown>;
        }

        // return null;
    };





    const markdownComponents = {
        // Other components as needed...
        // ul: ({ node, ...props }) => <List>{props.children}</List>,
        li: ({ node, ...props }) => (
            <li>
                <Text style={{ fontSize: 'smaller' }}>{props.children}</Text>
            </li>
        ),
    };



    return (
        <List.Item className={`chat-item ${item.status === 'sending' || item.status === 'queued' ? 'shine-effect' : ''}`}>
            <Comment
                author={<span>You</span>}
                // avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon={<CheckSquareOutlined />} />}
                content={item.text}
                datetime={moment(item.time).calendar()}
                actions={[
                    <span>{item.status === 'sending' ? <LoadingOutlined /> : <CheckSquareOutlined />}</span>
                    ,
                    item.details ? <span key="comment-basic-show-details" onClick={toggleShowDetails}>
                        {showDetails ? "Hide details" : "Show details"}
                    </span> : null,
                ]}
                style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
            >
                {showDetails && eventDetails && (
                    <Comment
                        avatar={<Avatar src={`${process.env.PUBLIC_URL}/images/logo_simple.svg`} />}
                        author={Array.isArray(eventDetails) ? <span>Recorded</span> : <span>Not recorded</span>}
                        content={<div>{renderEventDetails()}</div>}
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0)', paddingTop: 0 }}
                    />
                )}
            </Comment>
        </List.Item >
    );
};

export default React.memo(ChatItem);


import React, { Component } from 'react';
import {
  Button, Spin, Result, Row
} from 'antd';
import { checkActionCode, applyActionCode, sendPasswordResetEmail } from "firebase/auth";
import * as ROUTES from "../../constants/routes";


export default class RecoverEmail extends Component {
  state = {
    error: '',
    restoredEmail: '',
    resetSent: false,
    validCode: null,
    verifiedCode: false,
  }

  componentDidMount() {
    // Confirm the action code is valid.
    // this.props.firebase.auth
    //   .checkActionCode(this.props.actionCode)
    //   .then(info => {
    //     // Get the restored email address.
    //     const restoredEmail = info['data']['email'];
    //     // Revert to the old email.
    //     this.props.firebase.auth
    //       .applyActionCode(this.props.actionCode)
    //       .then(() => {
    //         // Account email reverted to restoredEmail
    //         this.setState({ restoredEmail, validCode: true, verifiedCode: true });
    //       });
    //   }, error => {
    //     // Invalid code.
    //     this.setState({ error: error.message, validCode: false, verifiedCode: true });
    //   });

    // console.log('firabase auth is', this.props.firebase.auth);

    checkActionCode(this.props.firebase.auth, this.props.actionCode)
      .then((info) => {
        // Get the restored email address.
        const restoredEmail = info.data?.email; // Note: The structure to access 'email' might need adjustment based on actual response
        // Revert to the old email.
        applyActionCode(this.props.firebase.auth, this.props.actionCode)
          .then(() => {
            // Account email reverted to restoredEmail
            // console.log('applied action code')
            this.setState({ restoredEmail, validCode: true, verifiedCode: true });
          })
          .catch((error) => {
            // console.log('error applying action code')
            this.setState({ error, validCode: false, verifiedCode: true });
          })
      })
      .catch((error) => {
        // Invalid code.
        // console.log('invalid code')
        this.setState({ error, validCode: false, verifiedCode: true });
      });


  }

  sendReset = () => {
    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    // this.props.firebase.auth
    //   .sendPasswordResetEmail(this.state.restoredEmail)
    //   .then(() => {
    //     // Password reset confirmation sent. Ask user to check their email.
    //     this.setState({ resetSent: true });
    //   });


    sendPasswordResetEmail(this.props.firebase.auth, this.state.restoredEmail)
      .then(() => {
        // Password reset confirmation sent. Ask user to check their email.
        this.setState({ resetSent: true });
      })
      .catch((error) => {
        // Error sending password reset email
        this.setState({ error });
      });
  }

  render() {
    const {
      error,
      restoredEmail,
      resetSent,
      validCode,
      verifiedCode,
    } = this.state;

    let component;
    if (!verifiedCode) {
      component = <Row type="flex" justify="center" style={{ margin: "80px auto" }}><Spin /></Row>;
    } else if (resetSent) {
      component = (
        <Result
          status="info"
          title="Check your email"
          subTitle={<span>Follow the instructions sent to <span>{restoredEmail}</span> to recover your password.</span>}
          extra={[
            <Button type="primary" key="home" onClick={() => this.props.navigate(ROUTES.HOME)}>
              Homepage
            </Button>
          ]}
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    } else if (verifiedCode && validCode) {

      component = (
        <Result
          status="success"
          title="Updated email address"
          subTitle={<span>Your sign-in email address has been changed back to <span>{restoredEmail}</span></span>}
          extra={[<div><p>If you did not change your sign-in email,
            it is possible someone is trying to access your account and you should
            <Button type="primary" onClick={this.sendReset}>change your password right away</Button>
          </p>
          </div>
          ]}
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    } else if (verifiedCode && !validCode) {
      const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || '';
      component = (
        <Result
          status="info"
          title="Unable to update your email address"
          subTitle={formattedError}
          // <p className="error">{error}</p></div>
          extra={[
            <Button type="primary" key="home" onClick={() => this.props.navigate(ROUTES.HOME)}>
              Homepage
            </Button>
          ]}
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    }

    return component;
  }
}

import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { FirebaseContext } from '../Firebase';
import AuthUserContext from './createdContexts';
import * as ROUTES from '../../constants/routes';
import { auth, mergeAuthAndDBUser } from '../Firebase';

// const useAuthorization = (condition) => {
//     const firebase = useContext(FirebaseContext);
//     const { authUser, setAuthUser } = useContext(AuthUserContext);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(auth, async (user) => {

//             // console.log('condition is', condition)
//             if (user) {
//                 // console.log('OnAuth state changed triggered in useAuthorization.');
//                 // Merge auth and db userd
//                 console.log('merging authUser with db');
//                 const mergedUser = await mergeAuthAndDBUser(user, firebase);
//                 setAuthUser(mergedUser); // Update authUser in context
//                 if (!condition(mergedUser)) {
//                     navigate(ROUTES.PAGE403);
//                 }
//             } else {
//                 setAuthUser(null); // User is signed out, set authUser to null
//                 navigate(ROUTES.LANDING);
//             }
//         });

//         return () => unsubscribe();
//     }, [condition, firebase]);
//     // }, [condition, navigate, firebase, setAuthUser]);

//     return condition(authUser);
// };

// export default useAuthorization;


// const useAuthorization = (condition) => {
//     const { authUser } = useContext(AuthUserContext);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (!condition(authUser)) {
//             navigate(authUser ? ROUTES.PAGE403 : ROUTES.LANDING);
//         }
//     }, [authUser, condition, navigate]);

//     return condition(authUser);
// };



const useAuthorization = (condition) => {
    const { authUser } = useContext(AuthUserContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Perform the authorization check
        if (authUser && !condition(authUser)) {
            // Navigate to the forbidden route if the condition fails
            navigate(ROUTES.PAGE403);
        } else if (!authUser) {
            // Navigate to the landing page if no user is logged in
            navigate(ROUTES.LANDING);
        }
        // We only need to rerun this effect when authUser changes or the condition function changes
    }, [authUser, condition, navigate]);

    // Optionally return the authorization status if needed elsewhere
    return authUser && condition(authUser);
};

export default useAuthorization;


import { useState, useContext, useCallback } from 'react';
import { message } from 'antd';
import { sendEmailVerification } from "firebase/auth";
import AuthUserContext from './createdContexts';
import { FirebaseContext } from '../Firebase';
import { siteUrl } from '../../constants';

const useEmailVerification = () => {
    const firebase = useContext(FirebaseContext);
    const [isSent, setIsSent] = useState(false);
    const { authUser } = useContext(AuthUserContext); // Destructure authUser from context
    const [messageApi, contextHolder] = message.useMessage();
    // const [emailVerificationStatusLoading, setEmailVerificationStatusLoading] = useState(true);

    const needsEmailVerification = useCallback(
        () =>
            authUser &&
            !authUser.emailVerified &&
            authUser.providerData
                .map((provider) => provider.providerId)
                .includes('password'),
        [authUser]
    );

    // useEffect(() => {
    //     // If authUser is defined, we can assess needsEmailVerification
    //     if (authUser != null) {
    //         setEmailVerificationStatusLoading(false);
    //     }
    // }, [authUser]);

    const onSendEmailVerification = useCallback(() => {
        const actionCodeSettings = {
            url: siteUrl,
            handleCodeInApp: false,
        };

        return sendEmailVerification(firebase.auth.currentUser, actionCodeSettings)
            .then(() => {
                messageApi.success('A verification email has been sent to you', 3);
                setIsSent(true);
            })
            .catch((error) => {
                const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || '';
                messageApi.error(`Error sending verification email: ${formattedError}`, 3);
                setIsSent(false); // Set isSent to false on error
            });

    }, [firebase]);


    return {
        isSent,
        onSendEmailVerification,
        needsEmailVerification,
        authUser,
        contextHolder,
        messageApi,
        // emailVerificationStatusLoading
    };
};

export default useEmailVerification;

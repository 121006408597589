import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Space, Flex, DatePicker, Typography, Tooltip, Checkbox, message } from 'antd';
import { PlusOutlined, MinusCircleOutlined, RobotOutlined, QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs with customParseFormat plugin to handle custom formats
dayjs.extend(customParseFormat);

const { Text } = Typography;

const { TextArea } = Input;



const EventDetailsModal = ({ eventDetails, chatEntry, isVisible, setIsVisible, onFinish }) => {
    const [form] = Form.useForm();
    const [editMessageMode, setEditMessageMode] = useState(false);
    const [showTeachBot, setShowTeachBot] = useState(false);
    const [recordsCount, setRecordsCount] = useState(Array.isArray(eventDetails) ? eventDetails.length : 0);

    const [messageApi, contextHolder] = message.useMessage();

    // console.log('eventDetails', eventDetails, 'its length', eventDetails?.length, 'chatEntry', chatEntry);

    const handleEditMessage = () => setEditMessageMode(true);


    useEffect(() => {

        // Check if eventDetails exists and isVisible is true before setting form values
        if (eventDetails && isVisible && chatEntry) {
            form.setFieldsValue({
                records: Array.isArray(eventDetails) ? (eventDetails?.map((record) => ({
                    event: record.event,
                    time: (record.time !== null && record.time !== undefined) ? dayjs.unix(record.time) : null,
                    end_time: (record.end_time !== null && record.end_time !== undefined) ? dayjs.unix(record.end_time) : null,
                    message_id: record.message_id,
                    timezone: record.timezone,
                    key: record.key,
                }))) : [],
                text: chatEntry.text,
                teachBot: false,
            });

            Array.isArray(eventDetails) && setRecordsCount(eventDetails.length);
        }

    }, [eventDetails, isVisible, form, chatEntry]);



    const convertDayjsToMomentUTCUnix = (dayjsObj) => {
        // Convert dayjs object to a standard Date object
        const dateObj = dayjsObj.toDate();

        // Create a moment object from the Date object
        const momentObj = moment(dateObj);

        // Convert to UTC and get the Unix timestamp (in seconds)
        const unixUtcTimestamp = momentObj.utc().unix();

        return unixUtcTimestamp;
    };


    const shallowEqual = (objA, objB) => {

        // Filter out keys with null values from both objects
        const keysA = Object.keys(objA).filter(key => objA[key] !== null);
        const keysB = Object.keys(objB).filter(key => objB[key] !== null);

        // Check if the objects have the same number of keys
        if (keysA.length !== keysB.length) {
            return false;
        }

        // Check if both objects have the same keys and values
        for (const key of keysA) {
            if (objA[key] !== objB[key]) {
                return false;
            }
        }

        return true;
    }


    // function compareObjectLists(listA, listB) {
    //     if (listA.length !== listB.length) {
    //         return false;
    //     }

    //     const copyListB = [...listB]; // Make a shallow copy to manipulate

    //     for (const objA of listA) {
    //         // Try to find a match for objA in copyListB
    //         const matchIndex = copyListB.findIndex(objB => shallowEqual(objA, objB));
    //         if (matchIndex === -1) {
    //             // No match found, lists are not equal
    //             return false;
    //         }
    //         // Remove the matched object from copyListB to prevent re-matching
    //         copyListB.splice(matchIndex, 1);
    //     }

    //     // If all objects from listA are matched and removed from copyListB, lists are equal
    //     return true;
    // }

    function compareObjectListsAndReturnChanges(listA, listB) {
        // const aDict = new Map(listA.map(item => [item.key, item]));
        const bDict = new Map(listB.map(item => [item.key, item]));

        let removals = [];
        let updates = [];
        let additions = [];

        // Determine Removals and Updates
        for (let aItem of listA) {
            const bItem = bDict.get(aItem.key);
            if (!bItem) {
                removals.push(aItem.key); // Item in A not found in B
            } else if (!shallowEqual(aItem, bItem)) {
                updates.push(bItem.key); // Item found but has differences
            }
        }

        // Determine Additions
        listB.forEach((item, index) => {
            if (!item.key) {
                additions.push(index); // Item in B not found in A, use index
            }
        });

        return { removals, updates, additions };
    }

    function compareObjectListsAndReturnNonMatchingTuples(listA, listB) {

        let nonMatchingTuples = [];

        if (listB.length === 0) {
            return [];
        } else if (listA.length === 0) {
            for (let i = 0; i < listB.length; i++) {
                nonMatchingTuples.push([null, i]); // Indicates non-matching due to absence in list A
            }
            return nonMatchingTuples;
        }

        // Determine the length of the shorter list
        const minLength = Math.min(listA.length, listB.length);

        // Compare objects in both lists up to the length of the shorter list
        for (let i = 0; i < minLength; i++) {
            if (!shallowEqual(listA[i], listB[i])) {

                nonMatchingTuples.push([i, i]); // Add a tuple-like array
            }
        }

        // If listA is longer, mark remaining indices as non-matching
        if (listA.length > minLength) {
            for (let i = minLength; i < listA.length; i++) {
                nonMatchingTuples.push([i, null]); // Indicates non-matching due to absence in list B
            }
        }

        // If listB is longer, mark remaining indices as non-matching
        if (listB.length > minLength) {
            for (let i = minLength; i < listB.length; i++) {
                nonMatchingTuples.push([null, i]); // Indicates non-matching due to absence in list A
            }
        }

        return nonMatchingTuples;
    }



    const handleFinish = (values) => {

        if (!values.records || values.records.length < 1) {
            messageApi.error('At least one record is required');
            return;
        }

        const anyInvalid = values.records?.some(record => !record.event || record.event.trim() === '');
        if (anyInvalid) {
            messageApi.error('Each record must have a non-empty event name');
            return;
        }


        // form.validateFields()
        //     .then(values => {
        //         // Form is valid
        //         console.log('Received values of form:', values);
        //         // Proceed with form submission or further actions
        //     })
        //     .catch(errorInfo => {
        //         // Handle form validation failure
        //         console.log('Validation Failed:', errorInfo);
        //     });

        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const oldVal = (Array.isArray(eventDetails) && eventDetails.map((record) => {
            return { ...record, duration: null };
        })) || [];


        // duration: null is needed to flush out existing duration values if any
        const newVal = values.records.map((record) => {
            // console.log('record message_id', record.message_id, 'chatEntry time', chatEntry.time);
            return {
                ...record,
                event: record.event.trim(),
                time: convertDayjsToMomentUTCUnix(record.time),
                end_time: (record.end_time !== null && record.end_time !== undefined) ? convertDayjsToMomentUTCUnix(record.end_time) : null,
                timezone: record.timezone ? record.timezone : userTimezone,
                message_id: record.message_id ? record.message_id : String(chatEntry.time),
                duration: null,
            };
        });

        const { removals, updates, additions } = compareObjectListsAndReturnChanges(oldVal, newVal);

        // console.log('removals', removals, 'updates', updates, 'additions', additions);

        // const nonMatchingTuples = compareObjectListsAndReturnNonMatchingTuples(oldVal, newVal);
        // console.log('eventDetails', eventDetails, 'oldVal', oldVal, 'newVal', newVal, 'non matches', nonMatchingTuples);

        const trimmedText = values.text?.trim() || null;

        const newText = trimmedText === chatEntry?.text ? null : trimmedText;

        // console.log('values.text is', values.text, 'newText is', newText);

        if ((removals.length > 0 || updates.length > 0 || additions.length > 0 || newText) && chatEntry) {
            onFinish(Array.isArray(eventDetails) ? eventDetails : oldVal, newVal, removals, updates, additions, chatEntry.text, newText, values.teachBot, chatEntry.time);
        }

        // if ((nonMatchingTuples.length > 0 || newText) && chatEntry) {
        //     console.log('nonMatchingTuples', nonMatchingTuples, 'newText', newText);
        //     onFinish(Array.isArray(eventDetails) ? eventDetails : oldVal, newVal, nonMatchingTuples, chatEntry.text, newText, values.teachBot, chatEntry.time);
        // }


        setIsVisible(false); // Close the modal
        form.resetFields();
        setEditMessageMode(false);
        setShowTeachBot(false);
    };

    const onModalCancel = () => {
        setIsVisible(false);
        form.resetFields();
        setEditMessageMode(false);
        setShowTeachBot(false);
    }

    return (
        <>
            {contextHolder}


            <Modal
                title={<div><RobotOutlined />&nbsp;&nbsp;&nbsp;Edit Time Records</div>}
                open={isVisible}
                onOk={() => form.submit()}
                onCancel={onModalCancel}
                width={800}
                // style={{ opacity: 0.9 }}  // less than 1 opacity causes the modal to flicker on open
                maskClosable={false}
            >

                <Form
                    form={form}
                    name="dynamic_form_event_details"
                    onFinish={handleFinish}
                    autoComplete="off"
                >

                    {
                        editMessageMode ? (
                            <>
                                <Form.Item
                                    label={`Original Message (at ${moment.unix(parseInt(chatEntry?.time) / 1000).format('H:mm')})`}
                                    name="text"
                                    rules={[{ required: true }]}
                                >
                                    {/* <Input
                                    // defaultValue={message}
                                    // onChange={e => setMessage(e.target.value)}
                                    maxLength={140}
                                    autoFocus
                                /> */}
                                    <TextArea
                                        showCount
                                        maxLength={140}
                                        autoSize={{ 'minRows': 1 }}
                                        allowClear
                                        onPressEnter={e => { e.preventDefault() }}
                                        onChange={(e) => {
                                            // setMessage(e.target.value);
                                            setShowTeachBot(false);
                                            form.setFieldValue('teachBot', false);
                                        }}
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            <Form.Item
                                label={`Original Message (at ${moment.unix(parseInt(chatEntry?.time) / 1000).format('H:mm')})`}
                            >
                                <Tooltip title="Click to edit">
                                    <Text onClick={handleEditMessage}>{chatEntry?.text}&nbsp;&nbsp;&nbsp;<EditOutlined /></Text>
                                </Tooltip>
                            </Form.Item>
                        )
                    }

                    <Form.Item name="teachBot" valuePropName="checked">
                        <Checkbox onChange={e => setShowTeachBot(e.target.checked)}>Teach the Parrot<Tooltip key="comment-basic-response" title="The Parrot misunderstood you. You'd like him to learn from this.">&nbsp;<QuestionCircleOutlined
                            style={{
                                fontSize: '14px', // Smaller size
                                position: 'relative',
                                top: '-4px', // Higher position
                                color: '#00a9a8',
                            }} />
                        </Tooltip></Checkbox>
                    </Form.Item>

                    {showTeachBot && (
                        <div style={{ marginBottom: 24 }}>
                            <div><Text strong>When I said</Text></div>
                            <div>&nbsp;&nbsp;&nbsp;<Text italic>{form.getFieldValue('text')}</Text>
                            </div>
                            <div><Text strong>&nbsp;&nbsp;&nbsp;on&nbsp;</Text>
                                <Text>{moment.unix(parseInt(chatEntry?.time) / 1000).format('ddd, YYYY-MM-DD H:mm')}</Text>
                            </div>
                            <div><Text strong>In this context
                                <Tooltip key="comment-basic-response" title="The context includes your five earlier messages until this one, and the bot's responses">&nbsp;<QuestionCircleOutlined style={{
                                    fontSize: '14px', // Smaller size
                                    position: 'relative',
                                    top: '-4px', // Higher position
                                    color: '#00a9a8',
                                }} />
                                </Tooltip>, it means</Text></div>
                        </div>
                    )}

                    {!recordsCount && <div style={{ color: 'red' }}>At least one record is required</div>}

                    <Form.List name="records"
                    >
                        {(fields, { add, remove }) => {

                            const handleRemove = (name) => {
                                remove(name); // Remove the item from the form

                                // Asynchronously update recordsCount based on the updated form state
                                setTimeout(() => {
                                    const recordsLength = form.getFieldValue('records')?.length || 0;
                                    setRecordsCount(recordsLength);
                                }, 0);
                            };

                            const handleAdd = () => {   // Add a new record
                                add();
                                // Asynchronously update recordsCount based on the updated form state
                                setTimeout(() => {
                                    const recordsLength = form.getFieldValue('records')?.length || 0;
                                    setRecordsCount(recordsLength);
                                }, 0);

                            }

                            return (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Flex key={key} size='large' style={{ marginBottom: 8 }} align="baseline" gap='middle'>
                                            <Space.Compact block style={{ width: 'calc(100% - 32px)' }}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'event']}
                                                    // fieldKey={[fieldKey, 'name']}
                                                    rules={[
                                                        // { required: true, message: 'Missing event name' },
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value) {
                                                                    return Promise.reject(new Error('Event name cannot be empty'));
                                                                }
                                                                if (value.trim() === '') {
                                                                    return Promise.reject(new Error('Event name cannot be just spaces'));
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }]}
                                                    style={{ flex: 1 }}
                                                >
                                                    <Input placeholder="Event Name" allowClear />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'time']}
                                                    // fieldKey={[fieldKey, 'startTime']}
                                                    rules={[{ required: true, message: 'Missing start time' }]}
                                                    style={{ flex: 1 }}
                                                >
                                                    <DatePicker
                                                        showTime
                                                        format="YYYY-MM-DD HH:mm"
                                                        placeholder="Start Time"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'end_time']}
                                                    dependencies={[['records', name, 'time']]}
                                                    // fieldKey={[fieldKey, 'endTime']}
                                                    style={{ flex: 1 }}
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                // Construct the full path to the start time field for the current list item
                                                                const startTimePath = ['records', name, 'time'];
                                                                const startTimeValue = getFieldValue(startTimePath);

                                                                // Ensure value is a moment object and compare
                                                                if (!value || !startTimeValue || value.isAfter(startTimeValue)) {
                                                                    return Promise.resolve(); // If end time is not provided, or is after start time, validation is successful
                                                                }
                                                                return Promise.reject(new Error('End time must be after start time')); // Validation failed
                                                            },
                                                        }),
                                                    ]}

                                                >
                                                    <DatePicker
                                                        showTime
                                                        format="YYYY-MM-DD HH:mm"
                                                        placeholder="End Time (optional)"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </Space.Compact>
                                            {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                                            <MinusCircleOutlined onClick={() => handleRemove(name)} />
                                        </Flex>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={handleAdd} block icon={<PlusOutlined />}>
                                            Add Event
                                        </Button>
                                    </Form.Item>
                                </>
                            )
                        }}
                    </Form.List>
                    {/* <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item> */}
                </Form>
            </Modal >
        </>
    );
};

export default React.memo(EventDetailsModal);




// const renderInputBoxes = () => {


//     if (showDetails && eventDetails.length > 0 && typeof item.response !== 'string') {
//         return (
//             <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
//                 {eventDetails.map((record, index) => (
//                     <Space key={index} wrap>
//                         {/* Input box for the event name */}
//                         <Input
//                             defaultValue={record.event}
//                             className="bot-event-name-input"
//                         // Add onChange handler if needed
//                         />

//                         {/* DatePicker for the start time */}
//                         <DatePicker
//                             format="YYYY-MM-DD HH:mm"
//                             // defaultValue={moment.unix(record.time)}
//                             placeholder={moment.unix(record.time).format('YYYY-MM-DD HH:mm')}
//                             className="bot-date-time-editing-picker"
//                             showTime={{ format: 'HH:mm' }}
//                             // onChange handler to update the state or process the selected time
//                             onChange={(time, timeString) => console.log(time, timeString)}
//                         />

//                         {/* Conditionally render an end DatePicker if there's an end_time */}
//                         {record.end_time && (
//                             <DatePicker
//                                 format="YYYY-MM-DD HH:mm"
//                                 // defaultValue={moment.unix(record.end_time)}
//                                 placeholder={moment.unix(record.end_time).format('YYYY-MM-DD HH:mm')}
//                                 className="bot-date-time-editing-picker"
//                                 showTime={{ format: 'HH:mm' }}
//                                 // onChange handler to update the state or process the selected time
//                                 onChange={(time, timeString) => console.log(time, timeString)}
//                             />
//                         )}
//                     </Space>
//                 ))}
//             </Space>
//         );
//     } else {
//         // otherwise show the error msg
//         return <p>{item.response}</p>;
//     }
// };

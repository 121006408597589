import React, { Component } from 'react';
import {
  Button, Spin, Result, Row, Form, Input, Modal, Flex
} from 'antd';
import { LockOutlined } from '@ant-design/icons';

import * as ROUTES from "../../constants/routes";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

export default class ResetPassword extends Component {
  state = {
    email: null,
    error: '',
    success: false,
    validCode: null,
    verifiedCode: false,
    showModal: false,
  }


  componentDidMount() {
    const { firebase, actionCode } = this.props;

    verifyPasswordResetCode(firebase.auth, actionCode)
      .then(email => {
        const currentUser = firebase.auth.currentUser;

        this.setState({ email });
        // Check if the action code matches the current user
        // this code is somewhat buggy: sometimes the currentUser isn't yet populated, even though a different user is signed in
        if (currentUser && currentUser.email !== email) {
          // this means the code if valid, but it's for a different user
          this.promptForAccountSwitch();
        } else {

          this.setState({ verifiedCode: true, validCode: true });
        }

      }, error => {

        this.setState({ error, validCode: false, verifiedCode: true });
      });
  }



  promptForAccountSwitch = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false, error: "signed in as a different user", validCode: false, verifiedCode: true });
  };



  handleSignOut = () => {
    const { firebase } = this.props;
    this.setState({ showModal: false });
    localStorage.setItem('pendingMessages', null);
    firebase.auth.signOut().then(() => {
      this.setState({ verifiedCode: true, validCode: true });
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };


  onFinish = values => {
    const { password } = values;
    const { actionCode, firebase } = this.props;

    confirmPasswordReset(firebase.auth, actionCode, password)
      .then(() => {

        this.setState({ success: true }, () => {
          // Set a timeout and save its ID
          const timeoutId = setTimeout(() => {
            this.onCancel();
          }, 3000);
          this.setState({ timeoutId });
        });


      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  // Lifecycle method to clear the timeout if the component unmounts
  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);  // Clear the timeout using the saved ID
    }
  }


  onCancel = () => {
    const { firebase, navigate } = this.props;
    if (firebase.auth.currentUser) {
      navigate(ROUTES.HOME);
    } else {
      navigate(ROUTES.LANDING);
    }
  }

  renderModal() {
    return (
      <Modal
        title="You are signed in as a different user"
        open={this.state.showModal}
        onOk={this.handleSignOut}
        onCancel={this.closeModal}
        okText="Sign Out"
        cancelText="Cancel"
      >
        <p>Would you like to sign out and try again? If you are in the middle of something, you'll lose unsaved data if you sign out now. You can also cancel and sign out at another time.</p>
      </Modal>
    );
  }

  render() {
    const {
      email,
      error,
      success,
      validCode,
      verifiedCode,
    } = this.state;


    let component;
    if (!verifiedCode) {
      component = <Spin style={{ marginTop: 200, display: 'flex', justifyContent: 'center' }} size="large" />;
    } else if (success) {
      component = (
        <Result
          status="success"
          title="Password changed"
          subTitle="Redirecting you to sign in..."
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    } else if (verifiedCode && validCode) {
      component = (
        <div>

          <Row type="flex" justify="center" style={{ margin: "60px auto 0px auto", maxWidth: 800 }}>
            <h3>{email}</h3>
          </Row>

          <Row type="flex" justify="center" style={{ margin: "20px auto", maxWidth: 400 }}>
            <Form onFinish={this.onFinish} name="pwreset" layout="vertical">

              <Form.Item
                name="password"
                label={`New password`}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password',
                  },
                  {
                    min: 10, message: 'Password must be at least 10 characters long'
                  },
                  {
                    max: 20, message: 'Password must be at most 20 characters long'
                  }
                ]}
                hasFeedback
              >
                {/* <Input.Password autoComplete="off" /> */}
                <Input.Password
                  prefix={<LockOutlined style={{ opacity: 0.3 }} />}
                  type="password"
                  placeholder="Password 10 characters or longer"
                  autoComplete="off"
                />
              </Form.Item>

              <Flex justify='space-between' gap="large">

                <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                  Reset My Password
                </Button>

                <Button className="login-form-button" onClick={this.onCancel}>
                  Cancel
                </Button>
              </Flex>


            </Form>
          </Row>

        </div>
      );
    } else if (verifiedCode && !validCode) {
      const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || error;
      component = (
        <Result
          status="warning"
          title="There was an issue resetting your password"
          subTitle={formattedError}
          extra={[
            <Button type="primary" key="landing" onClick={() => this.props.navigate(ROUTES.LANDING)}>
              Go Back
            </Button>
          ]}
          style={{ margin: "80px auto", maxWidth: 800 }}
        />
      );
    }

    return (
      <>
        {this.renderModal()}
        {component}
      </>
    );
  }
}

// DateContext.js
import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';

export const DateContext = createContext();

// export const DateContextProvider = ({ children }) => {
//     const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

//     return (
//         <DateContext.Provider value={{ date, setDate }}>
//             {children}
//         </DateContext.Provider>
//     );
// };


export const DateContextProvider = ({ children }) => {
    const [date, setDate] = useState(() => {
        // Get date from localStorage or default to today
        const savedDate = localStorage.getItem('selectedDate');
        return savedDate ? savedDate : moment().format('YYYY-MM-DD');
    });

    useEffect(() => {
        // Update localStorage when date changes
        localStorage.setItem('selectedDate', date);
    }, [date]);

    return (
        <DateContext.Provider value={{ date, setDate }}>
            {children}
        </DateContext.Provider>
    );
};
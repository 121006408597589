import { useRef, useState, useEffect } from 'react';
import Hammer from 'hammerjs';

const useGestureHandling = (chartRef) => {
    const [transform, setTransform] = useState({ scale: 1, transx: 0, transy: 0 });
    const lastPan = useRef({ x: transform.transx, y: transform.transy });
    const lastScale = useRef(transform.scale);

    useEffect(() => {
        const element = chartRef.current; // The element to which the event listeners will be attached
        const mc = new Hammer.Manager(chartRef.current);
        mc.add(new Hammer.Pinch());
        mc.add(new Hammer.Pan({ direction: Hammer.DIRECTION_ALL }));

        mc.get('pinch').set({ enable: true });

        // transform is not one of the dependencies, is outdated, so don't read from it; just set it
        // the reason it is not a dependecy, is because we don't want a new Hammer.Manager instance to be created every time transform changes

        // mc.on('pinchstart', () => {
        //     lastScale.current = transform.scale;
        // });

        mc.on('pinch', (ev) => {
            const newScale = lastScale.current * ev.scale;
            setTransform(prev => ({
                ...prev,
                scale: newScale
            }));
        });

        mc.on('pinchend', (ev) => {
            const finalScale = lastScale.current * ev.scale;
            lastScale.current = finalScale;
        });


        // mc.on('panstart', (ev) => {
        //     // Reset the pan offset at the start

        //     lastPan.current = { x: transform.x, y: transform.y };
        //     console.log('panstart at', transform.x, transform.y);
        // });

        mc.on('panmove', (ev) => {
            const newX = lastPan.current.x + ev.deltaX;
            const newY = lastPan.current.y + ev.deltaY;
            setTransform(prev => ({
                ...prev,
                transx: newX,
                transy: newY
            }));

        });

        mc.on('panend', (ev) => {
            // Finalizing the pan position
            const finalX = lastPan.current.x + ev.deltaX;
            const finalY = lastPan.current.y + ev.deltaY;
            //panmove already sets the transform, so no need to set it again
            // setTransform(prev => ({
            //     ...prev,
            //     x: finalX,
            //     y: finalY
            // }));
            lastPan.current = { x: finalX, y: finalY };
        });


        // Add mousewheel event listener
        const handleWheel = (event) => {
            const deltaY = event.deltaY * -0.01;
            const newScale = lastScale.current + deltaY;
            lastScale.current = newScale;  // Update lastScale to the new value
            setTransform(prev => ({
                ...prev,
                scale: newScale
            }));
        };

        element.addEventListener('wheel', handleWheel);


        return () => {
            mc.destroy();
            if (element) {  // Use the local variable in the cleanup function
                element.removeEventListener('wheel', handleWheel);
            }
        };
    }, [chartRef]);

    return transform;
};

export default useGestureHandling;

import React from 'react';
import { Tour, Button } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { MenuOutlined, BulbOutlined } from '@ant-design/icons';

export const CustomTour = (props) => {



    const actionNameCapped = props.isTouch ? 'Tap' : 'Click';
    const tooltipNameCapped = props.isTouch ? 'Tap on' : 'Hover over';



    const steps = [
        {
            title: <div style={{ marginRight: 16 }}>Pick a day from the calendar dropdown. {`${actionNameCapped}`}<CaretLeftOutlined /> or <CaretRightOutlined />to quickly change one day</div>,
            description: 'To extend a few hours to the previous or next day, modify the number of hours (default to 3 each).',
            cover: (
                <img
                    alt="span0.gif"
                    src="/images/span0.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: `Swipe to pan and pinch to zoom...`,
            description: <div>if you are on a touch screen; if not, drag to pan and mouse wheel (middle button) scroll to zoom.</div>,
            cover: (
                <img
                    alt="span01.gif"
                    src="/images/span01.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: `To create a time span`,
            description: `${actionNameCapped} a beginning event node, then ${actionNameCapped.toLowerCase()} the ending event node below it (do NOT drag). Optionally give it a name.`,
            cover: (
                <img
                    alt="span1.gif"
                    src="/images/span1.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'To remove a time span',
            description: `${actionNameCapped} and hold the span until it disappears. You can rename a span by re-creating it. The parrot may have created spans for you automatically by linking the beginning and the end of events. You can modify them this way.`,
            cover: (
                <img
                    alt="span2.gif"
                    src="/images/span2.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: `To cancel any time`,
            description: `${actionNameCapped} anywhere on the empty space.`,
            cover: (
                <img
                    alt="span3.gif"
                    src="/images/span3.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'Wish to see the netted duration?',
            description: `${actionNameCapped} on the duration next to the span. ${actionNameCapped} again to see the total duration.`,
            cover: (
                <img
                    alt="span4.gif"
                    src="/images/span4.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'Wish to see how long ago an event happened?',
            description: `${tooltipNameCapped} the event node.`,
            cover: (
                <img
                    alt="span5.gif"
                    src="/images/span5.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
            nextButtonProps: { onClick: props.onFinishTour }  // this is the second last step, but consider it finished if click next -- so that if user closes the tour by cross, it will not show again
        },
        {
            title: 'That\'s all for this page! There are more tips on other pages.',
            description: <> To see these tips again, press the <MenuOutlined /> button at the top right corner of the page, then press <BulbOutlined /></>,
            nextButtonProps: { onClick: props.onFinishTour }
        },
    ];

    return <Tour
        open={props.open}
        steps={steps}
        onClose={props.onCloseTour}
        disabledInteraction={true}
        style={{ maxWidth: props.screenWidth }}
    // mask={false}
    />

};


import React from "react";

import {
    Row,
    Typography
} from "antd";



import PricingTable from "./pricingTable";

// import { withEmailVerification } from '../Contexts';
import { siteName } from '../../constants';

const { Title, Text } = Typography;

const App = (props) => {


    // const uses = ['Find out how you spend your time',
    //     'guarantee time spent on your most important goal',
    //     'keep procrastination at bay',
    //     'create new habits and stick to routines',
    //     'discover rhythms and patterns in your day',
    //     'record and reference your metrics, thoughts and feelings',
    // ]


    // const uses = ['Find out how you spend your time',
    //     'create new habits and stick to routines',
    //     'keep reference to your metrics, thoughts and mood',
    // ]




    return (<>
        {/* <Flex vertical justify="center" align="center" style={{ marginTop: 40 }}>
            <p>Jobs asks for timesheets, but it's a pain in the a** to make them? </p>
            <p>(Plus don't you need time to do the job, instead of recording what you did?)</p>
            <p>Time just flies, not sure how it's 9pm again?</p>
            <p>So busy, but don't feel like you are accomplishing enough?</p>
            <p>Can't kick your habits of social media, the "free" entertainment that's costing you dearly in your time?</p>
        </Flex> */}
        {/* <Flex vertical justify="center" align="center" style={{ marginTop: 40 }}>
            <p>Subscribe to continue using Tellti.me</p>
        </Flex> */}
        <Row type="flex" justify="center" style={{ marginTop: 20 }}>
            <Title level={4} style={{ color: '#a90506' }}>Limited Time Offer</Title>
        </Row >
        <Row type="flex" justify="center" style={{ marginTop: -30 }}>
            <Title level={3}>Subscribe to {siteName}</Title>
        </Row>
        <Row type="flex" justify="center">
            <Text type="secondary">Time Told, Moments Held</Text>
        </Row>
        <Row type="flex" justify="center">
            <PricingTable {...props} />
        </Row>

        {/* <Row type="flex" justify="center">
            <ul style={{ padding: 0, margin: '0 20px 20px' }}>
                {uses.map((feature, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>{feature}</li>
                ))}
            </ul>
        </Row> */}



    </>)


}





export default App;

// export default withEmailVerification(App);



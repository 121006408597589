import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';


const stripe_pub_key = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;


const stripePromise = loadStripe(stripe_pub_key);

const Checkout = () => {
    const location = useLocation();
    const { clientSecret } = location.state || {};

    const options = { clientSecret };

    useEffect(() => {
        // Pre-load the stripe API
        stripePromise.catch(error => {
            console.error('Failed to load Stripe:', error);
        });
    }, []);

    return (
        <div id="checkout">
            {clientSecret ? (
                <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            ) : (
                <p>Loading checkout details...</p>
            )}
        </div>
    );
};

export default Checkout;


import React, { useEffect, useRef, useCallback } from 'react';
import { Chart } from '@antv/g2';
import DataSet from '@antv/data-set';
import moment from 'moment-timezone';
import { Flex } from 'antd';

import { formatDurationCompact } from '../Chat';
// import CustomTooltip from './customTooltip';
import { useResponsiveChart } from '../Hooks';
import { Capacitor } from '@capacitor/core';
import useGestureHandling from './useGesture';



const platform = Capacitor.getPlatform();

const formatLongText = (text, length = 30, elipsis = '...') => {

    if (!text || text.length <= length) return text;
    return `${text.substr(0, Math.max(length - 3, 1))}${elipsis}`;
};



// const formatLabel = (rawLabel, chartSize, rangeMin) => {

//     // const roundedLength = Math.round(chartSize * Math.sqrt(findLabelPos(rangeMin)) / 270);
//     const { distanceFrom6, pastNoon } = findLabelPos(rangeMin);
//     let roundedLength;

//     let adjustedLength;

//     if (platform === 'web') {
//         roundedLength = pastNoon ? Math.round(chartSize * Math.sqrt(distanceFrom6) / 130) : Math.round(chartSize * Math.sqrt(distanceFrom6) / 140);
//         adjustedLength = Math.min(Math.max(roundedLength, 20), 45);
//     } else if (platform === 'ios' || platform === 'android') {
//         roundedLength = pastNoon ? Math.round(chartSize * Math.sqrt(distanceFrom6) / 130) : Math.round(chartSize * Math.sqrt(distanceFrom6) / 140);
//         adjustedLength = Math.min(Math.max(roundedLength, 25), 40);
//     }

//     return formatLongText(rawLabel, adjustedLength, '...')
// }

// const findLabelPos = (rangeMin) => {
//     const middleMinute = (rangeMin[0] + rangeMin[1]) / 2;
//     const middleMinute12 = middleMinute % (12 * 60);
//     const pastNoon = middleMinute >= 12 * 60;
//     const distanceFrom6 = Math.abs(middleMinute12 - (6 * 60)) + 1;
//     return { distanceFrom6, pastNoon };
// }

function calculateFontSize(screenHeight, screenWidth, ratio = 50) {
    // Example calculation
    return Math.max(Math.round(Math.min(screenHeight, screenWidth) / ratio), 10); // Adjust divisor as needed
}


const DonutChart = ({ userData, dateText, isTouch, isDarkMode }) => {

    const chartRef = useRef(null);
    const transformRef = useRef({ scale: 1, transx: 0, transy: 0 });

    const { scale, transx, transy } = useGestureHandling(chartRef);
    // console.log('ouside of useGestureHandling, scale is', scale, 'transx is', transx, 'transy is', transy);

    const scaffoldInner = 0.7;
    const scaffoldOuter = 0.75;
    const tickInner = 0.5;
    const labelInner = 0.72;
    const { width: screenWidth, height: screenHeight } = useResponsiveChart(isTouch);

    // const [tooltip, setTooltip] = useState({ visible: false, content: '', position: { x: 0, y: 0 } });

    // const discrete = 0.8;

    // Update ref whenever transform changes
    useEffect(() => {
        transformRef.current = { scale, transx, transy };
    }, [scale, transx, transy]);


    const handleRectClick = (ev) => {

        const { scale: sc, transx: tx, transy: ty } = transformRef.current;

        const tooltip = document.getElementById('customTooltip');

        if (tooltip?.style?.display === 'none') {
            // console.log('ev in rect lick is', ev, 'ev.data is', ev.data);

            // const event = ev.data; // G2 provides x and y coordinates
            const { x, y } = ev.client;

            // console.log('ev is', ev);
            // const rect = ev.target.getBoundingClientRect(); // Get the bounding box of the clicked element
            // const chartRect = chartRef.current.getBoundingClientRect(); // Get the chart container's bounding box

            // // Calculate the position relative to the chart
            // const x = ev.clientX - chartRect.left + rect.width / 2; // Center x on the element
            // const y = ev.clientY - chartRect.top + rect.height; // 

            const data = ev.data.data; // The data bound to the hovered element

            const rectColor = ev.target?.attributes?.fill; // Access the color of the rectangle


            // Define the custom tooltip content based on the data
            let tooltipContent = '';
            if (data.durationMin) {
                // const now = moment();
                // const duration = moment.duration(now.diff(data.startMoment));
                // const roundedMinutes = Math.round(duration.asMinutes());

                const durationText = formatDurationCompact(data.durationMin);

                tooltipContent += `
                    <div>${data.event}</div>
                    <ul>
                    <li style="--bullet-color: ${rectColor};">${durationText}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${data.startText} - ${data.endText}</li>
                    
                    <li style="--bullet-color: ${rectColor};">${+(data.percent * 100).toFixed(1)}% of day</li>
                    <li style="--bullet-color: ${rectColor};">${+(data.percent_events * 100).toFixed(1)}% of recorded time</li>
                `;

                if (data.nettedDurationInMinutes && data.nettedDurationInMinutes !== data.durationMin) {
                    tooltipContent += `<li style="--bullet-color: ${rectColor};">Net duration: ${formatDurationCompact(data.nettedDurationInMinutes)}</li></ul>`;
                } else {
                    tooltipContent += `</ul>`;
                }
            } else {
                tooltipContent += `<div>${data.event}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${data.startText}</div>`;
            }

            // Set the content and position of the tooltip


            // Calculate the adjusted position based on transformations
            // console.log('scale is', sc, 'transx is', tx, 'transy is', ty);
            const transformedX = x / sc - tx;
            const transformedY = y / sc - ty;


            // // Apply the transformation to the tooltip position
            // const scaledX = (x - 4 * transx) * scale;
            // const scaledY = (y + transy) * scale + 20; // +20 is to Adjust positioning slightly below the cursor


            tooltip.innerHTML = tooltipContent;
            tooltip.style.left = `${transformedX}px`;
            tooltip.style.top = `${transformedY + 20}px`; // Adjust positioning slightly below the cursor
            tooltip.style.display = 'block';

            // Apply CSS for marker color
            const styleTag = document.createElement('style');
            styleTag.textContent = `
        li::marker {
            color: var(--bullet-color);
        }`;
            document.head.appendChild(styleTag);

        } else if (tooltip) {
            tooltip.style.display = 'none';
        }

    };



    useEffect(() => {
        if (!chartRef.current || !userData || !dateText) {
            return;
        }

        let userInner = 0.5;
        let userOuter = 0.875;

        let hasElementWithDurationRange050 = false;
        let hasElementWithDurationRange0_1 = false;

        // Check if userData has no element with DurationRange of [0.5, 0] or [0, -1]
        // If not, adjust userOuter or userInner accordingly
        // without such adjustments the tick line and bars would spill over the scaffolding
        for (const item of userData) {
            if (item.durationRange[0] === 0.5) {
                hasElementWithDurationRange050 = true;
            }
            if (item.durationRange[1] === -1) {
                hasElementWithDurationRange0_1 = true;
            }
        }

        if (!hasElementWithDurationRange050) {
            userOuter = 0.75;

        } else if (!hasElementWithDurationRange0_1) {
            userInner = 0.7;
        }


        const clockText = ['MIDNIGHT', '3 AM', '6 AM', '9 AM', 'NOON', '3 PM', '6 PM', '9 PM'];

        // Data for the time segments
        const data = [];
        for (let i = 0; i < 24; i++) {
            data.push({ idx: i, value: 10 });
        }


        // tick data for labels
        const tickData = [];
        for (let i = 0; i < 25; i++) {
            tickData.push({ idx: i, value: 10 });
        }


        // Transform data
        const dv = new DataSet.View().source(data).transform({
            type: 'percent',
            field: 'value',
            dimension: 'idx',
            as: 'percent'
        });



        // const testdv = new DataSet.View().source(data).transform({ type: 'bin.histogram', field: 'idx', binWidth: 3, as: ['x', 'count'] });



        const userDv = userData && new DataSet.View().source(userData).transform({
            type: 'percent',
            field: 'durationMin',
            dimension: 'event_id',
            as: 'percent_events'
        });



        const sqChartSize = Math.min(screenWidth, screenHeight, 1200);

        const chartSettings = ({
            container: chartRef.current,
            // autoFit: true,
            // height: screenHeight - 180,
            height: sqChartSize * 0.8,
            width: Math.max(screenWidth - 20, sqChartSize * 2),
            // width: Math.max((screenWidth - 20) * 0.9, chartSize),
            // padding: chartSize * 0.01,
            padding: 0,
        });


        // Initialize chart
        const chart = new Chart(chartSettings);


        const spaceLayer = chart.spaceLayer();


        // View for time segments
        const scaffoldView = spaceLayer.view();

        scaffoldView.interval()
            .coordinate({ type: 'theta', innerRadius: scaffoldInner, outerRadius: scaffoldOuter, startAngle: Math.PI / 2, endAngle: Math.PI * 5 / 2 })
            .data(dv.rows)
            .transform([{ type: 'stackY' }])
            .encode('y', 'percent')
            .style({
                stroke: isDarkMode ? '#333' : '#ccc',
                strokeWidth: 1,
                fill: 'rgba(255, 255, 255, 0)'
            })
            .animate('enter', { type: 'fadeIn', updateDelay: 10000, updateDuration: 3000, easing: 'easeLinear', enterFill: 'both' })
            .axis(false)
            .tooltip(false)
            .legend(false)


        scaffoldView
            .text()
            .style('text', dateText)
            // Relative position
            .style('x', '50%')
            .style('y', '50%')
            // .style('dy', -25)
            .style('fontSize', Math.min(calculateFontSize(screenHeight, screenWidth, 24), 20))
            .style('fill', '#8c8c8c')
            .style('textAlign', 'center')
            .animate(null);

        // View for labels on time segments
        const tickView = spaceLayer.view();

        // const piAdjustment = Math.PI / 32;

        tickView.coordinate({ type: 'polar', innerRadius: tickInner, startAngle: Math.PI / 2, endAngle: Math.PI * 5 / 2 });

        tickView
            .point()
            .data(tickData)
            // .transform([{ type: 'stackY' }])
            .encode('x', 'idx')
            .encode('y', 'value')
            .encode('size', data => data.idx % 6 === 0 ? 10 : 3)
            .scale('size', { type: 'log', range: [1.5, 2.5] })
            .encode('shape', 'square')
            .style({
                stroke: 'rgba(255, 255, 255, 0)',
                fill: data => data.idx % 3 === 0 ? (isDarkMode ? '#555' : '#aaa') : 'rgba(255, 255, 255, 0)',
            })
            // .encode('shape', data => data.idx % 3 === 0 ? 'cross' : 'line',)
            // .shape(data => data.idx % 3 === 0 ? 'cross' : 'line')
            // .style({
            //     stroke: data => data.idx % 3 === 0 ? '#888' : 'rgba(255, 255, 255, 0)',
            // })
            .tooltip(false)
            .axis(false)
            .legend(false);



        const labelView = spaceLayer.view();


        labelView.coordinate({ type: 'polar', innerRadius: labelInner, startAngle: Math.PI / 2, endAngle: Math.PI * 5 / 2 });

        labelView.line()
            .data(tickData)
            .encode('x', 'idx')
            .encode('y', 'value')
            .style({
                stroke: 'rgba(255, 255, 255, 0)',
                // fill: data => data.idx % 3 === 0 ? '#888' : 'rgba(255, 255, 255, 0)',
                // width: 4,
            })
            .label({
                text: data => data.idx % 3 === 0 && data.idx !== 24 ? clockText[data.idx / 3] : '',
                position: 'outside',
                offset: 200,
                fontSize: calculateFontSize(screenHeight, screenWidth, 48),
                fontWeight: 'bold',
                // fill: '#888',
                fill: isDarkMode ? '#666' : '#aaa',
                textAlign: 'middle',
                // textBaseline: 'bottom'
            })
            .tooltip(false)
            .axis(false)
            .legend(false);

        // View for user data
        const userView = spaceLayer.view();

        // const event_count = userData.length;

        userDv && userView.coordinate({ type: 'polar', innerRadius: userInner, outerRadius: userOuter, startAngle: Math.PI / 2, endAngle: Math.PI * 5 / 2 })
            .data(userDv.rows);

        const rect = userView.rect();


        userDv && rect
            .transform([{ type: 'stackY' }])
            .encode('x', 'rangeMin')
            .encode('y', 'durationRange')
            .encode('color', 'event')
            // .encode('connectorStroke', 'event')
            // color palette see https://g2.antv.antgroup.com/spec/palette
            // .scale('color', { palette: 'tableau10' })
            // .scale('color', { palette: 'pastel1' })
            // .scale('color', { palette: 'rdPu' })
            .label({
                position: 'outside',
                offset: 40,
                // text: 'event',
                // text: data => formatLongText(data.event, text2midpos(data.startText, data.endText) ? Math.round(2 + chartSize / 60) : Math.round(chartSize / 15), '...'),
                // text: data => formatLabel(data.event, sqChartSize, data.rangeMin),
                text: data => formatLongText(data.event, platform === 'ios' ? 40 : 45, '...'),
                fill: isDarkMode ? '#fff' : '#000',
                // fill: 'color',
                // color: 'type',
                // connectorStroke: data => data.durationMin > 100 ? 'red' : 'green',
                // connectorStroke: data => data.event,
                connector: true,
                connectorStroke: isDarkMode ? '#ccc' : '#222',
                // connectorStroke: (data) => { console.log('rect is', rect, 'data is', data); return '222' },
                // style: (item, index, items) => ({ fill: 'red' })
                // backgroundFill: 'white',
                // background: true,
                // encode: { 'background': 'event' },
                // stroke: data => data.event,
                // style: (item, index, items) => item.event,
                fontSize: Math.min(calculateFontSize(screenHeight, screenWidth, 26), 16),
                // fontSize: 4,
                transform: [
                    {
                        type: 'overlapDodgeY',
                    },
                ],
            })
            .tooltip(false)
            // .tooltip({
            //     title: d => d.durationMin ? d.event : false,
            //     items: [
            //         (d) => d.durationMin ? ({
            //             // color: d.sold > 150 ? 'red' : 'blue',
            //             // name: `${d.durationMin < 60 ? d.durationMin + ' minutes' : +(d.durationMin / 60).toFixed(1) + ' hours'}`,
            //             name: formatDurationCompact(d.durationMin),
            //             value: `${d.startText} - ${d.endText}`,
            //         }) : { name: d.event, value: '' },
            //         (d) => d.durationMin ? ({
            //             // color: d.sold > 150 ? 'red' : 'blue',
            //             // value: `${+(d.percent_events * 100).toFixed(1)}% of recorded time`,
            //             value: '',
            //             name: `${+(d.percent * 100).toFixed(1)}% of day`,
            //         }) : { name: 'at ' + d.startText, value: '' },
            //         (d) => {

            //             const now = moment();
            //             const duration = moment.duration(now.diff(d.startMoment));

            //             // Check total minutes including seconds for rounding purposes
            //             const totalMinutes = duration.asMinutes();
            //             const roundedMinutes = Math.round(totalMinutes);

            //             // formatDurationCompact(roundedMinutes);

            //             return (d.nettedDurationInMinutes && d.nettedDurationInMinutes !== d.durationMin) ? ({
            //                 value: `net duration`,
            //                 name: `${formatDurationCompact(d.nettedDurationInMinutes)}`,
            //             }) : (d.durationMin ? { name: `${+(d.percent_events * 100).toFixed(1)}% of recorded time`, value: '' } : { name: `${formatDurationCompact(roundedMinutes)} ago`, value: '' })
            //         }
            //     ]
            // })
            .style({
                // stroke: '#111',
                // strokeWidth: 1,
                opacity: 0.7,
            })
            .scale('x', {
                type: 'linear',
                domain: [0, 60 * 24],
            })
            // .transform({ type: 'stackEnter', duration: 1000, easing: 'easeLinear' })
            .animate('enter', { type: 'waveIn', easing: 'easeLinear' })
            // .animate('enter', { type: 'waveIn', updateDuration: 5000, easing: 'easeLinear' })
            .axis(false)
            .interaction({
                tooltip: { series: true },
            })
            .legend({ color: false });



        const handlePlotClick = (ev) => {
            // if (ev.data) {
            //     // Interaction with an element associated with data
            //     console.log('Click on element:', ev.data);
            // } 
            // Likely a click on the canvas or non-data element
            const tooltip = document.getElementById('customTooltip');
            if (tooltip) {
                if (!ev.data) {
                    tooltip.style.display = 'none';
                    // console.log('hid tooltip because of plot click');
                }
            }
        };

        if (isTouch) {

            chart.on('rect:click', handleRectClick);
            chart.on('plot:click', handlePlotClick);

        } else {
            rect.tooltip({
                title: d => d.durationMin ? d.event : false,
                items: [
                    (d) => d.durationMin ? ({
                        // color: d.sold > 150 ? 'red' : 'blue',
                        // name: `${d.durationMin < 60 ? d.durationMin + ' minutes' : +(d.durationMin / 60).toFixed(1) + ' hours'}`,
                        name: formatDurationCompact(d.durationMin),
                        value: `${d.startText} - ${d.endText}`,
                    }) : { name: d.event, value: '' },
                    (d) => d.durationMin ? ({
                        // color: d.sold > 150 ? 'red' : 'blue',
                        // value: `${+(d.percent_events * 100).toFixed(1)}% of recorded time`,
                        value: '',
                        name: `${+(d.percent * 100).toFixed(1)}% of day`,
                    }) : { name: 'at ' + d.startText, value: '' },
                    (d) => {

                        const now = moment();
                        const duration = moment.duration(now.diff(d.startMoment));

                        // Check total minutes including seconds for rounding purposes
                        const totalMinutes = duration.asMinutes();
                        const roundedMinutes = Math.round(totalMinutes);

                        // formatDurationCompact(roundedMinutes);

                        return (d.nettedDurationInMinutes && d.nettedDurationInMinutes !== d.durationMin) ? ({
                            value: `net duration`,
                            name: `${formatDurationCompact(d.nettedDurationInMinutes)}`,
                        }) : (d.durationMin ? { name: `${+(d.percent_events * 100).toFixed(1)}% of recorded time`, value: '' } : { name: `${formatDurationCompact(roundedMinutes)} ago`, value: '' })
                    }
                ]
            })
        }

        chart.interaction({
            tooltip: true
        });

        // Render the chart
        chart.render();

        // Clean up
        return () => {
            chart.destroy();

            if (isTouch) {
                chart.off('rect:click', handleRectClick);
                chart.off('plot:click', handlePlotClick);
            }
        }
    }, [userData, dateText, screenWidth, screenHeight, isTouch, isDarkMode]);



    useEffect(() => {
        // Define the function to hide the tooltip
        function hideTooltip(event) {
            const tooltip = document.getElementById('customTooltip');
            const chartContainer = document.getElementById('donut-chart-div');
            if (chartContainer && !chartContainer.contains(event.target)) {
                tooltip.style.display = 'none';
            }
        }

        // Add event listener to the document
        document.addEventListener('click', hideTooltip);

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener('click', hideTooltip);
        };

    }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

    //height: screenHeight - 200
    return <Flex justify="center" align="center" style={{ overflow: "hidden", height: screenHeight - 200 }}>


        <div id="donut-chart-div" ref={chartRef}
            style={{
                transform: `translate(${transx}px, ${transy}px) scale(${scale})`,
                transformOrigin: 'center center',
                // width: '100%',  // Fill the parent width
                flexShrink: 0,
            }} />
        <div id="customTooltip"
            style={{
                transform: `translate(${transx}px, ${transy}px) scale(${scale})`,
                // transformOrigin: 'top left',
                transformOrigin: 'center center',
            }} />
    </Flex>


};

export default DonutChart;

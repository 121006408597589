import React from 'react';
import { Modal } from 'antd';
import { siteName } from '../../constants';


const WelcomeModal = ({ onBeginTour, modalVisible }) => (


    <Modal
        open={modalVisible}
        onCancel={() => onBeginTour(false)} // or handle differently if you wish to not start the tour immediately
        onOk={() => onBeginTour(true)}
        title={`Welcome to ${siteName}!`}
    >
        {/* <p>{siteName} is available on your phone as well as on the web. Just use the same sign-in and you'd be all set.</p> */}
        <p>On this page you can chat with the Parrot. How about a few quick tips to get you started?</p>
        <p></p>
    </Modal>
);

export default WelcomeModal;
import React, { useState, useContext, useEffect, useRef } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence, indexedDBLocalPersistence } from "firebase/auth";
import { Form, Input, Button, Checkbox, message, Row, Col, Modal } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext } from '../Contexts';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
  await Haptics.impact({ style: ImpactStyle.Light });
};



const SignInForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);
  const { authUser } = useContext(AuthUserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const isMountedRef = useRef(true);
  // Reset isMountedRef to true at the start of each render
  isMountedRef.current = true;

  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const { handleFinish } = props;

  // useEffect(() => {
  //   // when both loading is on and there is authUser, then it means we just signed in using this very modal
  //   // if only authUser is present, then it means we previously signed in, and we don't want to navigate away (in handleFinish)
  //   console.log('authUser is', authUser, 'loading is', loading);


  //   if (authUser && loading) {
  //     isMountedRef.current && setLoading(false);
  //     form.resetFields();
  //     handleFinish();
  //   };
  // }, [authUser, form, handleFinish, loading]);

  const user = firebase.auth.currentUser;

  useEffect(() => {
    // when both loading is on and there is authUser, then it means we just signed in using this very modal
    // if only authUser is present, then it means we previously signed in, and we don't want to navigate away (in handleFinish)
    // console.log('user is', user, 'loading is', loading);


    if (user && loading) {
      isMountedRef.current && setLoading(false);
      form.resetFields();
      handleFinish();
    };

  }, [user, form, handleFinish, loading]);


  const displayError = (message, duration = 3) => {
    return messageApi.open({
      type: 'error',
      content: message,
      duration: duration,
    });
  };

  const handleCancel = () => {
    // this.setState({ alert: false });
    form.resetFields();
    props.handleCancel();
  };

  const onFinish = async (values) => {
    hapticsImpactLight();
    const { email, password, remember } = values;
    setLoading(true);



    try {

      // if (isNative) {
      //   // await setPersistence(firebase.auth, indexedDBLocalPersistence);
      // } else {
      //   if (!remember) {
      //     await setPersistence(firebase.auth, browserSessionPersistence);
      //   }
      // }

      if (!isNative && !remember) {
        await setPersistence(firebase.auth, browserSessionPersistence);
      }



      // const response = await signInWithEmailAndPassword(firebase.auth, email, password);
      // console.log('Sign-in successful', response);
      await signInWithEmailAndPassword(firebase.auth, email, password);



    } catch (error) {
      console.error('Sign-in error', error.code);
      const formattedError = error.code.replace('auth/', '').replace('-', ' ');
      const errorMessage = error.code === 'auth/invalid-login-credentials' ? 'Email/password doesn\'t match our records' : formattedError;
      displayError(errorMessage, 3);
      isMountedRef.current && setLoading(false);
    }
  };


  return (
    <div className="login-form">
      {contextHolder}
      {/* {isLoading ? <Row type="flex" justify="center">
        <Spin style={{ marginTop: 200 }} size="large" />
      </Row>
        : ( */}

      <Modal
        open={props.visible}
        title="Sign In"
        onCancel={handleCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        width={440}
      >

        <Form onFinish={onFinish} name="normal_login" initialValues={{ remember: false }} form={form} className="form-container">
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'Not a valid email address' },
              { required: true, message: ' ' },
            ]}
            validateTrigger={['onBlur']}
          >
            <Input
              allowClear
              name="email"
              prefix={<MailOutlined style={{ opacity: 0.3 }} />}
              placeholder="Email address"
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: ' ' }]}>
            <Input.Password
              name="password"
              prefix={<LockOutlined style={{ opacity: 0.3 }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          {isNative ? null :
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>}

          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col span={10}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                style={{ width: '100%' }}
              >
                OK
              </Button>
            </Col>
            <Col span={10}>
              <Button
                type="default"
                className="login-form-button"
                onClick={handleCancel}
                style={{ width: '100%' }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <Form.Item>
            <Button type="link" onClick={() => props.goToOtherModal('pwforget')}>Forgot Password</Button>
          </Form.Item>
          <Form.Item>
            Don't have an account?
            <Button type="link" onClick={() => props.goToOtherModal('signup')}>Sign Up</Button>
          </Form.Item>
        </Form></Modal>
      {/* ) }*/}

    </div >
  );
};



export default SignInForm;

import React from 'react';
import {
    Result, Button, Image
} from 'antd';

import * as ROUTES from "../../constants/routes";
import { useNavigate } from 'react-router-dom';

const Res = (props) => {

    const navigate = useNavigate();

    return <Result
        // status="404"
        // icon={<Image
        //     height={400}
        //     src="404.svg"
        //     alt="404"
        //     preview={false}
        //     fallback="Page not found"
        //     style={{ padding: "20px 6px 0px 2px", marginLeft: 12, marginRight: 12, marginTop: 80 }}
        // // style={{ margin: "auto", verticalAlign: "middle", display: "inline-block", height: "100%" }}
        // />}
        title="Page not found"
        subTitle="Sorry, the page you requested does not exist."
        extra={<Button key="home" type="primary" onClick={() => { navigate(ROUTES.HOME) }}>Go to Homepage</Button>}
    />
};

export default Res;



import React from 'react';
import {
    Result, Button
} from 'antd';
import { useNavigate } from 'react-router-dom';

import * as ROUTES from "../../constants/routes";

import withPage from '../Results';


const Res = () => {
    const navigate = useNavigate();

    // here since it's cancelling, I'd rather not automatically redirect
    // give the user some time to think

    return <Result
        status="warning"
        title="Payment is cancelled"
        subTitle="You were not charged"
        extra={[
            <Button type="primary" key="home" onClick={() => { navigate(ROUTES.ACCOUNT) }}>
                My Account
            </Button>
        ]}
        style={{ margin: "80px auto", maxWidth: 800 }}
    />


};

export default withPage(Res);

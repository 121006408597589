import React from 'react';
import { Flex } from 'antd';  // Ensure Flex is properly imported
import { footer } from './data';
import { NavLink } from 'react-router-dom';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};


const footerStyles = {
    background: '#00152a',
    color: '#fff',
    padding: '24px 24px 24px',
};


const footerLinkStyles = {
    marginBottom: '12px',
    fontSize: '14px',
};

const footerLinkAnchorStyles = {
    color: 'rgba(256, 256, 256, 0.9)',
};

const footerDescStyles = {
    color: 'rgba(255, 255, 255, 0.65)',
};

export default function Footer() {
    return (
        <footer style={footerStyles}>
            <NavLink to="/">
                <img style={{ marginRight: 12, height: 20 }} src={`${process.env.PUBLIC_URL}/images/logo_text.svg`} alt="welcome" onClick={hapticsImpactLight} />
            </NavLink>
            <NavLink to="/home">
                <img style={{ marginRight: 8, height: 20 }} src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="home" onClick={hapticsImpactLight} />
            </NavLink>
            < Flex justify="space-around" align="top" style={{ width: '100%' }}>
                {footer.map((foot, index) => (
                    <div key={index} >
                        <h2>
                            {foot.icon && <img style={{ marginRight: 2, height: 20 }} src={foot.icon} alt="img" />}
                            {foot.title}
                        </h2>
                        {foot.children?.map((child, idx) => (
                            <div key={idx} style={footerLinkStyles}>
                                <NavLink to={child.link} style={footerLinkAnchorStyles}>
                                    {child.title}
                                    {child.desc && (
                                        <span style={footerDescStyles}>{' - '}{child.desc}</span>
                                    )}
                                </NavLink>
                            </div>
                        ))}
                    </div>
                ))}
            </Flex>
        </footer>
    );
}



// const footerItemStyles = {
//     textAlign: 'left',
//     flex: '1 1 0',  // Flex grow, shrink and basis
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     margin: '0 10px',  // Add some margin between items if needed
// };

// const footerTitleStyles = {
//     marginBottom: '24px',
//     color: '#fff',
//     fontSize: '16px',
// };

import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const TypingAnimationTextArea = (props) => {
    const [textValue, setTextValue] = useState('');
    const [typing, setTyping] = useState(false);

    const { finalText, lastIsSent, onFinishTyping, initialTimeout } = props;

    useEffect(() => {
        // Reset the text value and restart typing when finalText changes
        setTyping(false);
        setTextValue('');

        let typingTimeout;
        if (finalText && lastIsSent) {
            typingTimeout = setTimeout(() => {
                setTyping(true);
            }, initialTimeout); // Delay typing by initialTimeout 
        }

        return () => clearTimeout(typingTimeout); // Cleanup timeout on component unmount

    }, [finalText, lastIsSent]); // Only run this effect when finalText changes and when the last item is sent

    useEffect(() => {

        let index = 0;
        let timer;

        if (typing && finalText) {

            timer = setInterval(() => {
                setTextValue(finalText.substring(0, index + 1));
                index++;
                if (index === finalText.length) {
                    clearInterval(timer);
                    setTyping(false); // Stop typing once the final text is completely displayed
                    onFinishTyping();
                }
            }, finalText.length > 5 ? 50 : 200); // Adjust typing speed as necessary

        }

        return () => clearInterval(timer); // Cleanup interval on component unmount

    }, [typing, finalText]);  // include the finalText dependency here, since otherwise if you switch between messages quickly, the typing may still continue, causing a bug

    return (
        <TextArea
            showCount
            maxLength={140}
            autoSize={{ minRows: 3 }}
            allowClear
            readOnly
            value={textValue}
            placeholder={props.placeholder}
            onPressEnter={props.onPressEnter}
            onFocus={props.onFocus}
        />
    );
};

export default TypingAnimationTextArea;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { ConfigProvider, theme } from 'antd';

// Create a Context for the theme state and toggler
const ThemeContext = createContext({
    toggleTheme: () => { }, // Default empty function
    currentTheme: theme.defaultAlgorithm,
});



// Export a hook to use the theme context
export const useTheme = () => useContext(ThemeContext);

// ThemeProvider component that provides the theme context to children
export const ThemeProvider = ({ children }) => {

    const getSystemIsDark = () => {
        // Check if the system prefers a dark theme
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return true; // Dark theme if system is set to dark
        } else {
            return false; // Explicitly set light theme if not
        }
    };


    const [isDarkMode, setIsDarkMode] = useState(
        getSystemIsDark()
    );

    useEffect(() => {
        const matcher = window.matchMedia('(prefers-color-scheme: dark)');

        const handleChange = (event) => {
            setIsDarkMode(event.matches);
            updateBodyClass(event.matches);
        };

        matcher.addEventListener('change', handleChange);
        updateBodyClass(isDarkMode);

        return () => {
            matcher.removeEventListener('change', handleChange);
        };

    }, []);

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
        updateBodyClass(!isDarkMode);
    };

    const updateBodyClass = (darkMode) => {
        document.body.classList.toggle('dark-mode', darkMode);
        document.body.classList.toggle('light-mode', !darkMode);
    };

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#00a9a8',
                        // colorLink: '#44bebe',
                        colorLink: '#049493',

                        // colorPrimary: '#245175',
                        // colorPrimary: '#2F5776',
                        // fontSize: 16,
                        // lineHeight: '24px',  // lineHeight is paird with fontSize in antd, see https://ant.design/docs/spec/font
                    },
                    // components: { Input: { fontSize: '16px', lineHeight: '24px' } },
                    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                }}
            >
                {children}
            </ConfigProvider>
        </ThemeContext.Provider >
    );
};



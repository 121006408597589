import React, { useContext, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import PwForget from '../PasswordForget';
import { Capacitor } from '@capacitor/core';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext } from '../Contexts';
import { signOut } from "firebase/auth";
import moment from 'moment';
import { Button, Flex } from "antd";

import withPage from '../Results';
import * as ROUTES from "../../constants/routes";

import ToSubscribe from './toSubscribe';
import MySubscription from './mySubscription';

import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};


const isNative = Capacitor.isNativePlatform();


const App = () => {

    const firebase = useContext(FirebaseContext);

    const { authUser, isLoading } = useContext(AuthUserContext);
    const navigate = useNavigate(); // Use useNavigate hook for navigation
    // const query = useQuery();


    const active = authUser?.current_period_end && authUser.current_period_end * 1000 > moment().unix();

    const Inner = active ? MySubscription : ToSubscribe;

    const [signInVisible, setSignInVisible] = useState(false);
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [pwForgetVisible, setPwForgetVisible] = useState(false);



    // const chatListRef = useRef(null);

    const handleSignInFinish = useCallback(() => {

        setSignInVisible(false);

        // if (authUser) {
        //     navigate(ROUTES.HOME);
        // }

    }, [navigate, authUser]);


    const handleCancel = (type) => {
        if (type === "signup") {
            setSignUpVisible(false);
        } else if (type === "signin") {
            setSignInVisible(false);
        } else if (type === "pwforget") {
            setPwForgetVisible(false);
        }
    }

    // useEffect(() => {
    //     // Read the 'mode' query parameter
    //     const mode = query.get('mode');

    //     // console.log('mode is', mode);

    //     if (mode === 'signup' && !authUser) {
    //         //authUser isn't populated fast enough
    //         setSignUpVisible(true);
    //     } else if (mode === 'signin' && !authUser) {
    //         setSignInVisible(true);
    //     }
    // }, [query]);

    // handle finish of sign up
    const handleFinish = useCallback(() => {
    }, []);

    const goToOtherModal = useCallback((type) => {

        if (type === "signup") {
            handleCancel('signin');
            setSignUpVisible(true);
        }
        else if (type === "signin") {
            handleCancel('signup');
            setSignInVisible(true);
        } else if (type === "pwforget") {
            handleCancel('signin');
            setPwForgetVisible(true);
        }

    }, []);

    const onSignIn = useCallback(() => {

        hapticsImpactLight();

        if (authUser) {
            // navigate(ROUTES.HOME);
        } else {
            setSignInVisible(true);
        }

    }, [authUser, navigate]);



    const onSignOut = useCallback(() => {
        localStorage.setItem('pendingMessages', null);
        signOut(firebase.auth);
        hapticsImpactLight();
    }, [firebase.auth]);




    return (

        <>
            <SignIn
                visible={signInVisible}
                handleCancel={() => handleCancel('signin')}
                goToOtherModal={goToOtherModal}
                handleFinish={handleSignInFinish}
            />
            <SignUp visible={signUpVisible}
                handleCancel={() => handleCancel('signup')}
                goToOtherModal={() => goToOtherModal('signin')}
                handleFinish={handleFinish}
            />
            <PwForget visible={pwForgetVisible}
                handleCancel={() => handleCancel('pwforget')}
            />

            <Inner promptLogin={onSignIn} authUser={authUser} />

            {authUser &&
                <Flex justify='center' gap="large" align='end' style={{ marginTop: 40 }}>
                    <Button key="home" onClick={() => navigate(ROUTES.HOME)}>
                        Homepage
                    </Button>
                    <Button onClick={onSignOut}>Sign Out</Button>
                </Flex>}

            {/* <Row type="flex" justify="center" style={{ marginTop: 40 }}></Row> */}
        </>

    );
};


// export default App;


export default withPage(App);


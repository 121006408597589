// this is chat demo component for recording screen sims in a nice even way

import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from 'react';
import { message, Typography, Alert, Button, Flex } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../Firebase';
import { AuthUserContext, useScreen, useNetwork } from '../Contexts';
import { ref, get, remove, update, push, runTransaction, } from 'firebase/database';

import ReactMarkdown from 'react-markdown';

import { sendChat } from '../../functions';
import ChatModule from './chatModule';
import ChatList from './chatList';
import EventDetailsModal from './eventDetailsModal';
import { CustomTour } from './customTour';
import WelcomeModal from './welcomeModal';
import FloatMenu from './floatMenu';
// import useFirebaseToken from './useFirebaseToken';
import TypingAnimationTextArea from '../Landing/typingAnimatedTextArea';


import moment from 'moment-timezone';


const { Text } = Typography;

const dayMod = -3;


// Predefined simulated chat data
const AllSimulatedChatData = [
    {
        history: [],
        interaction: [
            {
                text: "fed the cat 5 min ago and the dog just now",
                response: [{
                    event: 'fed the cat',
                    timeFunction: ts => ts - (5 * 60),
                },
                {
                    event: 'fed the dog',
                    timeFunction: ts => ts,
                }]
            },
            {
                text: "gave the puppy water",
                response: [{
                    event: 'gave the puppy water',
                    timeFunction: ts => ts,
                }]
            },
            {
                textFunction: ts => `groomed the puppy for an hour 10 min until ${moment.unix(ts - (36 * 60)).format('h:mm') + (moment.unix(ts - (36 * 60)).format('a') === 'pm' ? 'pm' : '')}`,
                response: [{
                    event: 'groomed the puppy',
                    timeFunction: ts => ts - (106 * 60),
                    endTimeFunction: ts => ts - (36 * 60),
                }]
            },
            {
                text: "played with the puppy for 25 min",
                response: [{
                    event: 'played with the puppy',
                    timeFunction: ts => ts - (25 * 60),
                    endTimeFunction: ts => ts,
                }],
            }
        ]
    },
    {
        useHistoryTime: true,
        history: [
            {
                text: 'snacked on chips and dips for 15 min',
                status: 'sent',
                timeFunction: tsInMilli => tsInMilli - (83 * 60 * 1000),
                details: [{
                    event: 'snacked on chips and dips',
                    timeFunction: ts => ts - ((83 + 15) * 60),
                    endTimeFunction: ts => ts - (83 * 60)
                }]
            },
            {
                text: 'stretched for 25 min until 10 min ago',
                status: 'sent',
                timeFunction: tsInMilli => tsInMilli,
                details: [{
                    event: 'stretched',
                    timeFunction: ts => ts - ((25 + 10) * 60),
                    endTimeFunction: ts => ts - (10 * 60)
                }]
            },
        ],
        interaction: [
            {
                text: "meditated between the snack and the stretching",
                response: [{
                    event: 'meditated',
                    timeFunction: ts => ts - (83 * 60),
                    endTimeFunction: ts => ts - ((25 + 10) * 60),
                }],
            }
        ]
    },
    {
        history: [
            {
                text: 'started writing the report',
                status: 'sent',
                timeFunction: tsInMilli => tsInMilli - (55 * 60 * 1000),
                details: [
                    {
                        event: 'started writing the report',
                        timeFunction: ts => ts - (55 * 60)
                    }]
            },
        ],
        interaction: [
            {
                text: "done",
                response: [{
                    event: 'done writing the report',
                    timeFunction: ts => ts,
                }],
            }
        ]
    },
    {
        history: [
        ],
        interaction: [
            {
                text: "feeling enthusiastic",
                response: [{
                    event: 'feeling enthusiastic',
                    timeFunction: ts => ts,
                }],
            },
            {
                text: "weight: 150 lbs",
                response: [{
                    event: 'weight: 150 lbs',
                    timeFunction: ts => ts,
                }],
            }
        ]
    },
];

const slideNum = 0;

const ChatDemo = () => {

    // const [firebaseToken, refreshToken] = useFirebaseToken();

    const { authUser, setAuthUser } = useContext(AuthUserContext);

    const targetComponentRef = useRef(null);

    // state var to track that one message is being processed, hence none else should also be processed
    const [answerLoading, setAnswerLoading] = useState(false);
    const [chat, setChat] = useState(Array(3).fill({ text: '' })); // use a filler to add a skeleton
    const [chatHistoryLoading, setChatHistoryLoading] = useState(true); // start as true if you want to immediately show a loading state

    const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(false);
    // const [isChatModalVisible, setIsChatModalVisible] = useState(false);

    const [eventDetailsMap, setEventDetailsMap] = useState({});

    const [teachBotTarget, setTeachBotTarget] = useState(null);

    const [messageQueue, setMessageQueue] = useState([]);
    const [tourOpen, setTourOpen] = useState(false);

    const { width: screenWidth, height: screenHeight, isTouch } = useScreen();


    const [currentStep, setCurrentStep] = useState(0);

    const [textValue, setTextValue] = useState('');

    const [currentTime, setCurrentTime] = useState(null);
    const [historyTime, setHistoryTime] = useState(null);





    useEffect(() => {
        if (targetComponentRef.current) {
            // Get the position of the target component relative to the document
            const topPosition = targetComponentRef.current.getBoundingClientRect().top + window.scrollY;

            // Scroll to the target component
            window.scrollTo({ top: topPosition, behavior: 'smooth' });
        }
    }, []);



    const setIsTeachModalVisible = useCallback((visible) => {
        if (!visible) {
            setTeachBotTarget(null);
        }
    }, []);  // No dependencies, since setTeachBotTarget is stable


    const [messageApi, contextHolder] = message.useMessage();

    const firebase = useContext(FirebaseContext);


    const user = firebase.auth.currentUser;
    const uid = user?.uid;
    const { db } = firebase;

    const chatListRef = useRef(null);


    const handleTeachModalFinish = useCallback(
        async (oldVal, newVal, removals, updates, additions, oldText, newText, teachBot, chatTime) => {
            if (newVal.length === 0) { return; } // Don't process empty edits
            let newItem = [...newVal]; // Copy to manage updates locally

            const promises = [];

            if (!newText && removals.length === 0 && updates.length === 0 && additions.length === 0) {
                console.log('No new text, and no changes detected between the old and new values.');
                return;
            }

            if (newText && newText !== oldText) {



                setChat((currentChat) =>
                    currentChat.map((chatItem) =>
                        chatItem.time === chatTime ? { ...chatItem, text: newText } : chatItem
                    )
                );
            }





            setEventDetailsMap(prev => ({ ...prev, [chatTime]: newItem }));



            // Update response in u_chats
            if (removals.length > 0 || additions.length > 0) {
                // const oldResponse = oldVal.map(item => item.key);

                const keptKeys = newItem.map(item => item.key);

                // // Construct the update object with continuous indices for kept keys
                // let responseUpdateObject = keptKeys.reduce((acc, pushId, index) => {
                //     acc[String(index)] = pushId; // Keep continuous indices for kept items
                //     return acc;
                // }, {});

                // // Append nulls for deleted keys to indicate deletion in Firebase
                // removals.forEach((_, index) => {
                //     responseUpdateObject[String(keptKeys.length + index)] = null; // Mark for deletion
                // });


                // const responseRef = ref(db, `u_chats/${uid}/${chatTime}/response`);
                // await update(responseRef, responseUpdateObject);
                setChat((currentChat) =>
                    currentChat.map((chatItem) =>
                        chatItem.time === chatTime ? { ...chatItem, response: keptKeys } : chatItem
                    )
                );
            }

            console.log('Updates to chat and records completed successfully.');
        },
        [] // Dependencies
    );



    const fetchEventDetailsForItem = useCallback(async (item) => {

        let fetchedDetails = [];

        if (eventDetailsMap[item.time]) {
            console.log('Using cached details');
            return eventDetailsMap[item.time];
        }


        // else if (typeof item.response === 'string' && item.response !== '') {
        //     // setEventDetailsMap(prev => ({ ...prev, [item.time]: { details: [item.response], textTime: item.time, text: item.text, timezone: item.timezone, recorded: false } }));
        //     setEventDetailsMap(prev => ({ ...prev, [item.time]: item.response }));
        //     return [item.response];
        // } else if (uid && item.response) {
        //     // technically user should already be populated, since to click on a chat item, the user must be logged in
        //     const pushIds = item.response; // Assuming this is an array of push IDs

        //     for (let pushId of pushIds) {
        //         const eventRef = ref(db, `u_records/${uid}/${pushId}`);
        //         try {
        //             const snapshot = await get(eventRef);
        //             if (snapshot.exists()) {
        //                 const eventData = snapshot.val();
        //                 fetchedDetails.push({ ...eventData, key: pushId });
        //             }
        //         } catch (error) {
        //             console.error("Error fetching event details:", error);
        //         }
        //     }
        fetchedDetails = item.details;

        setEventDetailsMap(prev => ({ ...prev, [item.time]: fetchedDetails }));

        return fetchedDetails;

    }, [uid, db, eventDetailsMap]);


    const handleTeachBot = useCallback((item) => {
        fetchEventDetailsForItem(item);

        setTeachBotTarget(item.time);


    }, [fetchEventDetailsForItem, setTeachBotTarget]);




    const scrollToBottom = useCallback(() => {
        const scroll = chatListRef.current;
        if (scroll) {
            scroll.scrollTop = scroll.scrollHeight;
            // scroll.scrollTo({ top: scroll.scrollHeight, behavior: 'smooth' });
        }
    }, []); // No dependencies, so it only creates the function once


    // if showing details and if it's the last item, then scroll to the bottom
    const handleDetailsToggle = useCallback(
        (itemTime) => {
            const lastIdxTime = chat[chat.length - 1]?.time; // Optional chaining in case chat is empty
            if (itemTime === lastIdxTime) {
                scrollToBottom();
            }

            // else {
            //     chatListRef.current?.scrollBy({
            //         top: 80, // or any positive or negative value you want to scroll by vertically
            //         left: 0, // or any positive or negative value you want to scroll by horizontally
            //         behavior: 'smooth' // This enables smooth scrolling
            //     });
            // }
        },
        [chat, scrollToBottom] // Dependencies
    );





    const prevChatsLength = useRef(chat.length);

    useEffect(() => {
        if (chat.length > prevChatsLength.current) {
            scrollToBottom();
        }

        if (chat.length !== prevChatsLength.current) {
            prevChatsLength.current = chat.length;
        }
        // Update the previous length for the next render

    }, [chat.length, scrollToBottom]); // Dependency only on chats.length



    useEffect(() => {
        // this sets the text value to the current step's text

        if (currentStep >= 0) {
            const simulatedChatData = AllSimulatedChatData[slideNum].interaction;
            const currentStepData = simulatedChatData[currentStep];

            if (!currentStepData) {
                return;
            }

            const msg = currentStepData.text;
            const utcTimestamp = Date.now();
            const ts = Math.floor(utcTimestamp / 1000);
            // const response = currentStepData.response;
            if (currentStepData.textFunction) {
                setCurrentTime(ts);
            } else {
                setCurrentTime(null);
            }
            const text = msg ? msg : currentStepData.textFunction(ts);
            setTextValue(text);
        }

    }, [currentStep, slideNum]);



    useEffect(() => {


        const fetchChatHistory = async () => {

            if (uid && db) {
                setChatHistoryLoading(false);
                setChat(AllSimulatedChatData[slideNum].history);

            }
        };


        fetchChatHistory();


        // }, []);
    }, [uid, db]);




    const markdownComponents = useMemo(() => ({
        // Other components as needed...

        li: ({ node, ...props }) => (
            <li>
                <Text style={{ fontSize: 'smaller' }}>{props.children}</Text>
            </li>
        ),
    }), []); // Dependencies array is empty because we assume markdownComponents doesn't depend on any props or state


    const showWarning = useCallback((val, type, duration = 3, icon = null) => {

        messageApi.open({
            type,
            content: <div style={{ textAlign: 'left' }}>
                <ReactMarkdown className='react-markdown' components={markdownComponents}>
                    {val}</ReactMarkdown>
            </div >,
            duration,
            icon
        });

    }, [messageApi, markdownComponents]);




    const handleSendClick = async () => {

        setTextValue('');

        const utcTimestamp = Date.now() + (dayMod * 24 * 3600 * 1000);

        const newMessage = { text: textValue, status: 'sending', time: utcTimestamp };
        // Add message immediately to chatList
        setChat(prevChat => [...prevChat, { ...newMessage }]);

        const simulatedChatData = AllSimulatedChatData[slideNum].interaction;
        const currentStepData = simulatedChatData[currentStep];
        const response = currentStepData.response;



        let ts;
        if (AllSimulatedChatData[slideNum].useHistoryTime) {
            ts = historyTime;
        } else {
            ts = currentStepData.textFunction ? currentTime + (dayMod * 24 * 3600) : Math.floor(utcTimestamp / 1000);
        }


        setTimeout(() => {

            const details = response.map(res => ({
                event: res.event,
                time: res.timeFunction(ts),
                end_time: res.endTimeFunction ? res.endTimeFunction(ts) : null,
            }));

            setChat(prevChat => prevChat.map((item) => {
                return item.time === utcTimestamp ? {
                    ...item,
                    status: 'sent',
                    details: details,
                    response: Array(response.length).fill(0)
                }
                    : item
            }));

            setEventDetailsMap(prev => ({ ...prev, [utcTimestamp]: details }));

        }, 800);

    };


    const handleResend = useCallback(
        async (utcTimestamp, resendFlag = true) => {
            // Find the message to resend using the utcTimestamp
            const messageToResend = chat.find((item) => item.time === utcTimestamp);

            if (!messageToResend) {
                console.error("Message not found for resending");
                return;
            }

            // Check if queue is full before adding
            setMessageQueue((prevQueue) => {
                if (prevQueue.length < 3) {
                    // Set the message as queued again
                    setChat((prevChat) =>
                        prevChat.map((item) =>
                            item.time === utcTimestamp ? { ...item, status: 'sending' } : item
                        )
                    );

                    // Add to queue for resending
                    return [...prevQueue, { ...messageToResend, status: 'sending', resend: resendFlag }];
                } else {
                    console.warn("Queue is full, cannot resend at this moment.");
                    showWarning('Queue is full, cannot resend at this moment', 'warning', 3);
                    return prevQueue; // Optionally handle or notify when queue is full
                }
            });
        },
        [chat, showWarning]
    );


    const onCloseTour = useCallback(() => {
        setTourOpen(false);
        if (targetComponentRef.current) {
            const textarea = targetComponentRef.current.querySelector('textarea');
            textarea?.focus();
        }

    }, []);

    const onBeginTour = useCallback((bl) => {

        if (bl) {
            setTourOpen(true);
        }

        setIsWelcomeModalVisible(false);


    }, []);



    const onFinishTour = useCallback(() => {
        if (authUser && !authUser.settings?.chat_tour_seen) {
            setAuthUser({ ...authUser, settings: { ...authUser.settings, chat_tour_seen: true } });
            const userRef = ref(db, `u_settings/${uid}`);
            update(userRef, { chat_tour_seen: true }).catch((error) => { console.error(error) });
        }
    }, [authUser, setAuthUser]);


    const handleDelete = useCallback(async (item) => {
        // Update local state to remove the item that's the same as the one to be deleted
        setChat(currentChat => currentChat.filter(listItem => listItem.time !== item.time));

    }, []); // Dependencies

    const removeMessageFromQueue = useCallback((timeKey) => {
        setMessageQueue((currentQueue) => currentQueue.filter((obj) => obj.time !== timeKey));
    }, []);




    return (
        <div>

            {contextHolder}

            <FloatMenu
                top={50}
                screenWidth={screenWidth}
                screenHeight={screenHeight}
                tourOpen={tourOpen}
                setTourOpen={setTourOpen}
            />


            <div
                ref={chatListRef}
                style={{
                    maxHeight: screenHeight - 380, // Set a fixed height here
                    overflowY: 'auto',   // Enable vertical scrolling
                    width: '100%',
                }}>



                <ChatList
                    chat={chat}
                    chatHistoryLoading={chatHistoryLoading}
                    handleResend={handleResend}
                    handleDelete={handleDelete}
                    handleDetailsToggle={handleDetailsToggle}
                    fetchEventDetailsForItem={fetchEventDetailsForItem}
                    handleTeachBot={handleTeachBot}
                    eventDetailsMap={eventDetailsMap}
                    isOnline={true}
                    demo={true}
                />
            </div>

            {
                chatHistoryLoading ? null :
                    <EventDetailsModal
                        eventDetails={eventDetailsMap[teachBotTarget]}
                        chatEntry={chat.find((item) => item.time === teachBotTarget)}
                        isVisible={teachBotTarget !== null}
                        setIsVisible={setIsTeachModalVisible}
                        onFinish={handleTeachModalFinish}
                    />
            }

            <div style={{ marginTop: 30 }}
            >
                <WelcomeModal
                    modalVisible={isWelcomeModalVisible}
                    onBeginTour={onBeginTour}
                />
            </div>


            <div style={{ marginTop: 30 }}
                ref={targetComponentRef}>
                {/* <ChatModule
                    handleSendClick={handleSendClick}
                    messageQueue={messageQueue}
                    removeMessageFromQueue={removeMessageFromQueue}
                    screenWidth={screenWidth}
                    screenHeight={screenHeight}
                    isTouch={isTouch}
                    isOnline={true}
                /> */}
                <Flex size='large' align="flex-end" gap='small' style={{ width: '100%' }}>
                    <TypingAnimationTextArea
                        placeholder={"Enter what you wish to record..."}
                        finalText={textValue}
                        onPressEnter={handleSendClick}
                        lastIsSent={chat.length > 0 ? chat[chat.length - 1].status === 'sent' : true}
                        onFinishTyping={() => { }}
                        initialTimeout={1000}
                        style={{ width: '100%' }}
                        onFocus={() => { setCurrentStep(prev => prev + 1) }}
                    />

                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={handleSendClick}
                    />
                </Flex>
            </div>


            <CustomTour
                // ref1={floatButtonRef}
                // ref2={chatListRef}
                open={tourOpen}
                onCloseTour={onCloseTour}
                onFinishTour={onFinishTour}
                screenWidth={screenWidth}
            />

            {/* <Text type="secondary">
                {responseTime ? `Response time: ${(responseTime / 1000).toFixed(1)} s` : 'Calculating...'}
            </Text> */}

        </div >
    );

};

export default ChatDemo;

import React, { useState, useContext } from 'react';
import { Form, Input, Tooltip, Button, Modal, message } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../Firebase'; // Assuming this is the correct path
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { LockOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};



const PasswordChangeForm = ({ visible, handleCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);



  const onFinish = async (values) => {
    const { password, currentPassword } = values;
    setLoading(true);

    const user = firebase.auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    const maxRetries = 3; // Maximum number of retries
    let retries = 0; // Current retry count

    const tryUpdatePassword = async () => {
      try {
        await updatePassword(user, password);
        message.success("The password has been changed");
        onSubmit();
        handleCancel();
        form.resetFields();
        setLoading(false);
      } catch (error) {
        if (error.code === 'auth/requires-recent-login' && retries < maxRetries) {
          retries++; // Increment retry count
          try {
            await reauthenticateWithCredential(user, credential);
            await tryUpdatePassword(); // Retry password update after successful reauthentication
          } catch (reauthError) {
            console.error('Re-authentication failed:', reauthError);
            message.error('Re-authentication failed, please try again.', 2);
            setLoading(false);
          }
        } else {
          console.error('Password update failed:', error);
          message.error('Password update failed. Please try again later.', 2);
          setLoading(false);
        }
      }
    };

    tryUpdatePassword();
  };


  return (
    <Modal
      open={visible}
      // title="Change Password"
      onCancel={handleCancel}
      footer={null}
      closable={true}
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          remember: true,
        }}
        {...formItemLayout}
        style={{
          maxWidth: 600,
          margin: 'auto',
          paddingTop: 40,
        }}
      >
        <Form.Item
          name="currentPassword"
          label="Old Password"
          rules={[
            {
              required: true,
              message: 'Please input your old password',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="password"
          label={
            <span>
              New Password&nbsp;
              <Tooltip title="Use letters, numbers and special characters">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
            {
              min: 10, message: 'Password must be at least 10 characters long'
            },
            {
              max: 20, message: 'Password must be at most 20 characters long'
            }
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ opacity: 0.3 }} />}
            type="password"
            placeholder="Password 10 characters or longer"
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Reset My Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordChangeForm;

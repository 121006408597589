import React, { useState, useEffect } from 'react';
import { List, Button, Flex, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import ChatItem from './simulatedChatItem';
import moment from 'moment';
import TweenOne from 'rc-tween-one';
import TypingAnimationTextArea from './typingAnimatedTextArea';

const animation = [
    {
        y: -20,
        scaleX: 0.9,
        scaleY: 1.1,
        ease: 'easeOutQuad',  // ease definitions: https://easings.net/en
        duration: 200
    },
    {
        y: 0,
        scaleX: 1,
        scaleY: 1,
        ease: 'easeOutBounce',  // ease definitions: https://easings.net/en
        duration: 700
    }
];

// const historyAnimation = [];


// Predefined simulated chat data
const AllSimulatedChatData = [
    {
        history: [],
        interaction: [
            {
                text: "fed the cat 5 min ago and the dog just now",
                response: [{
                    event: 'fed the cat',
                    timeFunction: ts => ts - (5 * 60),
                },
                {
                    event: 'fed the dog',
                    timeFunction: ts => ts,
                }]
            },
            {
                text: "gave the puppy water",
                response: [{
                    event: 'gave the puppy water',
                    timeFunction: ts => ts,
                }]
            },
            {
                textFunction: ts => `groomed the puppy for an hour 10 min until ${moment.unix(ts - (36 * 60)).format('h:mm') + (moment.unix(ts - (36 * 60)).format('a') === 'pm' ? 'pm' : '')}`,
                response: [{
                    event: 'groomed the puppy',
                    timeFunction: ts => ts - (106 * 60),
                    endTimeFunction: ts => ts - (36 * 60),
                }]
            },
            {
                text: "played with the puppy for 25 min",
                response: [{
                    event: 'played with the puppy',
                    timeFunction: ts => ts - (25 * 60),
                    endTimeFunction: ts => ts,
                }],
            }
        ]
    },
    {
        useHistoryTime: true,
        history: [
            {
                text: 'snacked on chips and dips for 15 min',
                status: 'sent',
                timeFunction: tsInMilli => tsInMilli - (83 * 60 * 1000),
                details: [{
                    event: 'snacked on chips and dips',
                    timeFunction: ts => ts - ((83 + 15) * 60),
                    endTimeFunction: ts => ts - (83 * 60)
                }]
            },
            {
                text: 'stretched for 25 min until 10 min ago',
                status: 'sent',
                timeFunction: tsInMilli => tsInMilli,
                details: [{
                    event: 'stretched',
                    timeFunction: ts => ts - ((25 + 10) * 60),
                    endTimeFunction: ts => ts - (10 * 60)
                }]
            },
        ],
        interaction: [
            {
                text: "meditated between the snack and the stretching",
                response: [{
                    event: 'meditated',
                    timeFunction: ts => ts - (83 * 60),
                    endTimeFunction: ts => ts - ((25 + 10) * 60),
                }],
            }
        ]
    },
    {
        history: [
            {
                text: 'started writing the report',
                status: 'sent',
                timeFunction: tsInMilli => tsInMilli - (55 * 60 * 1000),
                details: [
                    {
                        event: 'started writing the report',
                        timeFunction: ts => ts - (55 * 60)
                    }]
            },
        ],
        interaction: [
            {
                text: "done",
                response: [{
                    event: 'done writing the report',
                    timeFunction: ts => ts,
                }],
            }
        ]
    },
    {
        history: [
        ],
        interaction: [
            {
                text: "feeling enthusiastic",
                response: [{
                    event: 'feeling enthusiastic',
                    timeFunction: ts => ts,
                }],
            },
            {
                text: "weight: 150 lbs",
                response: [{
                    event: 'weight: 150 lbs',
                    timeFunction: ts => ts,
                }],
            }
        ]
    },
];

const SimulatedChat = ({ slideNum, scrollToBottom, onNext }) => {



    const [chat, setChat] = useState([]);

    const [currentStep, setCurrentStep] = useState(0);

    const [textValue, setTextValue] = useState('');
    const [currentTime, setCurrentTime] = useState(null);
    const [historyTime, setHistoryTime] = useState(null);
    const [animateHistory, setAnimateHistory] = useState(false);
    const [finishedTyping, setFinishedTyping] = useState(false);


    useEffect(() => {
        // this sets the original chat history for each slide

        const originalUtcTimestamp = Date.now();
        const OUTinSeconds = Math.floor(originalUtcTimestamp / 1000);
        const chatHistoryRaw = AllSimulatedChatData[slideNum].history;
        const chatHistory = chatHistoryRaw.length > 0 ? chatHistoryRaw.map(c => ({
            text: c.text ? c.text : c.textFunction(originalUtcTimestamp),
            status: c.status,
            time: c.timeFunction(originalUtcTimestamp),
            details: c.details.map(dtls => ({
                event: dtls.event,
                time: dtls.timeFunction(OUTinSeconds),
                end_time: dtls.endTimeFunction ? dtls.endTimeFunction(OUTinSeconds) : null
            }))
        })) : [];

        setChat([...chatHistory]);
        setCurrentStep(0);
        setCurrentTime(null);
        setHistoryTime(OUTinSeconds);
        setAnimateHistory(true); // Enable animation only initially
        setFinishedTyping(false);

    }, [slideNum]);


    useEffect(() => {
        // this sets the text value to the current step's text

        if (currentStep >= 0) {
            const simulatedChatData = AllSimulatedChatData[slideNum].interaction;
            const currentStepData = simulatedChatData[currentStep];

            if (!currentStepData) {
                return;
            }

            const msg = currentStepData.text;
            const utcTimestamp = Date.now();
            const ts = Math.floor(utcTimestamp / 1000);
            // const response = currentStepData.response;
            if (currentStepData.textFunction) {
                setCurrentTime(ts);
            } else {
                setCurrentTime(null);
            }
            const text = msg ? msg : currentStepData.textFunction(ts);
            setTextValue(text);
        }

    }, [currentStep, slideNum]);


    const onFinish = async () => {
        const utcTimestamp = Date.now();
        const newMessage = { text: textValue, status: 'sending', time: utcTimestamp };
        // Add message immediately to chatList
        setChat(prevChat => [...prevChat, { ...newMessage }]);

        const simulatedChatData = AllSimulatedChatData[slideNum].interaction;
        const currentStepData = simulatedChatData[currentStep];
        const response = currentStepData.response;



        let ts;
        if (AllSimulatedChatData[slideNum].useHistoryTime) {
            ts = historyTime;
        } else {
            ts = currentStepData.textFunction ? currentTime : Math.floor(utcTimestamp / 1000);
        }


        setTimeout(() => {
            setChat(prevChat => prevChat.map((item) => {
                return item.time === utcTimestamp ? {
                    ...item,
                    status: 'sent',
                    details: response.map(res => ({
                        event: res.event,
                        time: res.timeFunction(ts),
                        end_time: res.endTimeFunction ? res.endTimeFunction(ts) : null,
                    }))
                }
                    : item
            }));

        }, 800);

    };


    useEffect(() => {


        // this increments the current step if chat increases; 
        const simulatedChatData = AllSimulatedChatData[slideNum].interaction;
        const chatHistoryRaw = AllSimulatedChatData[slideNum].history || [];
        const detailsLength = chat.length - chatHistoryRaw.length;

        if (currentStep !== detailsLength && detailsLength < simulatedChatData.length) {
            setCurrentStep(detailsLength);
        } else if (detailsLength >= simulatedChatData.length) {
            setCurrentStep(-1);
        }


        if (chat.length > chatHistoryRaw.length) {
            setAnimateHistory(false); // Disable animation after initial load
        }

        setFinishedTyping(false);


    }, [chat, slideNum]);   //I dont' think currentStep is needed here, since we can move it into setCurrentStep as the existing value



    const handlePressEnter = e => {
        e.preventDefault();
        onFinish();
    }

    const renderItem = (item) => (
        <ChatItem
            key={item.time}
            item={item}
            scrollToBottom={scrollToBottom}
        />
    );

    const handleNext = () => {
        // setCurrentStep(0);  // not needed since we're setting it in the useEffect
        onNext(slideNum >= AllSimulatedChatData.length - 1);
    }


    const onFinishTyping = () => {
        setFinishedTyping(true);
    }


    return (
        <div style={{ maxWidth: 600, width: '100%', margin: '30px auto' }}>

            <Flex vertical gap="large" >

                {chat.length > 0 ?
                    // (animateHistory ?
                    <TweenOne
                        animation={{
                            y: -30,
                            opacity: 0,
                            type: 'from',
                            duration: 500,
                            ease: 'easeInCubic',
                        }}
                        delay={500}
                        key={slideNum}
                    >
                        <List
                            className="chat-list"
                            itemLayout="horizontal"
                            dataSource={chat}
                            renderItem={renderItem}
                            bordered={true}
                        />
                    </TweenOne>
                    // :
                    // <List
                    //     className="chat-list"
                    //     itemLayout="horizontal"
                    //     dataSource={chat}
                    //     renderItem={renderItem}
                    //     bordered={true}
                    // />)
                    // <List
                    //     className="chat-list"
                    //     itemLayout="horizontal"
                    //     dataSource={chat}
                    //     renderItem={renderItem}
                    //     bordered={true}
                    // />
                    :
                    null
                }

                {currentStep < 0 ?
                    <Flex justify="center" ><Button type="primary" onClick={handleNext}>{slideNum < AllSimulatedChatData.length - 1 ? "Next" : "Go to the Start"}</Button></Flex> :
                    <Flex size='large' align="flex-end" gap='small' style={{ width: '100%' }}>

                        <TweenOne
                            animation={{
                                y: -60,
                                opacity: 0,
                                type: 'from',
                                duration: 500,
                                ease: 'easeInCubic',
                            }}
                            style={{ flexGrow: 1, display: 'inline-block', width: "100%" }}
                        >


                            <TypingAnimationTextArea
                                placeholder={"Enter what you wish to record..."}
                                finalText={textValue}
                                onPressEnter={handlePressEnter}
                                lastIsSent={chat.length > 0 ? chat[chat.length - 1].status === 'sent' : true}
                                onFinishTyping={onFinishTyping}
                                initialTimeout={1000}
                                style={{ width: '100%' }}
                            />
                        </TweenOne>

                        <TweenOne
                            // animation={finishedTyping ? animation : null} // Only apply animation if finishedTyping is true
                            animation={animation}
                            paused={!finishedTyping} // Pause animation when finishedTyping is false
                            style={{ display: 'inline-block', flexShrink: 0 }} // Necessary for animation to affect layout
                            repeat={-1}
                            repeatDelay={3000}
                        >
                            <Button
                                type="primary"
                                icon={<SendOutlined />}
                                onClick={onFinish}
                            />
                        </TweenOne>

                    </Flex>
                }
            </Flex >
        </div>
    );
};

export default SimulatedChat;

import React, { useState, useEffect, useRef } from 'react';
import { List, Skeleton, Tooltip, Popconfirm, Avatar, Typography } from 'antd';
import { CheckSquareOutlined, SyncOutlined, LoadingOutlined, DeleteOutlined, RobotOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
// import { ref, get } from 'firebase/database';

import { Comment } from '@ant-design/compatible';
import ReactMarkdown from 'react-markdown';
import moment from 'moment-timezone';
import { formatMarkdownContent } from './utils';
import { FOCUSABLE_SELECTOR } from '@testing-library/user-event/dist/utils';
// import CustomComment from './customComment';

const { Text } = Typography;

const ChatItem = ({ item, chatHistoryLoading, onResend, onDelete, onDetailsToggle, handleTeachBot, fetchEventDetails, eventDetails, isOnline, demo }) => {

    let responseIcon;
    let tooltipTitle;

    // new items, when created, have eventDetails -- by default show them
    const [showDetails, setShowDetails] = useState(eventDetails);
    // const [eventDetails, setEventDetails] = useState([]);
    // const [isFetching, setIsFetching] = useState(false);
    const [toggle, setToggle] = useState(true);


    // console.log('re rendering chatItem', item.time, item.status, eventDetails);

    useEffect(() => {

        // console.log('eventDetails in ChatITem useEffect', item.time, eventDetails);

        if (eventDetails && showDetails && toggle) {
            // call parent function if it's expansion; currently onDetailsToggle scrolls to the bottom if it's the last item
            onDetailsToggle();
            setToggle(false);
        }

    }, [showDetails, onDetailsToggle]);  // toggle is deliberately left out of the dependency array



    useEffect(() => {

        // console.log('eventDetails in ChatITem useEffect', item.time, eventDetails);

        if (eventDetails && toggle) {
            setShowDetails(true);
        }

    }, [eventDetails, setShowDetails]);  // toggle is deliberately left out of the dependency array


    if (item.status === 'sending') {
        responseIcon = <LoadingOutlined style={{ fontSize: '14px' }} />;
        tooltipTitle = "Processing";
    } else {
        if (item.response && Array.isArray(item.response)) {
            responseIcon = <CheckSquareOutlined style={{ fontSize: '14px' }} />;
            tooltipTitle = "Recorded";
        }
        else {
            responseIcon = <SyncOutlined style={{ fontSize: '14px' }} />;
            tooltipTitle = "Resend";
        }
    }


    const confirmDelete = () => {
        onDelete(item);
    };


    const handleResend = (item) => {
        if (item.status !== 'sending' && !(item.response && Array.isArray(item.response))) {
            onResend(item.time);
        }
    };





    const toggleShowDetails = () => {
        // since the parent holds eventDetailsMap and the data is cached, we don't need to fetch from db again
        // so it's prudent to re-fetch the eventDetails even if our local state already has it

        if (showDetails) {
            setToggle(true);
        } else {

            fetchEventDetails();
        }
        setShowDetails(!showDetails);



    };




    const renderEventDetails = () => {
        if (!eventDetails) {
            return <p>Loading...</p>;
        }
        // show eventDetails if is an array
        if (showDetails && Array.isArray(eventDetails)) {

            const markdownContent = formatMarkdownContent(eventDetails, 'h:mma, ddd MMM D', 'start');

            return <ReactMarkdown className='react-markdown' components={markdownComponents}>{markdownContent}</ReactMarkdown>;
        } else {
            // otherwise show the error msg
            return <ReactMarkdown className='react-markdown' components={markdownComponents}>{eventDetails}</ReactMarkdown>;
        }

    };


    const onTeachBot = () => {

        // if (showDetails) {
        //     toggleShowDetails();
        // }

        handleTeachBot();

    };



    const markdownComponents = {
        // Other components as needed...
        // ul: ({ node, ...props }) => <List>{props.children}</List>,
        li: ({ node, ...props }) => (
            <li>
                <Text style={{ fontSize: 'smaller' }}>{props.children}</Text>
            </li>
        ),
        a: ({ node, ...props }) => {
            // Use only internal links with NavLink, for external links fallback to normal <a>
            const { href, children } = props;

            console.log('href is', href, 'chidren is', children);
            // const isInternal = href && (href.startsWith('/') || href.startsWith('#'));
            const isExternal = href && href.startsWith('http');

            return isExternal ? (
                <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
            ) : (
                <NavLink to={href}>{children}</NavLink>
            );
        },
    };

    const actions = [
        <Tooltip key="comment-basic-response" title={tooltipTitle}>
            <span style={{ marginRight: 20 }} onClick={() => handleResend(item)}>{responseIcon}</span>
        </Tooltip>,
        item.status !== 'sending' ? <Popconfirm
            key="comment-basic-delete"
            title="This will delete the chat entry and the associated event entry. Proceed?"
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
        >
            <DeleteOutlined style={{ marginRight: 20, fontSize: "14px" }} />
        </Popconfirm > : null,
        item.response ? <Tooltip key="comment-basic-response" title="Edit records">
            <span onClick={onTeachBot} style={{ marginRight: 20 }}><RobotOutlined style={{ fontSize: '14px' }} /></span>
        </Tooltip> : null,
        item.response ? <span key="comment-basic-show-details" onClick={toggleShowDetails}>
            {showDetails ? "Hide details" : "Show details"}
        </span> : null,
    ];

    return (
        <List.Item className={`chat-item ${item.status === 'sending' || item.status === 'queued' ? 'shine-effect' : ''}`}>
            <Skeleton loading={!item.text || chatHistoryLoading} active avatar={{ 'shape': 'circle' }} paragraph={false}>
                <Comment
                    author={<span>You</span>}
                    content={item.text}
                    datetime={demo ? 'Today at ' + moment(item.time).format('h:mm A') : moment(item.time).calendar()}
                    actions={isOnline ? actions : null}
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0)' }}
                >
                    {showDetails && eventDetails && (
                        <Comment
                            avatar={<Avatar src={`${process.env.PUBLIC_URL}/images/logo_simple.svg`} />}
                            author={Array.isArray(eventDetails) ? <span>Recorded</span> : <span>Not recorded</span>}
                            content={<div>{renderEventDetails()}</div>}
                            style={{ backgroundColor: 'rgba(255, 255, 255, 0)', paddingTop: 0 }}
                        />
                    )}
                </Comment>
            </Skeleton>
        </List.Item >
    );
};

export default React.memo(ChatItem);

// export default ChatItem;





// const components = {
//     h1: ({ node, ...props }) => <Title level={1}>{props.children}</Title>,
//     h2: ({ node, ...props }) => <Title level={2}>{props.children}</Title>,
//     // ... Add for h3, h4, h5, h6 if needed
//     ul: ({ node, ...props }) => <List bordered>{props.children}</List>,
//     li: ({ node, ...props }) => <List.Item>{props.children}</List.Item>,
//     inlineCode: ({ node, ...props }) => <Text code>{props.children}</Text>,
//     blockquote: ({ node, ...props }) => <Text type="secondary">{props.children}</Text>,
//     // If you need code blocks with syntax highlighting:
//     // code: ({node, inline, className, children, ...props}) => {
//     //     const match = /language-(\w+)/.exec(className || '');
//     //     return match 
//     //         ? <SyntaxHighlighter language={match[1]} children={String(children).replace(/\n$/, '')} {...props} />
//     //         : <code className={className} {...props} />
//     // }
// };

import React from 'react';
import { Switch, Tooltip } from 'antd';
import { SunOutlined, MoonOutlined } from '@ant-design/icons';
import { useTheme } from '../Contexts';

const ThemeToggleSwitch = (props) => {
    const { isDarkMode, toggleTheme } = useTheme();

    const onToggle = () => {
        toggleTheme();
        props.handleToggleTheme();
    }

    return (
        <Tooltip title="Light/Dark Mode">
            <Switch
                checkedChildren={<SunOutlined />}  // Icon for light mode
                unCheckedChildren={<MoonOutlined />}  // Icon for dark mode
                checked={!isDarkMode}  // The switch state is 'checked' when in light mode
                onChange={onToggle}  // Toggle function changes theme mode
                style={{ background: '#00A9A8', transform: 'scale(1.3)', ...props.style }}
            />
        </Tooltip>
    );
};

export default ThemeToggleSwitch;

import React, { createContext, useContext, useState, useEffect } from 'react';

const ScreenContext = createContext(undefined);

// Simple debounce function
// to avoid calling the resize event too often
function debounce(fn, ms) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}

export const ScreenProvider = ({ children }) => {
    const [screenSize, setScreenSize] = useState({
        width: window.visualViewport ? window.visualViewport.width : window.innerWidth,
        height: window.visualViewport ? window.visualViewport.height : window.innerHeight,
        isTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0
    });

    useEffect(() => {

        const handleResize = () => {
            setScreenSize({
                width: window.visualViewport ? window.visualViewport.width : window.innerWidth,
                height: window.visualViewport ? window.visualViewport.height : window.innerHeight,
                isTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0
            });
        };

        const debouncedHandleResize = debounce(handleResize, 100);

        const timeoutId = setTimeout(handleResize, 500);

        // Listen to visualViewport changes if available
        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', debouncedHandleResize);
            window.visualViewport.addEventListener('orientationchange', debouncedHandleResize);
        } else {
            window.addEventListener('resize', debouncedHandleResize);
            window.addEventListener('orientationchange', debouncedHandleResize);
        }


        // // Add both resize and orientationchange listeners
        // window.addEventListener('resize', debouncedHandleResize);
        // window.addEventListener('orientationchange', debouncedHandleResize);

        return () => {
            clearTimeout(timeoutId);
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', debouncedHandleResize);
                window.visualViewport.removeEventListener('orientationchange', debouncedHandleResize);
            } else {
                window.removeEventListener('resize', debouncedHandleResize);
                window.removeEventListener('orientationchange', debouncedHandleResize);
            }
        };

    }, []);

    return (
        <ScreenContext.Provider value={screenSize}>
            {children}
        </ScreenContext.Provider>
    );
};

export const useScreen = () => {
    const context = useContext(ScreenContext);
    if (context === undefined) {
        throw new Error('useScreen must be used within a ScreenProvider');
    }
    return context;
};

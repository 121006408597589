import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { FirebaseContext } from '../Firebase';

const useFirebaseToken = () => {
    const firebase = useContext(FirebaseContext);
    const [firebaseToken, setFirebaseToken] = useState(null);
    const intervalRef = useRef(null);  // Use a ref to persist the interval ID

    const auth = firebase.auth;


    const refreshToken = useCallback(async () => {
        if (auth.currentUser) {
            try {
                const newToken = await auth.currentUser.getIdToken(true);
                setFirebaseToken(newToken);
                return newToken;
            } catch (error) {
                console.error("Failed to refresh token:", error);
                // setFirebaseToken(null);
                // Optionally, handle user feedback or re-authentication logic here
            }
        } else {
            setFirebaseToken(null);
            return Promise.resolve(null);
        }
    }, [auth]);



    useEffect(() => {
        const setupTokenRefreshInterval = () => {
            if (firebase.auth.currentUser && !intervalRef.current) {
                refreshToken();  // Refresh immediately when user is detected
                intervalRef.current = setInterval(refreshToken, 3500 * 1000);
            }
        };

        setupTokenRefreshInterval();  // Setup or reset the interval

        const unsubscribe = firebase.auth.onAuthStateChanged(user => {
            if (user) {
                setupTokenRefreshInterval();  // Reset interval on user change
            } else {
                intervalRef.current = null;
                setFirebaseToken(null);  // Clear token on sign-out
            }
        });

        return () => {
            clearInterval(intervalRef.current);  // Make sure to clear interval when the component unmounts
            unsubscribe();
        };
    }, [refreshToken, firebase.auth]);


    return [firebaseToken, refreshToken];

};

export default useFirebaseToken;
import { createContext } from 'react';
import { db, auth } from '.';
import { connectAuthEmulator } from 'firebase/auth';

// the following can combine with useContext hook to consume FirebaseProvider
export const FirebaseContext = createContext(null);

// the following can be used for class components to consume FirebaseProvider
export const withFirebase = (Component) => (props) => (
    <FirebaseContext.Consumer>
        {(firebase) => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
);

const FirebaseProvider = ({ children }) => {

    // // Use emulators and logging for development only
    // if (process.env.NODE_ENV === 'development') {
    //     connectAuthEmulator(auth, 'http://localhost:9099');  // Correct function for emulator
    //     console.log('Connected to Firebase Auth Emulator.');
    //     // Optionally disable app verification for testing
    //     // auth.settings.appVerificationDisabledForTesting = true;
    // }


    const value = {
        db,
        auth
    };
    return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};


export default FirebaseProvider;



// import React from 'react';
// import { FloatButton } from 'antd';

// import { MenuOutlined, BulbOutlined } from '@ant-design/icons';
// import ThemeToggleSwitch from './themeToggle';

// const FloatMenu = (props) => {




//     return <FloatButton.Group
//         trigger="click"
//         type="primary"
//         style={{ right: getRightDistance(props.screenWidth), bottom: props.top, transform: 'rotate(180deg)' }}

//         // transform: 'rotate(180deg)'
//         icon={<MenuOutlined />}
//     >
//         <FloatButton
//             type={props.tourOpen ? "default" : "primary"}
//             icon={<BulbOutlined />}
//             onClick={() => props.setTourOpen(true)}
//             tooltip={"Show Tips"}
//         // style={{ right: getRightDistance(props.screenWidth), top: props.top }}
//         />

//         {/* <FloatButton icon={<ThemeToggleSwitch />} /> */}
//         <ThemeToggleSwitch />
//         {/* </FloatButton> */}
//     </FloatButton.Group>

// }

// export default FloatMenu;


import React, { useState } from 'react';
import { FloatButton, Flex } from 'antd';
import { MenuOutlined, CloseOutlined, BulbOutlined } from '@ant-design/icons';
import ThemeToggleSwitch from './themeToggle';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};


const CustomFloatMenu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    // Function to determine the right margin based on the window width
    function getRightDistance() {
        // Example: if the screen width is greater than 1200px, set right distance to 200px,
        // otherwise set it to 10% of the screen width
        if (props.screenWidth < 400) {
            return props.screenWidth * 0.05; // Fixed pixels for very wide screens
        } else {
            return props.screenWidth * 0.15; // 10% of the screen width for others
        }
    }


    const handleToggleTheme = () => {
        const timer = setTimeout(() => {
            setMenuOpen(false);
        }, 800);

        return () => clearTimeout(timer);  // Clear the timeout if the component unmounts or the function is called again
    };

    const handleTourClick = () => {
        props.setTourOpen(true); // Immediately open the tour

        const timer = setTimeout(() => {
            setMenuOpen(false); // Close the menu after a delay
        }, 800);

        return () => clearTimeout(timer); // Cleanup the timeout
    };

    const handleMenuClick = () => {

        hapticsImpactLight();
        setMenuOpen(!menuOpen);

        // auto close the menu after 5 seconds
        const timer = setTimeout(() => {
            setMenuOpen(false); // Close the menu after a delay
        }, 5000);

        return () => clearTimeout(timer); // Cleanup the timeout
    };


    return (
        // <div style={{
        //     position: 'fixed',
        //     right: getRightDistance(),
        //     top: props.top,
        //     zIndex: 1000  // Ensure it's on top of other content
        // }}>
        <Flex
            vertical
            align="flex-end"
            style={{
                position: 'fixed',
                //     right: getRightDistance(),
                //     top: props.top,
                zIndex: 1000  // Ensure it's on top of other content
            }}

        >
            <FloatButton
                icon={menuOpen ? <CloseOutlined /> : <MenuOutlined />}
                // onClick={() => setMenuOpen(!menuOpen)}
                onClick={handleMenuClick}
                type="primary"
                style={{
                    right: getRightDistance(props.screenWidth),
                    top: props.top
                }
                }
            />
            {
                menuOpen && (
                    <>
                        <FloatButton
                            icon={<BulbOutlined />}
                            onClick={handleTourClick}
                            tooltip="Show Tips"
                            type={props.tourOpen ? "default" : "primary"}
                            style={{
                                right: getRightDistance(props.screenWidth),
                                top: props.top + 50
                            }}
                        />

                        <ThemeToggleSwitch
                            handleToggleTheme={handleToggleTheme}
                            style={{
                                // width: 100,
                                right: getRightDistance(props.screenWidth),
                                top: props.top + 104,
                                position: 'fixed',
                            }}
                        />

                    </>
                )
            }

        </Flex >
        // </div>
    );
};

export default CustomFloatMenu;

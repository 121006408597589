import React, { createContext, useContext, useEffect, useState } from 'react';
import { Network } from '@capacitor/network';

const NetworkContext = createContext();

export const NetworkProvider = ({ children }) => {
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const checkInitialNetworkStatus = async () => {
            const status = await Network.getStatus();
            setIsOnline(status.connected);
        };

        const networkStatusChangeListener = Network.addListener('networkStatusChange', status => {
            setIsOnline(status.connected);
        });

        checkInitialNetworkStatus();

        return () => {
            networkStatusChangeListener.remove();
        };
    }, []);

    return (
        <NetworkContext.Provider value={{ isOnline }}>
            {children}
        </NetworkContext.Provider>
    );
};

export const useNetwork = () => useContext(NetworkContext);

import React, { useCallback } from 'react';
import { LayoutWithNav } from '../Navigation';

import { useAuthorization, withEmailVerification } from '../Contexts';

import Page from './page';


// function App(props) {
const App = (props) => {

    const authCondition = useCallback(authUser => !!authUser, []);
    useAuthorization(authCondition);


    return (

        <LayoutWithNav>
            <Page {...props} />

        </LayoutWithNav>

    )
}

// export default App;
export default withEmailVerification(App);

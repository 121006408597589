import React, { useEffect } from 'react';
import {
    Result
} from 'antd';
import { useNavigate } from 'react-router-dom';

import * as ROUTES from "../../constants/routes";
import withPage from '../Results';

const Res = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Set up the timer
        const timer = setTimeout(() => {
            navigate(ROUTES.HOME); // Redirect to the home route after 3 seconds
        }, 3000);

        // Clean up the timer when the component unmounts
        return () => {
            clearTimeout(timer);
        };
    }, [navigate]);

    return <Result
        status="success"
        title="Thank you for subscribing"
        subTitle="Redirecting you in a few seconds..."
        style={{ margin: "80px auto", maxWidth: 800 }}
    />


};

export default withPage(Res);

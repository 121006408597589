import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import './App.css';
import { Capacitor } from '@capacitor/core';

import { AuthUserProvider, ScreenProvider, DateContextProvider, ThemeProvider, NetworkProvider, useNetwork } from './components/Contexts';
import { FirebaseProvider } from './components/Firebase';

import Landing from "./components/Landing";
import Chat from "./components/Chat";
import Donut from "./components/DayDonut";
import Arc from "./components/SpanArc";
import AuthHandler from "./components/AuthHandler";
import Account from "./components/Account";
import { SuccessPage, CancelPage, Checkout } from "./components/CheckOut";
import Membership from "./components/Account/membership";
// import { About, Terms, Privacy, Disclaimer, Faq } from "./components/Docs";
import DocMD from "./components/Docs";
import Help from './components/Help';

// import Notice from '../Notice';
// import Help from '../Help';
import { Page403, Page404 } from "./components/Results";
// import AuthHandler from "../AuthHandler";

import * as ROUTES from "./constants/routes";
// import { TooltipContext, LanguageContext } from './components/Contexts';
import { useScrollToTop } from './components/Hooks';
import { App as CapacitorApp } from '@capacitor/app';
import { message } from 'antd';


// Adjust viewport settings for iOS to prevent unwanted auto-zoom
// see https://weblog.west-wind.com/posts/2023/Apr/17/Preventing-iOS-Safari-Textbox-Zooming for inspiration

// const adjustViewportForIOS = () => {
//   const platform = Capacitor.getPlatform();
//   if (platform === 'ios') {
//     const viewport = document.querySelector("[name=viewport]");
//     if (viewport) {
//       viewport.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=yes");
//     }
//   }
// };

// adjustViewportForIOS();

const Inner = () => {

  useScrollToTop(); // Scroll to top on route change
  const navigateTo = useNavigate();

  const { isOnline } = useNetwork();
  const [messageApi, contextHolder] = message.useMessage();
  const [lastStatus, setLastStatus] = useState(true); // Track the last network status

  useEffect(() => {
    // Only display a message if the network status has actually changed
    if (lastStatus !== isOnline) {
      if (isOnline) {
        messageApi.open({
          type: 'success',
          content: 'You are back online.',
          duration: 2,
          style: {
            marginTop: '10vh',
          }
        });

      } else {
        messageApi.open({
          type: 'warning',
          content: 'You are offline. Some features may not be available.',
          duration: 2,
          style: {
            marginTop: '10vh',
          }
        });
      }
      setLastStatus(isOnline); // Update the last known status
    }
    // We deliberately do not include lastStatus in the dependencies here
    // to avoid re-running this effect from its own state update
  }, [isOnline, messageApi]); // Include messageApi to ensure the message is handled by the current instance


  useEffect(() => {

    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener('appUrlOpen', (event) => {
        // console.log('App opened with URL:', event.url);

        // Parse the URL to get the pathname
        const url = new URL(event.url);
        const fullUrlPath = `${url.pathname}${url.search}${url.hash}`; // Includes pathname, query string, and hash fragment
        navigateTo(fullUrlPath);

        // console.log('fullUrlPath is', fullUrlPath);

        // Navigate directly to the pathname
        navigateTo(fullUrlPath);

        // Optionally, you can log or handle query parameters here
        // console.log('Query parameters:', url.searchParams);

        // const url = event.url;
        // // let pattern = /tellti\.me(.*)/i;
        // let pattern = /tellti\.me\/auth\/(.*)$/;
        // const slug = url.match(pattern);
        // if (slug && slug[1]) {
        //   navigateTo('/auth/' + slug[1]);
        // }
        // // If no match, do nothing - let regular routing
        // // logic take over
        // // theory is that the regular logic would generally redirect to the default page for url not matching any endpoint
        // // in app, redirects always an url that's not legit since it mixes app url with regular url
      });

      // Cleanup listener when component unmounts
      return () => {
        CapacitorApp.removeAllListeners('appUrlOpen');
      };
    }

  }, [navigateTo]);


  // const { authUser } = useContext(AuthUserContext);

  // const {
  //   loading,
  //   // tooltip,
  //   // language,
  //   // setTooltip,
  //   // setLanguage,
  // } = useSettings(authUser); // Use the custom hook



  // if (loading) {
  //   return <Spin style={{ marginTop: 200, display: 'flex', justifyContent: 'center' }} size="large" />
  // }

  return (
    // <TooltipContext.Provider value={{ tooltip, setTooltip }}>
    //   <LanguageContext.Provider value={{ language, setLanguage }}>
    <div style={{ margin: "auto", maxHeight: "none" }}>
      {contextHolder}
      <Routes>
        <Route path={ROUTES.CHAT} element={<Chat />} />
        <Route path={ROUTES.HOME} element={<Chat />} />
        <Route path={ROUTES.LANDING} element={<Landing />} />
        <Route path={ROUTES.DAY} element={<Donut />} />
        <Route path={ROUTES.SPAN} element={<Arc />} />
        <Route exact path={ROUTES.PAGE403} element={<Page403 />} />
        <Route exact path={ROUTES.PAGE404} element={<Page404 />} />
        <Route exact path={ROUTES.PAYMENTSUCCESS} element={<SuccessPage />} />
        {/* <Route exact path={ROUTES.ERROR} element={ErrorPage} />*/}
        <Route exact path={ROUTES.PAYMENTCANCELLED} element={<CancelPage />} />
        <Route path={ROUTES.AUTHHANDLER} element={<AuthHandler />} />
        <Route path={ROUTES.ACCOUNT} element={<Account />} />
        <Route path={ROUTES.CHECKOUT} element={<Checkout />} />

        <Route exact path={ROUTES.TERMS} element={<DocMD path='terms' />} />
        <Route exact path={ROUTES.PRIVACY} element={<DocMD path='privacy' />} />
        {/* <Route exact path={ROUTES.DISCLAIMER} element={<DocMD path='disclaimer' />} /> */}
        <Route exact path={ROUTES.ABOUT} element={<DocMD path='about' />} />
        <Route exact path={ROUTES.HELP} element={<Help />} />
        <Route exact path={ROUTES.MEMBERSHIP} element={<Membership />} />

        {/* Redirect to landing page if route not found */}
        <Route path="*" element={<Navigate to={ROUTES.LANDING} replace />} />
      </Routes>
    </div>
    //   </LanguageContext.Provider>
    // </TooltipContext.Provider>
  );
};

const App = () => {

  return (
    <Router>
      {/* <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00a9a8',
            // fontSize: 16,
            // lineHeight: '24px',  // lineHeight is paird with fontSize in antd, see https://ant.design/docs/spec/font
          },
          algorithm: theme.darkAlgorithm,
        }}
      > */}
      <ThemeProvider>
        <NetworkProvider>
          <FirebaseProvider>
            <AuthUserProvider>
              <ScreenProvider>
                <DateContextProvider>
                  <Inner />
                </DateContextProvider>
              </ScreenProvider>
            </AuthUserProvider>
          </FirebaseProvider>
        </NetworkProvider>
      </ThemeProvider>
      {/* </ConfigProvider> */}
    </Router >)
};



export default App;



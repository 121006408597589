import React, { useEffect, useState, useContext } from 'react';
import { loadStripe } from "@stripe/stripe-js";
// import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { FirebaseContext } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import {
    Button
} from 'antd';

import { createCheckoutSession } from '../../functions';
import { set } from 'firebase/database';



const stripe_pub_key = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;


const stripePromise = loadStripe(stripe_pub_key);




// note this checkout button by default is not embedded, but does a redirect
const CheckoutButton = ({ level, email, buttonText = 'Checkout', isEmbedded = false, promptLogin, ...restProps }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [messageIndex, setMessageIndex] = useState(0);

    const firebase = useContext(FirebaseContext);
    const user = firebase.auth.currentUser;

    const navigate = useNavigate();

    const messages = [buttonText, "Hold on...", "Just a moment...", "Finishing up..."];

    useEffect(() => {
        if (!isEmbedded) {
            // Pre-load the stripe API only for redirect scenarios
            stripePromise.catch(error => {
                console.error('Failed to load Stripe:', error);
                setError('Failed to initialize payment service. Please try again later.');
            });
        }
    }, [isEmbedded]);

    // useEffect(() => {
    //     let interval;

    //     if (loading && messageIndex < messages.length - 1) {
    //         // Setup the interval only if loading and there are more messages to show
    //         interval = setInterval(() => {
    //             setMessageIndex(prevIndex => {
    //                 if (prevIndex < messages.length - 1) {
    //                     return prevIndex + 1;
    //                 } else {
    //                     clearInterval(interval); // Clear the interval when no more messages are left to show
    //                     return prevIndex; // Return current index to avoid further changes
    //                 }
    //             });
    //         }, 1000);  // Update the message every 1.5 seconds
    //     }

    //     // Cleanup function to clear the interval
    //     return () => {
    //         if (interval) {
    //             clearInterval(interval);  // Clear the interval if it was set
    //         }
    //     };
    // }, [loading]);  // Include messageIndex and messages.length to properly track changes

    useEffect(() => {

        let interval;
        // Only setup interval if loading is true
        if (loading) {
            interval = setInterval(() => {
                setMessageIndex(prevIndex => {
                    // Check if the next index would exceed the bounds of the messages array
                    if (prevIndex >= messages.length - 1) {
                        clearInterval(interval); // Clear interval if it's the last message
                        return prevIndex; // Keep the index at the last message
                    } else {
                        return prevIndex + 1; // Increment the message index
                    }
                });
            }, 2000);
        }
        else {
            // Reset messageIndex to zero when loading is false
            setMessageIndex(0);
        }

        // Cleanup function to clear the interval
        return () => {
            if (interval) {
                clearInterval(interval);
                setMessageIndex(0); // Reset the message index when the interval is cleared
            }
        };

    }, [loading]); // Only re-run the effect when `loading` changes



    const handleCheckout = async () => {

        if (!level) {
            // setError('Please provide an email address and select a subscription level.');
            return;
        }

        if (!user) {
            promptLogin();
            return;
        }

        setLoading(true);
        setError('');
        setMessageIndex(0);  // Reset the message index on new checkout attempt

        try {

            const stripe = await stripePromise;
            // const info = await Device.getInfo();
            // const platform = info?.platform || ''; 
            const platform = Capacitor.getPlatform() || ''; // returns 'ios', 'android', or 'web'
            // console.log('platform is', platform);
            const firebaseToken = await user.getIdToken();

            const response = await createCheckoutSession(email, level, platform, firebaseToken, true);
            if (response.status === 200) {
                const data = response.data;


                if (isEmbedded) {
                    navigate(ROUTES.CHECKOUT, { state: { clientSecret: data.clientSecret } });
                } else {
                    const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
                    if (result.error) {
                        console.error('Stripe checkout error:', result.error.message);
                        setError(result.error.message);
                    }
                }


                // const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
                // if (result.error) {
                //     console.error('Stripe checkout error:', result.error.message);
                //     setError(result.error.message);
                // }
            } else {
                const data = response.data;
                console.error('Failed to start checkout:', data.error);
                setError(data.error);
            }
        } catch (error) {
            console.error('Error during checkout:', error);
            setError('An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }



    };

    return (
        <>
            <Button  {...restProps} onClick={handleCheckout} loading={loading} disabled={loading || !level}>
                {loading ? messages[messageIndex] : buttonText}
            </Button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {/* {<p style={{ color: '#00a9a8' }}>{loading ? messages[messageIndex] : ' '}</p>} */}
        </>
    );
};

export default CheckoutButton;


import React, { useState, useContext } from 'react';
import { Card, Radio, Typography, Flex } from 'antd';
import { siteName } from '../../constants';
import Texty from 'rc-texty';

import CheckoutButton from '../CheckOut/checkout_button';

import { AuthUserContext } from '../Contexts';

const { Text, Title } = Typography;

const PricingTable = (props) => {
    const [plan, setPlan] = useState('yearly');

    const { authUser } = useContext(AuthUserContext);
    const [showNewPrice, setShowNewPrice] = useState(true);



    const subscriptions = {
        monthly: {
            id: "price_monthly",
            name: "Monthly",
            price: 8.99,
            oldPrice: 10.99,
            period: "per month",
            displayName: `${siteName} monthly`,
            // features: [
            //     "Access to premium features",
            //     "Priority customer support",
            //     "Additional account benefits"
            // ]
        },
        yearly: {
            id: "price_yearly",
            name: "Yearly",
            price: 29.99,
            oldPrice: 59.99,
            period: "per year",
            displayName: `${siteName} annual`,
            // features: [
            //     "Access to premium features",
            //     "Priority customer support",
            //     "2 months free!",
            //     "Additional account benefits"
            // ]
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>


            <Card
                bordered={false}
                style={{ width: 240, textAlign: 'center', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
            >
                <Radio.Group
                    onChange={(e) => setPlan(e.target.value)}
                    value={plan}
                    buttonStyle="solid"
                    style={{ marginBottom: 16, width: '100%' }}
                >
                    {Object.entries(subscriptions).map(([key, value]) => (
                        <Radio.Button value={key} key={key} style={{ textAlign: 'center', width: "50%" }}>
                            {value.name}
                        </Radio.Button>
                    ))}
                </Radio.Group>
                <Title level={4} style={{ marginBottom: 0 }}>
                    {subscriptions[plan].displayName}
                </Title>

                <Flex align="middle" justify="center" gap="small">



                    <Title delete level={2}>
                        {`$ ${subscriptions[plan].oldPrice}`}
                    </Title>
                    <Flex vertical align="flex-start" justify="center">
                        <Text type="secondary" style={{ fontSize: 14, paddingTop: 12, marginBottom: -6 }}>

                        </Text>
                        <Text type="secondary" style={{ fontSize: 14 }}>

                        </Text>
                    </Flex>
                </Flex>

                <Flex align="middle" justify="center" gap="small" style={{ marginTop: -44 }}>

                    <Flex vertical align="flex-start" justify="space-evenly">
                        <div> </div>
                        <Text type="secondary" style={{ fontSize: 14, }}>US</Text>
                    </Flex>


                    <Title level={2} >
                        {`$ ${subscriptions[plan].price}`}
                    </Title>
                    <Flex vertical align="flex-start" justify="center">
                        <Text type="secondary" style={{ fontSize: 14, paddingTop: 12, marginBottom: -6 }}>
                            {subscriptions[plan].period.split(' ')[0].toLowerCase()}
                        </Text>
                        <Text type="secondary" style={{ fontSize: 14 }}>
                            {subscriptions[plan].period.split(' ')[1].toLowerCase()}
                        </Text>
                    </Flex>
                </Flex>

                {/* <span style={{ fontSize: '14px', color: 'grey', display: 'block' }}>
                    {subscriptions[plan].period}
                </span> */}
                {/* <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left', margin: '0 20px 20px' }}>
                    {subscriptions[plan].features.map((feature, index) => (
                        <li key={index} style={{ marginBottom: '10px' }}>{feature}</li>
                    ))}
                </ul> */}

                <div style={{ marginTop: 10 }}>
                    <CheckoutButton
                        type="primary"
                        block
                        email={authUser?.email}
                        level={plan}
                        promptLogin={props.promptLogin}
                        disabled={!authUser}
                    >
                        Subscribe
                    </CheckoutButton >
                </div>

                <div style={{ marginTop: 10, fontSize: 10, }} >
                    <span>* Prices are in USD. Other currencies are also accepted.</span>
                </div>
            </Card >

        </div >
    );
};

export default PricingTable;

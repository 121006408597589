import React, { Component } from 'react';

import {
    Typography, Card
} from 'antd';

import Contact from './contact';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const { Meta } = Card;

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            visible: true,
            dbLoading: false,
            pushkey: null,
            data: {},
            adding: false,
        };

        this.maxShortnameLength = 18;

        this.maxNameLength = 100;


    }

    pendingPromises = [];



    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);




    render() {


        return (<div
            style={{
                maxWidth: 1200,
                margin: "auto",
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 40,
            }}
        >

            <Card style={{ width: "100%", marginTop: 20, marginBottom: 60 }}>
                <Meta
                    title={<Title level={2} ellipsis={true}>Got questions?</Title>}
                />

                <h4>For tips on how to use our site, press the floating button on the top right of any page for a tour. You can also see some examples on the <Link to='/'>welcome page</Link>.</h4>
                <h4>For questions regarding our service, refer to our <Link to='/terms'>Terms of Service</Link> or <Link to='/privacy'>Privacy Notice</Link> linked at the bottom of this page.</h4>

            </Card>


            <Card style={{ width: "100%", marginTop: 20, marginBottom: 60 }}>
                <Meta
                    title={<Title level={2} ellipsis={true}>Still got questions?</Title>}
                    description={`To send us a message, press the following button.`}
                />

                <Contact {...this.props} />

            </Card>




        </div>

        );
    }
}




export default App;


import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthUserContext from './createdContexts';
import { Button, Spin, Space, Layout, Flex, Image } from 'antd';
import { signOut, reload } from "firebase/auth";
import { FirebaseContext } from '../Firebase';
import useEmailVerification from './useEmailVerification';
import { siteName } from '../../constants';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../constants/routes";
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};

const isNative = Capacitor.isNativePlatform();

const headerHeight = isNative ? 120 : 80;
const headerPadding = isNative ? "40px 20px" : "0 20px";

const { Header, Content, Footer } = Layout;

const EmailVerification = ({ authUser, setAuthUser, onSendEmailVerification, contextHolder }) => {

    const firebase = useContext(FirebaseContext);
    const [countdown, setCountdown] = useState(0);
    const navigate = useNavigate(); // Use useNavigate hook for navigation

    const onClickLogo = () => {
        hapticsImpactLight();
        navigate(ROUTES.LANDING);
    }

    useEffect(() => {
        // // start the timer only when the countdown state is updated
        // if (countdown <= 0) return;
        // On mount, check if a timestamp is saved in localStorage
        const timestamp = localStorage.getItem('timestamp');
        if (timestamp) {
            const timeElapsed = Date.now() - timestamp;
            const remainingTime = 60 - Math.floor(timeElapsed / 1000);
            if (remainingTime > 0) {
                // If remaining time is greater than 0, start countdown
                setCountdown(remainingTime);
            }
        }

        // Countdown timer
        const timerId = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    // If countdown is complete, clear timestamp from localStorage
                    localStorage.removeItem('timestamp');
                    clearInterval(timerId);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timerId); // Cleanup timer on unmount
    }, [countdown]);


    const handleSendEmailVerification = () => {
        onSendEmailVerification()
            .then(() => {
                // Email successfully sent
                localStorage.setItem('timestamp', Date.now());
                setCountdown(60);
            })
            .catch(() => {
                // Email not sent
                localStorage.setItem('timestamp', Date.now());
                setCountdown(5);
            });
    };


    const handleRefreshUser = useCallback(() => {

        if (firebase.auth.currentUser) {

            reload(firebase.auth.currentUser)
                .then(() => { setAuthUser({ ...firebase.auth.currentUser }); })
                .catch(error => console.error("Error refreshing user:", error.message));

            // firebase.auth.currentUser.reload()
            //     .then(() => { setAuthUser({ ...firebase.auth.currentUser }); })
            //     .catch(error => {
            //         console.error("Error refreshing user:", error);
            //     });

        }
    }, [firebase.auth.currentUser, setAuthUser]);


    useEffect(() => {
        // Refresh user on mount, since sometimes after email verification, the user is not updated
        handleRefreshUser();

    }, [handleRefreshUser]);



    if (!authUser) {
        return null; // or you can handle this scenario appropriately
    }



    return (
        <Layout style={{ height: "100vh" }}>
            <Header style={{
                height: headerHeight,
                padding: headerPadding,
            }}>

                <Flex justify='space-around' gap="large" align='end'>
                    <Image
                        height={60}
                        src="/images/logo_text.svg"
                        alt={siteName}
                        preview={false}
                        fallback={siteName}
                        style={{ display: "flex", justifyContent: 'center', marginTop: "10px", minWidth: "60px" }}
                        onClick={onClickLogo}
                    />



                </Flex>


            </Header>
            <Content>
                <div style={{ padding: 24, margin: "100px auto", maxWidth: 500 }}>
                    {contextHolder}
                    <h1>Verify Your Email Address</h1>
                    <p>
                        Check your email folder at <b>{authUser.email}</b> (spam folder included) for a confirmation email.
                        If you can't find it, or the link in it no longer works, click the button to resend.
                    </p>


                    <Flex justify='start' align='center'>
                        <Button
                            type="link"
                            onClick={handleRefreshUser}
                        // style={{ width: '100%' }}
                        >
                            Already verified your email?
                        </Button>


                        <Button
                            type="primary"
                            onClick={handleRefreshUser}

                        >
                            Refresh page
                        </Button>

                    </Flex>


                    <Space style={{ margin: "20px 0", width: "100%", display: "flex", justifyContent: "space-between" }}>



                        <Button
                            onClick={handleSendEmailVerification}
                            disabled={countdown > 0}
                            style={{ width: '100%' }}
                        >
                            {countdown > 0 ? `Resend in ${countdown}s` : 'Resend confirmation email'}
                        </Button>

                        <Button
                            onClick={() => signOut(firebase.auth)}
                            style={{ width: '100%' }}
                        >
                            Sign Out
                        </Button>

                    </Space>
                </div>
            </Content>
        </Layout>
    );
};



export const withEmailVerification = (WrappedComponent) => {
    return (props) => {
        const { authUser, isLoading, setAuthUser } = useContext(AuthUserContext);

        const {
            onSendEmailVerification,
            needsEmailVerification,
            contextHolder,
        } = useEmailVerification();

        if (isLoading) {
            // return <Row type="flex" justify="center">
            //     <Spin style={{ marginTop: 200 }} size="large" />
            // </Row>
            return <Spin style={{ marginTop: 200, display: 'flex', justifyContent: 'center' }} size="large" />;
        } else if (needsEmailVerification(authUser)) {
            return <EmailVerification
                authUser={authUser}
                setAuthUser={setAuthUser}
                // isSent={isSent}
                onSendEmailVerification={onSendEmailVerification}
                contextHolder={contextHolder}
            />;
        }

        return <WrappedComponent {...props} />;
    }
};


import React from 'react';
import {
    Result, Button
} from 'antd';

import * as ROUTES from "../../constants/routes";
import { useNavigate } from 'react-router-dom';

const Res = (props) => {

    const navigate = useNavigate();

    return <Result
        // status="403"
        // icon={<Image
        //     height={400}
        //     src="403.svg"
        //     alt="403"
        //     preview={false}
        //     fallback="Login Required"
        //     style={{ padding: "20px 6px 0px 2px", marginLeft: 12, marginRight: 12, marginTop: 80 }}
        // // style={{ margin: "auto", verticalAlign: "middle", display: "inline-block", height: "100%" }}
        // />}
        title="Login required"
        subTitle="To have access to this page, please sign in."
        extra={<Button key="landing" type="primary" onClick={() => { navigate(ROUTES.LANDING) }}>Go Back</Button>}
    />
};

export default Res;



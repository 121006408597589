import React, { useState, useEffect, useCallback, useRef } from 'react';
import DonutChart from './donut-day';
import { FirebaseContext } from '../Firebase';
import { useContext } from 'react';
import moment from 'moment-timezone';

import { Button, Space, Flex, Layout, Alert } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, MenuOutlined, BulbOutlined } from '@ant-design/icons';


import dayjs from 'dayjs';

import { DatePickerWithDots } from '../SpanArc';
import { LayoutWithNav } from '../Navigation';

import { useAuthorization, withEmailVerification, AuthUserContext, useScreen, DateContext, useTheme, useNetwork } from '../Contexts';

import { fetchDataProcess, processRecordsForGraph } from './utils';
import { CustomTour } from './customTour';
import { ref, set, update } from 'firebase/database';
import { siteName } from '../../constants';
import FloatMenu from '../Chat/floatMenu';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};


const { Content, Footer } = Layout;

const DonutChartComponent = () => {

    // console.log('donut page loaded');

    const authCondition = useCallback(authUser => !!authUser, []);
    useAuthorization(authCondition);

    const { isOnline } = useNetwork();

    const { date, setDate } = useContext(DateContext);
    const { isDarkMode } = useTheme();

    const graphText = moment(date).format('MMM D') ?? moment().format('MMM D');
    const dateText = date ?? moment().format('YYYY-MM-DD');

    const targetComponentRef = useRef(null);

    const { width: screenWidth, height: screenHeight, isTouch } = useScreen();

    const [graphData, setGraphData] = useState([]);
    // const [graphText, setGraphText] = useState(moment(date).format('MMM D') ?? moment().format('MMM D'));
    // const [dateText, setDateText] = useState(date ?? moment().format('YYYY-MM-DD'));
    const [tourOpen, setTourOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    const firebase = useContext(FirebaseContext);

    const { authUser, setAuthUser } = useContext(AuthUserContext);



    const user = firebase.auth.currentUser;
    const uid = user?.uid;
    const { db } = firebase;

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const onCloseTour = useCallback(() => {
        setTourOpen(false);
    }, []);


    useEffect(() => {

        if (authUser && authUser.settings?.donut_tour_seen == null) {
            setShowAlert(true);
        }

    }, [authUser]);



    const onFinishTour = useCallback(() => {
        if (authUser && !authUser.settings?.donut_tour_seen) {
            setAuthUser({ ...authUser, settings: { ...authUser.settings, donut_tour_seen: true } });
            const userRef = ref(db, `u_settings/${uid}`);
            update(userRef, { donut_tour_seen: true }).catch((error) => { console.error(error) });
        }
    }, [authUser, setAuthUser, db, uid]);


    const onChange = (date, dateString) => {
        hapticsImpactLight();
        if (dateString) {
            // setGraphText(moment(dateString).format('MMM D'));
            // setDateText(dateString);
            setDate(dateString);
        }
    };

    const onClickDayChange = (direction) => {
        hapticsImpactLight();
        const newDay = direction === 'back' ? moment(dateText).subtract(1, 'day') : moment(dateText).add(1, 'day');
        // setDateText(newDay.format('YYYY-MM-DD'));
        // setGraphText(newDay.format('MMM D'));
        setDate(newDay.format('YYYY-MM-DD'));
    };


    // const usePrevious = (value, initialValue) => {
    //     const ref = useRef(initialValue);
    //     useEffect(() => {
    //         ref.current = value;
    //     });
    //     return ref.current;
    // };

    // const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
    //     const previousDeps = usePrevious(dependencies, []);

    //     const changedDeps = dependencies.reduce((accum, dependency, index) => {
    //         if (dependency !== previousDeps[index]) {
    //             const keyName = dependencyNames[index] || index;
    //             return {
    //                 ...accum,
    //                 [keyName]: {
    //                     before: previousDeps[index],
    //                     after: dependency
    //                 }
    //             };
    //         }

    //         return accum;
    //     }, {});

    //     if (Object.keys(changedDeps).length) {
    //         console.log('[use-effect-debugger] ', changedDeps);
    //     }

    //     useEffect(effectHook, dependencies);
    // };

    // //  useEffectDebugger(() => {

    useEffect(() => {


        uid && db && dateText && userTimezone &&
            fetchDataProcess(uid, dateText, userTimezone, db)
                .then((records) => {
                    records && setGraphData(processRecordsForGraph(Object.values(records), dateText, userTimezone));
                });



    }, [dateText, uid, userTimezone, db]);



    const headerPlusFooterHeight = 200;


    return (
        <LayoutWithNav>

            <Layout style={{ flexGrow: 1 }}>
                <Content>

                    {/* {graphData?.length > 0 && <FloatButton
                        type={tourOpen ? "default" : "primary"}
                        icon={<BulbOutlined />}
                        onClick={() => setTourOpen(true)}
                        tooltip={"Show Tips"}
                        style={{ right: getRightDistance(screenWidth), top: screenWidth < 300 ? 60 : 30 }}
                    />} */}

                    <FloatMenu
                        top={50}
                        screenWidth={screenWidth}
                        screenHeight={screenHeight}
                        tourOpen={tourOpen}
                        setTourOpen={setTourOpen}
                    />

                    <div style={{ padding: screenWidth < 300 ? '12px 8px' : '12px' }}>
                        <div style={{ display: "inline-block", margin: "46px 0px 0px 20px" }} ref={targetComponentRef}>
                            <Space.Compact>
                                <Button onClick={() => onClickDayChange('back')} icon={<CaretLeftOutlined />}></Button>
                                <DatePickerWithDots onChange={onChange} value={dayjs(dateText)} style={{ maxWidth: 140 }} />
                                <Button onClick={() => onClickDayChange('forward')} icon={<CaretRightOutlined />}></Button>
                            </Space.Compact>
                        </div>

                        {!isOnline && <Alert
                            type="warning"
                            message="You are offline"
                            description="Some features don't work"
                            style={{ marginTop: '20px' }}
                            closable
                        />}


                        {showAlert && <Alert
                            message="How about some tips for using this page?"
                            description={<>Press <BulbOutlined /> from the <MenuOutlined /> menu to see them anytime.</>}
                            type="info"
                            action={
                                <Space direction="vertical">
                                    <Button size="small" type="primary" onClick={() => { setTourOpen(true); setShowAlert(false) }} style={{ width: 80 }}>
                                        Show Me
                                    </Button>
                                    <Button size="small" onClick={() => setShowAlert(false)} style={{ width: 80 }}>
                                        Not Now
                                    </Button>
                                </Space>
                            }
                            style={{ marginTop: '20px' }}
                            closable
                        />}

                        {/* style={{ height: `calc(100vh - ${headerPlusFooterHeight}px)` }} */}
                        <Flex align='center' justify='center' >
                            <DonutChart userData={graphData} dateText={graphText} isTouch={isTouch} isDarkMode={isDarkMode} />
                        </Flex>


                        <CustomTour
                            open={tourOpen}
                            onCloseTour={onCloseTour}
                            onFinishTour={onFinishTour}
                            screenWidth={screenWidth}
                            isTouch={isTouch}
                            ref1={targetComponentRef}
                        />


                    </div>

                </Content>
                <Footer style={{ textAlign: 'center', padding: '2px 14px 14px 14px', fontSize: "14px" }}>©{new Date().getFullYear()} {siteName} created by Spearmint Labs</Footer>
            </Layout>
        </LayoutWithNav >)
};


export default withEmailVerification(DonutChartComponent);



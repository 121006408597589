import React, { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../Firebase';

import { DatePicker } from 'antd';


import moment from 'moment-timezone';
import dayjs from 'dayjs';
import { fetchRecordsForDate } from './utils'; // Adapt the path as necessary

const App = (props) => {

    const firebase = useContext(FirebaseContext);

    const user = firebase.auth.currentUser;
    const { db } = firebase;
    const uid = user?.uid;


    const [specialDates, setSpecialDates] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(dayjs().format('YYYY-MM'));

    useEffect(() => {

        if (db && uid && currentMonth) {


            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const monthMoment = moment(currentMonth, 'YYYY-MM').tz(userTimezone);

            // console.log('compare where to place tz', moment(currentMonth, 'YYYY-MM').tz(userTimezone).unix(), moment.tz(currentMonth, 'YYYY-MM', userTimezone).unix(), 'turns out identical')
            // console.log('compare if converting to UTC is needed', monthMoment.clone().startOf('month').unix(), monthMoment.clone().startOf('month').tz('UTC').unix(), 'turns out identical')

            const startDate = monthMoment.clone().startOf('month').unix();
            const endDate = monthMoment.clone().endOf('month').unix();

            // console.log('fetch records for current month');
            // Fetch records for the current month
            fetchRecordsForDate(uid, startDate, endDate, db).then(records => {
                const newSpecialDates = [];
                for (const key in records) {
                    const record = records[key];
                    if (record.time) {
                        newSpecialDates.push(dayjs.unix(record.time));
                    }
                    if (record.end_time) {
                        newSpecialDates.push(dayjs.unix(record.end_time));
                    }
                }

                setSpecialDates(newSpecialDates);
            });
        }

    }, [currentMonth, db, uid]); // Refetch when the currentMonth changes

    const onPanelChange = (value, mode) => {
        // unix timestamp is universal and based on utc
        setCurrentMonth(dayjs(value).format('YYYY-MM')); // Update the current month based on user navigation
    };

    const onOpenChange = (open) => {
        if (open) {
            const pickerMonth = props.value ? dayjs(props.value).format('YYYY-MM') : dayjs().format('YYYY-MM');
            if (pickerMonth !== currentMonth) {
                setCurrentMonth(pickerMonth);
            }
        }
    };

    const cellRender = (current) => {
        // Check if the current date is a special date
        // console.log('specialDates', specialDates.map(date => date.format('YYYY-MM-DD')));
        const isSpecialDate = specialDates.some(specialDate => specialDate.isSame(current, 'day'));


        // Add a dot below the special dates
        return (
            <div className="ant-picker-cell-inner" style={{ position: 'relative' }}>
                {current.date()}
                {isSpecialDate && (
                    <span style={{
                        position: 'absolute',
                        bottom: -4,
                        left: '50%',
                        width: '4px',
                        height: '4px',
                        backgroundColor: '#ff7875',
                        borderRadius: '50%',
                        transform: 'translateX(-50%)'
                    }}></span>
                )}
            </div>
        );
    };


    return (

        <DatePicker
            cellRender={cellRender}
            onPanelChange={onPanelChange}
            onOpenChange={onOpenChange}
            {...props}
        />

    );
};

export default App;

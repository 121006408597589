import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Flex, Avatar } from 'antd';
import { PieChartOutlined, UserOutlined, RollbackOutlined } from '@ant-design/icons';
// import { useScreen } from '../Contexts';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
  await Haptics.impact({ style: ImpactStyle.Light });
};

const { Content, Footer } = Layout;


const LayoutWithNav = ({ children, footerHeight = 60 }) => {

  // const { height: screenHeight } = useScreen();

  return (


    <Layout
      style={{
        display: 'flex',
        flexDirection: 'column',
        // height: '100%', // Use 100% height for the flex container
        minHeight: '100vh', // minHeight here only to fill the viewport initially

      }}
    >
      <Content
        className='layoutwithnav-content'
        // style={{
        //   // minHeight: `calc(${screenHeight}px - ${footerHeight}px)`, // Ensures content area takes up remaining space
        //   display: "flex",
        //   flexDirection: "column",
        //   // padding: '24px', // Optional: adds padding inside the content area,
        // }}

        style={{
          flex: 1,  // Allows content to expand and fill available space
          display: "flex",
          flexDirection: "column",
          overflow: 'auto', // Allows content to scroll if needed
        }}

      >
        {children} {/* Render the page content */}
      </Content>

      <Footer
        style={{
          height: footerHeight,
          position: 'sticky',
          bottom: 0,
          //   zIndex: 1,
          // width: '100%',
          //   display: 'flex',
          // alignItems: 'center',
          padding: '10px 10px 20px 10px',

          //   justifyContent: 'space-around',
          borderTop: '1px solid #f0f0f0',
        }}
      >
        <Flex justify="space-around" gap="large" style={{ margin: "auto", maxWidth: 800 }}>
          <NavLink to="/home" style={{ color: 'inherit' }} onClick={hapticsImpactLight}><Avatar size={26} src={`${process.env.PUBLIC_URL}/images/logo_simple.svg`} /></NavLink>
          {/* <NavLink to="/span" style={{ color: 'inherit', fontSize: 24 }}><RollbackOutlined style={{ transform: 'rotate(180deg) scaleX(-1)' }} /></NavLink> */}
          <NavLink to="/span" style={{ color: 'inherit', fontSize: 24 }} onClick={hapticsImpactLight}><RollbackOutlined style={{ transform: 'rotate(180deg) scaleX(-1)' }} /></NavLink>
          <NavLink to="/day" style={{ color: 'inherit', fontSize: 24 }} onClick={hapticsImpactLight}><PieChartOutlined /></NavLink>
          <NavLink to="/account" style={{ color: 'inherit', fontSize: 24 }} onClick={hapticsImpactLight}><UserOutlined /></NavLink>
        </Flex>
      </Footer>
    </Layout >
  );
};

export default LayoutWithNav;



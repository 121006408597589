import React, { useEffect, useRef, useState, useCallback } from 'react';
// import DataSet from '@antv/data-set';
import G6 from '@antv/g6';
import { Input, Button, Space, Flex } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { formatDurationCompact } from '../Chat';
import { useResponsiveChart } from '../Hooks';
import { useTheme } from '../Contexts';
import { createCustomNode, createCustomEdge, createCustomBehavior } from './customG6';
import { Capacitor } from '@capacitor/core';


const colors = [
    'rgb(91, 143, 249)',
    'rgb(90, 216, 166)',
    'rgb(93, 112, 146)',
    'rgb(246, 189, 22)',
    'rgb(232, 104, 74)',
    'rgb(109, 200, 236)',
    'rgb(146, 112, 202)',
    'rgb(255, 157, 77)',
    'rgb(38, 154, 153)',
    'rgb(227, 137, 163)',
];

const platform = Capacitor.getPlatform();


const formatLongText = (text, length = 30, elipsis = '...') => {

    if (!text || text.length <= length) return text;
    return `${text.substr(0, Math.max(length - 3, 1))}${elipsis}`;
};



const minNodeSeparation = 30;
const maxNodeSeparation = 60;



const MyG6Chart = ({ userData, dateText, createEdge, removeEdge, updateEdgeData, updateGraphData, isTouch }) => {

    //if mode is click to create edge, then don't use graph.on for node click -- the reason is that the node click won't register until after the edge is created; this introduces hard to find bugs

    // const [graphModes, setGraphModes] = useState(['drag-canvas', 'zoom-canvas', 'click-select']);



    const { width: screenWidth, height: screenHeight } = useResponsiveChart(isTouch);
    const minGraphHeight = screenHeight - 240;
    // const chartWidth = chartRef.current.scrollWidth;


    const sqChartSize = Math.min(screenWidth, screenHeight, 1200);
    const chartWidth = Math.max(screenWidth - 20, sqChartSize * 2);

    const innerWidth = screenWidth - 40;
    const nodeWidth = Math.floor(Math.min(240, innerWidth / 2));

    const { isDarkMode } = useTheme();

    const erasingDuration = 1200;


    const [edgeState, setEdgeState] = useState({
        visible: false,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        value: '',
        edgeId: null
    });

    createCustomNode(isDarkMode);

    createCustomEdge(isDarkMode);

    createCustomBehavior();



    const graphRef = useRef(null);
    const chartRef = useRef(null);




    const onConfirmEdge = () => {


        if (edgeState.edgeId && graphRef.current) {

            // const edge = graphRef.current.findById(edgeState.edgeId);

            const edge = graphRef.current.getEdges().find(edge => {
                const model = edge.getModel();
                return model.customId === edgeState.edgeId;
            });


            if (edge) {

                const model = edge.getModel();
                const trimmedValue = edgeState.value?.trim() || '';
                if (trimmedValue === '' || trimmedValue === model.name) {
                    setEdgeState({ ...edgeState, visible: false });
                    return;
                }

                graphRef.current.updateItem(edge, { name: trimmedValue });

                const sourceNodeId = model.source;
                const targetNodeId = model.target;
                // Retrieve the source and target nodes using their IDs
                const sourceNode = graphRef.current.findById(sourceNodeId);
                const targetNode = graphRef.current.findById(targetNodeId);

                const sid = sourceNode.getModel().record_id;
                const tid = targetNode.getModel().record_id;

                // Extract the record_id from each node
                const sourceRecordId = sid ? sid : sourceNode.getModel().start_record_id;
                const targetRecordId = tid ? tid : targetNode.getModel().start_record_id;

                const adjust = (sid ? '0' : '1') + (tid ? '0' : '1');

                // // Call updateNodeData with the appropriate parameters
                updateEdgeData(sourceRecordId, targetRecordId, adjust, { "name": trimmedValue });

                if (platform === 'ios') {
                    // ios super buggy; adding this line preventatively
                    // updateGraphData('edge', edgeState.edgeId, { name: trimmedValue });
                    graphRef.current.refresh();
                }


                // graphRef.current.setItemState(edge, 'creating', false);

                // graphRef.current.refresh(); // Refresh the graph to apply the changes
            }
        }

        // console.log('going to set edge state to false');

        setEdgeState({ ...edgeState, visible: false });

    };







    const eventInput = useCallback((inputElement) => {
        // ios has a bug that I can't remove focus after typing. So let's just only focus when the input is empty
        if (inputElement && (edgeState.value == null || edgeState.value === "")) {
            inputElement.focus();
        }

    }, [edgeState.value]);



    useEffect(() => {


        if (!chartRef.current || !userData || !userData.nodes || !userData.edges) {
            // Skip graph initialization if userData is not ready
            return;
        }




        const { nodes, edges } = userData;
        const n = nodes.length;
        const vertPadding = 30;  //this moves the graph down the canvas, solving the problem of top part of graph missing

        // Calculate total height needed for the graph
        const totalGraphHeight = n * minNodeSeparation + vertPadding * 2;
        // console.log('totalGraphHeight is', totalGraphHeight);

        // Adjust the container height dynamically
        const containerHeight = Math.max(totalGraphHeight, minGraphHeight); // Ensure a minimum height

        // Update the graph's height to match the container's height
        const chartHeight = containerHeight;


        // Define the base modes
        // const baseModes = [
        // {
        //     type: 'edge-tooltip',
        //     formatText: function formatText(model) {
        //         // const text = mode === 'regular' ? model.sourceCluster + ': ' + model.label : model.name;
        //         const text = model.name + ': ' + model.label;
        //         return text;
        //     },
        //     // offset: 0,
        // },
        // {
        //     type: 'tooltip',
        //     formatText: function formatText(model) {
        //         const text = model.name;

        //         const now = moment();
        //         const duration = moment.duration(now.diff(moment.unix(model.timestamp)));

        //         // Check total minutes including seconds for rounding purposes
        //         const totalMinutes = duration.asMinutes();
        //         const roundedMinutes = Math.round(totalMinutes);

        //         return `${text} - ${formatDurationCompact(roundedMinutes)} ago`;
        //     },
        // }
        // ];



        // Conditionally include create-edge mode
        const graphMode =
        {
            type: 'create-edge',
            // trigger: 'drag', // 'click' by default. options: 'drag', 'click'
            shouldBegin: (e) => {
                const clickedNode = e.item;
                graph.setItemState(clickedNode, 'clicked', true);
                // Get the id of the node where the edge creation starts
                const startNodeId = clickedNode.getModel().id;

                // Store the start node id for later reference
                graph.set('startNodeId', startNodeId);
                // console.log('should begin');
                return true;
            },
            shouldEnd: (e) => {
                // console.log('should end');
                // Get the id of the node where the edge creation ends
                const endNode = e.item;
                const endNodeId = endNode.getModel().id;
                const endNodeTimestamp = endNode.getModel().timestamp;

                // Get the start node id from the graph
                const startNodeId = graph.get('startNodeId');
                const startNode = graph.findById(startNodeId);
                const startNodeTimestamp = startNode.getModel().timestamp;

                // Prevent self-edges
                if (startNodeId === endNodeId) {
                    //  if self-edge, do not reset clicked false for the clicked node -- wait for a new edge to be created
                    return false;
                }

                // Prevent edge creation if the end node's timestamp is earlier than the start node's timestamp
                if (endNodeTimestamp <= startNodeTimestamp) {
                    // do not reset clicked false for the clicked node-- wait for a new edge to be created
                    return false;
                }

                // Check for duplicate edges
                const isDuplicate = graph.getEdges().some(edge => {
                    const edgeModel = edge.getModel();
                    return edgeModel.source === startNodeId && edgeModel.target === endNodeId;
                });

                if (isDuplicate) {
                    // if duplicate edge, also do not reset clicked false -- wait for a new edge to be created from the first click
                    return false;
                }

                graph.setItemState(startNode, 'clicked', false);
                return true;
            },
        };

        const afterCreateEdge = (edge, graph) => {


            // I find it clearer to remove the hover from the second clicked node, but it's not necessary
            // graph.setItemState(e.edge.getTarget(), 'hover', false);

            // Retrieve the source and target nodes using their IDs
            const sourceNodeId = edge.getModel().source;
            const targetNodeId = edge.getModel().target;
            const sourceNode = graph.findById(sourceNodeId);
            const targetNode = graph.findById(targetNodeId);

            const step = targetNode.getModel().i - sourceNode.getModel().i;

            const customEdgeId = `${sourceNodeId}-${targetNodeId}-${Date.now()}`;

            const newEdgeModel = { ...edge.getModel(), customId: customEdgeId };

            // const newEdgeModel = e.edge.getModel();


            // newEdgeModel.isGrowing = true; // Flag to indicate that the edge is growing
            processEdge(newEdgeModel);
            // netAnEdge(newEdgeModel, graph.getEdges());

            // start with not showing the edge, to prep for animation to gradually show
            // newEdgeModel.style = { ...newEdgeModel.style, opacity: 1 };

            const sourceEvent = sourceNode.getModel().name;
            const targetEvent = targetNode.getModel().name;
            const sourceCluster = sourceNode.getModel().cluster;
            const targetCluster = targetNode.getModel().cluster;

            const sameCluster = sourceCluster === targetCluster;

            newEdgeModel.name = sameCluster ? sourceCluster : sourceEvent + ' -> ' + targetEvent;

            // // Set initial state for the growing animation

            graph.updateItem(edge, newEdgeModel);

            graph.setItemState(edge, 'creating', true);


            const sid = sourceNode.getModel().record_id;
            const tid = targetNode.getModel().record_id;

            // Extract the record_id from each node
            const sourceRecordId = sid ? sid : sourceNode.getModel().start_record_id;
            const targetRecordId = tid ? tid : targetNode.getModel().start_record_id;

            // const sourceEvent = sourceNode.getModel().name;
            // const targetEvent = targetNode.getModel().name;

            const adjust = (sid ? '0' : '1') + (tid ? '0' : '1');

            createEdge(sourceRecordId, targetRecordId, adjust);

            if (sourceRecordId !== targetRecordId) {
                // const model = e.edge.getModel();
                const { x, y, width, height } = edge.getBBox();
                const canvasPosition = graph.getCanvasByPoint(x, y);
                const graphContainer = graph.get('container');
                const canvasRect = graphContainer.getBoundingClientRect();
                // console.log('edge id is', customEdgeId);

                const absoluteLeft = canvasRect.left + window.scrollX + canvasPosition.x;
                const absoluteTop = canvasRect.top + window.scrollY + canvasPosition.y;
                // const absoluteRight = canvasRect.left + window.scrollX + canvasPosition.x;

                // console.log('canvasRect left and right', canvasRect.left, canvasRect.right, 'windowScrollX', window.scrollX, 'canvasPosition.x', canvasPosition.x, sourceNode);
                // graph.refreshItem(edge);

                setEdgeState({
                    visible: true,
                    x: absoluteLeft - 180,
                    y: absoluteTop + height / 2 - 20,
                    width: 200,
                    height: 40,
                    value: '',  // here we start with empty value, since upon creation we only show input if source and target are different record ids, hence the default value is long and not nice to see; the confirm edge will ignore empty value and not update from that long default value
                    edgeId: customEdgeId
                });
            }

        };


        // console.log('node width is', nodeWidth);
        let graph = graphRef.current;

        if (!graph) {


            const fillColor = isDarkMode ? '#ccc' : '#444';




            graph = new G6.Graph({
                container: chartRef.current,
                width: chartWidth,
                height: chartHeight,
                modes: {
                    default: isTouch ? ['custom-create-edge', 'drag-canvas', 'zoom-canvas'] : [graphMode, 'drag-canvas', 'zoom-canvas'],
                },
                afterCreateEdge: afterCreateEdge,
                defaultEdge: {
                    size: 3,
                    style: {
                        // opacity: 0.7,
                        lineWidth: 3,
                        lineAppendWidth: isTouch ? 10 : 3,
                        // radius: 20,
                        // offset: (model) => { return model.curveOffset },
                    },
                    labelCfg: {
                        // autoRotate: true,
                        // position: "center",
                        refY: -6,
                        style: {
                            fill: fillColor,
                            // stroke: fillColor,
                            fontSize: 14,
                            rotate: Math.PI / 2,
                            textBaseline: 'middle',
                            // fillOpacity: 0.5,
                            // background: {  //adding this background caused the graph to produce a million duplicates when panned or zoomed
                            //     fill: '#888', // You can use a transparent color here if you don't want it visible
                            //     padding: [5, 8], // Adjust padding to increase the size
                            //     radius: 2, // Optional: adds rounded corners
                            //     fillOpacity: 0, // Make the background slightly visible or fully transparent
                            // }
                        },
                    },
                },
                defaultNode: {
                    type: 'round-rect',
                    size: [nodeWidth, 24],
                    labelCfg: {
                        style: {
                            fill: fillColor,
                            fontSize: 14,
                            textBaseline: 'middle',
                            fontFamily: 'Arial, sans-serif',
                        },
                    },
                    style: {
                        width: nodeWidth,
                    },
                },
            });


            graphRef.current = graph;



        } else {

            const currentsqChartSize = Math.min(screenWidth, screenHeight, 1200);
            const currentWidth = Math.max(screenWidth - 20, currentsqChartSize * 2);
            const currentHeight = chartRef.current.scrollHeight;
            // const currentWidth = chartRef.current.scrollWidth;
            // const currentHeight = chartRef.current.scrollHeight;
            if (!graph || graph.get('destroyed')) return;
            if (!chartRef.current || !chartRef.current.scrollWidth || !chartRef.current.scrollHeight) return;
            if (currentWidth === graph.getWidth() && currentHeight === graph.getHeight()) return;
            graph.changeSize(currentWidth, currentHeight);


        }

        // graph.on('afteradditem', (e) => {
        //     console.log('Item added:', e.item);
        //     console.log('Item type:', e.item.getType());
        //     console.log('item id', e.item.getModel().id);
        //     console.log('Total nodes now:', graph.getNodes().length);
        //     console.log('Total edges now:', graph.getEdges().length);
        // });



        graph.on('aftercreateedge', (e) => {

            afterCreateEdge(e.edge, graph);

        });

        const edgeTouch = isTouch ? 'edge:touchstart' : 'edge:click';


        graph.on(edgeTouch, (evt) => {
            const clickedElement = evt.target; // Get the clicked element
            // console.log('edge click', clickedElement);
            const item = evt.item;
            const model = item.getModel();

            const overlaps = (record1, record2) => {
                return record1.end_time > record2.time && record1.time < record2.end_time;
            }


            const calculateOverlapDuration = (record1, record2) => {
                const startMax = Math.max(record1.time, record2.time);
                const endMin = Math.min(record1.end_time, record2.end_time);
                return (endMin - startMax) / 60; // Convert seconds to minutes if your timestamps are in seconds
            }


            const netAnEdge = (edgeModel, edges) => {
                let nettedDurationInMinutes = edgeModel.duration; // Start with original duration
                let maxEnd = nodeMap.get(edgeModel.source).timestamp; // Initialize maxEnd with the current edge's start time

                const thisRecord = { time: nodeMap.get(edgeModel.source).timestamp, end_time: nodeMap.get(edgeModel.target).timestamp, id: edgeModel.id }

                const edgeRecords = edges.map(edge => {
                    const model = edge.getModel();
                    const start_time = nodeMap.get(model.source).timestamp;
                    const end_time = nodeMap.get(model.target).timestamp;
                    return { time: start_time, end_time, id: model.id }
                });

                // Ensure records are sorted by their start time
                const sortedRecords = edgeRecords.sort((edge1, edge2) => { return edge1.time - edge2.time });

                sortedRecords.forEach((otherRecord) => {
                    if (thisRecord.id !== otherRecord.id && (otherRecord.time > thisRecord.time || otherRecord.end_time < thisRecord.end_time)) { // Don't compare a record with itself; and also don't try to subtract from another event that fully contains this one
                        // the latter decision is purely for flavor, just so that the netting can show more results

                        if (overlaps(thisRecord, otherRecord)) {
                            const truncatedOtherRecord = { ...otherRecord, time: Math.max(otherRecord.time, maxEnd) }; // Truncate other record to the maxEnd
                            if (truncatedOtherRecord.time >= truncatedOtherRecord.end_time) {
                                return; // Returning from a callback function in .forEach() is akin to using continue in a traditional loop—it ends the current iteration's callback execution and proceeds to the next item in the array.
                            }
                            const overlapDuration = calculateOverlapDuration(thisRecord, truncatedOtherRecord);
                            maxEnd = Math.min(thisRecord.end_time, otherRecord.end_time); // Update maxEnd to the furthest end of the overlap
                            nettedDurationInMinutes -= overlapDuration; // Subtract overlap
                        }
                    }
                });

                return Math.round(nettedDurationInMinutes);
            };



            if (clickedElement.get('name') === 'text-shape') {
                // Clicked on the node label, show TimePicker

                const netTime = netAnEdge(model, graph.getEdges());
                // const netTime = '30m';
                if (clickedElement.attr('alt_text') !== model.duration && netTime !== model.duration && netTime > 0) {
                    clickedElement.attr({ text: 'net: ' + formatDurationCompact(netTime), alt_text: model.duration });
                } else {
                    clickedElement.attr({ text: model.label, alt_text: '' });
                }

            };

        });



        let erasingTimeoutId = null;






        // graph.on('edge:click', (event) => {
        //     const clickedEdge = event.item; // Get the clicked edge
        //     console.log('edge click', clickedEdge);

        //     const item = event.item;

        //     const model = item.getModel();
        //     const { x, y, width, height } = item.getBBox();
        //     const canvasPosition = graph.getCanvasByPoint(x, y);
        //     const graphContainer = graph.get('container');
        //     const canvasRect = graphContainer.getBoundingClientRect();
        //     const edgeId = model.id;

        //     const absoluteLeft = canvasRect.left + window.scrollX + canvasPosition.x;
        //     const absoluteTop = canvasRect.top + window.scrollY + canvasPosition.y;

        //     setEdgeState({
        //         visible: true,
        //         x: absoluteLeft - 60,
        //         y: absoluteTop + height / 2 - 20,
        //         width: Math.min(Math.max(model.name?.length * 10, 120), 200),
        //         height: 40,
        //         value: model.name,
        //         edgeId
        //     });


        // });

        // const canvasTouchStart = isTouch ? 'canvas:touchstart' : 'canvas:click';
        // const canvasTouchEnd = isTouch ? 'canvas:touchend' : 'canvas:mouseup';



        // Event listener for clicking on the graph background
        // to remove pressed-down effect on a node if create edge is cancelled
        graph.on('canvas:click', (e) => {
            // Check if there is a last clicked node

            e.preventDefault();
            // console.log('going to set mode to custom');
            // graph.setMode('custom')
            // graph.refresh();

            console.log('canvas click/touch');

            const startNodeId = graph.get('startNodeId');
            const startNode = graph.findById(startNodeId);

            if (startNode) {
                // Reset the state of the last clicked node
                graph.setItemState(startNode, 'clicked', false);
                // lastClickedNodeRef.current = null; // Clear the reference
            }

            // clear the edit state, and show no input box

            setEdgeState({
                visible: false,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                value: '',
                edgeId: null
            });

            const tooltip = document.getElementById('customTooltip');
            tooltip.style.display = 'none';

        });


        // graph.on(canvasTouchEnd, (e) => {

        //     const tooltip = document.getElementById('customTooltip');
        //     tooltip.style.display = 'none';


        //     // setTimeout(() => {
        //     //     console.log('going to set mode to default');
        //     //     graph.setMode('default');
        //     // }, 100);

        // });




        // graph.on('aftercreateedge', (e) => {

        //     // I find it clearer to remove the hover from the second clicked node, but it's not necessary
        //     graph.setItemState(e.edge.getTarget(), 'hover', false);

        //     // Retrieve the source and target nodes using their IDs
        //     const sourceNodeId = e.edge.getModel().source;
        //     const targetNodeId = e.edge.getModel().target;
        //     const sourceNode = graph.findById(sourceNodeId);
        //     const targetNode = graph.findById(targetNodeId);
        //     const step = targetNode.getModel().i - sourceNode.getModel().i;

        //     const customEdgeId = `${sourceNodeId}-${targetNodeId}-${Date.now()}`;

        //     const newEdgeModel = { ...e.edge.getModel(), customId: customEdgeId };

        //     // const newEdgeModel = e.edge.getModel();


        //     newEdgeModel.isGrowing = true; // Flag to indicate that the edge is growing
        //     processEdge(newEdgeModel);
        //     // netAnEdge(newEdgeModel, graph.getEdges());

        //     newEdgeModel.style = { ...newEdgeModel.style, opacity: 0 };

        //     // // Set initial state for the growing animation
        //     // const shape = e.edge.get('keyShape');
        //     // const length = shape.getTotalLength();
        //     // console.log('length is', length);
        //     // shape.attr({ lineDash: [0, length] }); // Start with an invisible line




        //     graph.updateItem(e.edge, newEdgeModel);

        //     graph.setItemState(e.edge, 'erasing', true);



        //     const sid = sourceNode.getModel().record_id;
        //     const tid = targetNode.getModel().record_id;

        //     // Extract the record_id from each node
        //     const sourceRecordId = sid ? sid : sourceNode.getModel().start_record_id;
        //     const targetRecordId = tid ? tid : targetNode.getModel().start_record_id;

        //     const sourceEvent = sourceNode.getModel().name;
        //     const targetEvent = targetNode.getModel().name;

        //     const adjust = (sid ? '0' : '1') + (tid ? '0' : '1');

        //     createEdge(sourceRecordId, targetRecordId, adjust);
        //     updateGraphData('edge', [sourceNodeId, targetNodeId], { name: sourceEvent + ' -> ' + targetEvent, customId: customEdgeId });

        //     if (sourceRecordId !== targetRecordId) {
        //         // const model = e.edge.getModel();
        //         const { x, y, width, height } = e.edge.getBBox();
        //         const canvasPosition = graph.getCanvasByPoint(x, y);
        //         const graphContainer = graph.get('container');
        //         const canvasRect = graphContainer.getBoundingClientRect();
        //         // console.log('edge id is', customEdgeId);

        //         const absoluteLeft = canvasRect.left + window.scrollX + canvasPosition.x;
        //         const absoluteTop = canvasRect.top + window.scrollY + canvasPosition.y;
        //         // const absoluteRight = canvasRect.left + window.scrollX + canvasPosition.x;

        //         // console.log('canvasRect left and right', canvasRect.left, canvasRect.right, 'windowScrollX', window.scrollX, 'canvasPosition.x', canvasPosition.x, sourceNode);

        //         setEdgeState({
        //             visible: true,
        //             x: absoluteLeft - 180,
        //             y: absoluteTop + height / 2 - 20,
        //             width: 200,
        //             height: 40,
        //             value: newEdgeModel.name,
        //             edgeId: customEdgeId
        //         });
        //     }

        // });



        function showTooltip(evt) {
            const { item } = evt;
            const model = item.getModel();
            const { x, y } = graph.getCanvasByPoint(model.x, model.y);
            const graphContainer = graph.get('container');
            const canvasRect = graphContainer.getBoundingClientRect();
            const tooltip = document.getElementById('customTooltip');

            const text = model.name;
            const now = moment();
            const duration = moment.duration(now.diff(moment.unix(model.timestamp)));
            const totalMinutes = duration.asMinutes();
            const roundedMinutes = Math.round(totalMinutes);

            tooltip.innerHTML = `${text} - ${formatDurationCompact(roundedMinutes)} ago`;
            tooltip.style.display = 'block';

            const posX = canvasRect.left + window.scrollX + x;
            const posY = canvasRect.top + window.scrollY + y - 60;

            // Adjust the position directly here to ensure it's within bounds
            // adjustTooltipPosition(tooltip, posX, posY);
            tooltip.style.left = `${posX}px`;
            tooltip.style.top = `${posY}px`;
        }





        if (isTouch) {

            // Touch events
            graph.on('node:touchstart', (e) => {
                showTooltip(e);
                graph.setItemState(e.item, 'hover', true);
                e.stopPropagation();
            });

            graph.on('node:touchend', (e) => {
                const tooltip = document.getElementById('customTooltip');
                tooltip.style.display = 'none';
                graph.setItemState(e.item, 'hover', false);
            });

            graph.on('node:touchmove', (e) => {
                updateTooltipPosition(e);
                graph.setItemState(e.item, 'hover', false);
            });

            // // Touch events for edges
            // graph.on('edge:touchstart', showEdgeTooltip);
            graph.on('edge:touchstart', function (e) {
                e.preventDefault();
                // console.log('going to set mode to edit');
                // graph.setMode('default');
                showEdgeTooltip(e);
                e.stopPropagation();
            });

            // graph.on('edge:touchmove', updateTooltipPositionForEdges);
            graph.on('edge:touchend', () => {
                const tooltip = document.getElementById('customTooltip');
                tooltip.style.display = 'none';
            });




        } else {

            // Mouse events for nodes
            graph.on('node:mouseenter', (e) => {
                showTooltip(e);
                graph.setItemState(e.item, 'hover', true);
            });


            graph.on('node:mouseleave', (e) => {
                const tooltip = document.getElementById('customTooltip');
                tooltip.style.display = 'none';
                graph.setItemState(e.item, 'hover', false);
            });

            graph.on('node:mousemove', (evt) => {
                updateTooltipPosition(evt);
            });

            // Mouse events for edges
            graph.on('edge:mousemove', updateTooltipPositionForEdges);

            graph.on('edge:mouseenter', function (e) {
                const edge = e.item;
                graph.setItemState(edge, 'hover', true);
                showEdgeTooltip(e);
            });

            // Also handle mouse leave to cancel the erasing in case of dragging away
            graph.on('edge:mouseleave', (e) => {
                const edge = e.item;
                // Only remove hover state and stop animation if not already erased
                if (edge && !edge.destroyed) {
                    graph.setItemState(edge, 'hover', false);
                    graph.setItemState(edge, 'erasing', false);

                    // Clear the timeout if it's set
                    if (erasingTimeoutId) {
                        clearTimeout(erasingTimeoutId);
                        erasingTimeoutId = null;
                    }
                }

                const tooltip = document.getElementById('customTooltip');
                tooltip.style.display = 'none';
            });


        }


        const edgeStart = isTouch ? 'edge:touchstart' : 'edge:mousedown';
        const edgeEnd = isTouch ? 'edge:touchend' : 'edge:mouseup';

        // Handle mouse down to start the erasing animation
        graph.on(edgeStart, (e) => {
            e.preventDefault();
            const clickedEdge = e.item;
            // Start the erasing effect immediately
            graph.setItemState(clickedEdge, 'hover', false);
            graph.setItemState(clickedEdge, 'erasing', true);

            // Set a timeout to remove the edge after the animation duration
            erasingTimeoutId = setTimeout(() => {
                // Check if the edge still exists before trying to remove it
                if (clickedEdge && !clickedEdge.destroyed) {

                    const tooltip = document.getElementById('customTooltip');
                    tooltip.style.display = 'none';

                    const sourceNodeId = clickedEdge.getModel().source;
                    const targetNodeId = clickedEdge.getModel().target;
                    // Retrieve the source and target nodes using their IDs
                    const sourceNode = graph.findById(sourceNodeId);
                    const targetNode = graph.findById(targetNodeId);

                    graph.removeItem(clickedEdge);


                    const sid = sourceNode.getModel().record_id;
                    const tid = targetNode.getModel().record_id;

                    // Extract the record_id from each node
                    const sourceRecordId = sid ? sid : sourceNode.getModel().start_record_id;
                    const targetRecordId = tid ? tid : targetNode.getModel().start_record_id;

                    const adjust = (sid ? '0' : '1') + (tid ? '0' : '1');


                    removeEdge(sourceRecordId, targetRecordId, adjust);



                }
                // Reset the timeout ID
                erasingTimeoutId = null;
            }, erasingDuration);
        });

        // Handle mouse up to cancel the erasing if it's still in progress
        graph.on(edgeEnd, (e) => {
            const edge = e.item;
            // Stop the erasing animation
            graph.setItemState(edge, 'erasing', false);

            // Clear the timeout if it's set
            if (erasingTimeoutId) {
                clearTimeout(erasingTimeoutId);
                erasingTimeoutId = null;
            }
        });


        function updateTooltipPosition(evt) {
            // const { item } = evt;
            // const model = item.getModel();
            // const graphContainer = graph.get('container');
            // const canvasRect = graphContainer.getBoundingClientRect();
            // let x, y;

            // console.log('mousemove', 'evt', evt);
            const { canvasX: x, canvasY: y } = evt;
            const graphContainer = graph.get('container');
            const canvasRect = graphContainer.getBoundingClientRect();
            const tooltip = document.getElementById('customTooltip');

            const posX = canvasRect.left + window.scrollX + x;
            const posY = canvasRect.top + window.scrollY + y - 60;

            // adjustTooltipPosition(tooltip, posX, posY);
            tooltip.style.left = `${posX}px`;
            tooltip.style.top = `${posY}px`;

            // if (evt.type.includes('touch')) {  // Check if the event is a touch event
            //     const touch = evt.originalEvent.touches[0];
            //     const point = graph.getPointByClient(touch.clientX, touch.clientY);
            //     x = point.x;
            //     y = point.y;
            // } else {
            //     const point = graph.getCanvasByPoint(model.x, model.y);
            //     x = point.x;
            //     y = point.y;
            // }

            // const tooltip = document.getElementById('customTooltip');
            // tooltip.style.left = `${canvasRect.left + window.scrollX + x}px`;
            // tooltip.style.top = `${canvasRect.top + window.scrollY + y - 60}px`;
        }



        // graph.on('node:mouseleave', (evt) => {
        //     const tooltip = document.getElementById('customTooltip');
        //     tooltip.style.display = 'none';
        // });

        // // Handle mouse enter on edges to show tooltip
        // graph.on('edge:mouseenter', (evt) => {
        //     const { item } = evt;
        //     const model = item.getModel();
        //     const { canvasX: x, canvasY: y } = evt;
        //     const graphContainer = graph.get('container');
        //     const canvasRect = graphContainer.getBoundingClientRect();
        //     const tooltip = document.getElementById('customTooltip');

        //     // Formatting the text based on the edge model
        //     const text = model.name + ': ' + model.label;

        //     tooltip.innerHTML = text;  // Set the tooltip content
        //     tooltip.style.display = 'block';
        //     tooltip.style.left = `${canvasRect.left + window.scrollX + x - 100}px`;
        //     tooltip.style.top = `${canvasRect.top + window.scrollY + y - 60}px`;
        // });

        // // Update tooltip position on mouse move over edge
        // graph.on('edge:mousemove', (evt) => {
        //     const { canvasX: x, canvasY: y } = evt;
        //     const graphContainer = graph.get('container');
        //     const canvasRect = graphContainer.getBoundingClientRect();
        //     const tooltip = document.getElementById('customTooltip');

        //     tooltip.style.left = `${canvasRect.left + window.scrollX + x - 100}px`;
        //     tooltip.style.top = `${canvasRect.top + window.scrollY + y - 60}px`;
        // });

        // // Hide tooltip when mouse leaves the edge
        // graph.on('edge:mouseleave', () => {
        //     const tooltip = document.getElementById('customTooltip');
        //     tooltip.style.display = 'none';
        // });


        function showEdgeTooltip(evt) {
            // evt.preventDefault();
            const { item } = evt;
            const model = item.getModel();
            const graphContainer = graph.get('container');
            const canvasRect = graphContainer.getBoundingClientRect();
            let x, y;

            if (evt.type.includes('touch')) {
                const touch = evt.originalEvent.touches[0];
                const point = graph.getPointByClient(touch.clientX, touch.clientY);
                x = point.x;
                y = point.y;
            } else {
                const point = graph.getCanvasByPoint(model.startPoint.x, model.startPoint.y);
                x = point.x;
                y = point.y;
            }

            const tooltip = document.getElementById('customTooltip');
            const text = model.name + ': ' + model.label;
            tooltip.innerHTML = text;
            tooltip.style.display = 'block';
            tooltip.style.left = `${canvasRect.left + window.scrollX + x - 100}px`;
            tooltip.style.top = `${canvasRect.top + window.scrollY + y - 60}px`;
        }

        function updateTooltipPositionForEdges(evt) {
            const graphContainer = graph.get('container');
            const canvasRect = graphContainer.getBoundingClientRect();
            let x, y;

            if (evt.type.includes('touch')) {
                const touch = evt.originalEvent.touches[0];
                const point = graph.getPointByClient(touch.clientX, touch.clientY);
                x = point.x;
                y = point.y;
            } else {
                x = evt.canvasX;
                y = evt.canvasY;
            }

            const tooltip = document.getElementById('customTooltip');
            tooltip.style.left = `${canvasRect.left + window.scrollX + x - 100}px`;
            tooltip.style.top = `${canvasRect.top + window.scrollY + y - 60}px`;
        }




        const nodeMap = new Map();
        const clusterMap = new Map();
        let clusterId = 0;
        const begin = [chartWidth / 2 - 20, vertPadding];
        const end = [chartWidth / 2 - 20, chartHeight - vertPadding];
        const yLength = end[1] - begin[1];
        // const ySep = yLength / n;


        const ySep = Math.min(Math.max(yLength / Math.max(n - 1, 1), minNodeSeparation), maxNodeSeparation);
        // console.log('ySep is', ySep);

        nodes.forEach(function (node, i) {
            node.x = begin[0];
            node.y = begin[1] + i * ySep;
            node.i = i;
            nodeMap.set(node.id, node);

            // cluster
            if (node.cluster && clusterMap.get(node.cluster) === undefined) {
                clusterMap.set(node.cluster, clusterId);
                clusterId++;
            }

            const id = clusterMap.get(node.cluster);

            if (node.style) {
                node.style.fill = colors[id % colors.length];
                node.style.stroke = colors[id % colors.length];
            } else {
                node.style = {
                    fill: colors[id % colors.length],
                    stroke: colors[id % colors.length],
                };
            }

            // label
            node.label = formatLongText(node.name, Math.floor(Math.min(30, nodeWidth / 7)));


            // node.labelCfg = {
            //     position: 'left',
            //     offset: -5,
            //     style: {
            //         textAlign: 'start',
            //     },
            // };

        });


        const processEdge = (edge) => {
            const source = nodeMap.get(edge.source);
            const target = nodeMap.get(edge.target);
            const step = target.i - source.i;
            const curveOffset = -22 * step;

            edge.type = 'flowing-dashed';
            edge.curveOffset = curveOffset;
            // edge.color = source.style.fill;
            edge.sourceName = source.name;
            edge.sourceCluster = source.cluster;
            edge.targetName = target.name;
            edge.duration = Math.round((target.timestamp - source.timestamp) / 60);
            edge.label = formatDurationCompact(edge.duration);
            edge.style = {
                ...edge.style,
                curveOffset: curveOffset,
                stroke: `l(90) 0:${source.style.fill} 1:${target.style.fill}`, // Gradient stroke, l(90) makes it vertical as opposed to l(0) which would be horizontal
            };
            // edge.labelCfg = { ...edge.labelCfg, style: { ...edge.labelCfg?.style, fill: `l(90) 0:${source.style.fill} 1:${target.style.fill}` } };

        };

        // Existing edges
        edges.forEach((edge) => {
            processEdge(edge);
        });



        graph.data({ nodes, edges });

        graph.render();


        // const handleResize = () => {
        //     const currentWidth = chartRef.current.scrollWidth;
        //     const currentHeight = chartRef.current.scrollHeight;
        //     if (!graph || graph.get('destroyed')) return;
        //     if (!chartRef.current || !chartRef.current.scrollWidth || !chartRef.current.scrollHeight) return;
        //     if (currentWidth === graph.getWidth() && currentHeight === graph.getHeight()) return;
        //     graph.changeSize(currentWidth, currentHeight);
        // };


        // window.addEventListener('resize', handleResize);

        // Clean up
        return () => {
            if (graphRef.current) {
                graphRef.current.destroy();
                graphRef.current = null;
            }
        };



    }, [userData, dateText, screenWidth, screenHeight]);
    // including dependencies such as createEdge, removeEdge, updateGraphData makes the graph refresh itself after removing or creating edge, reverting to the initial state of the graph, which is not desired
    // userData, dateText, screenWidth, screenHeight, createEdge, removeEdge, updateGraphData




    useEffect(() => {

        setEdgeState({
            visible: false,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            value: '',
            edgeId: null
        });

    }, [dateText]);


    useEffect(() => {

        // Use this function whenever isDarkMode changes

        if (graphRef.current) {
            // thanks to the lines in g6.registerNode, Update
            // after refresh, the node will be updated
            graphRef.current.refresh();
        }

    }, [isDarkMode]);






    return (
        <Flex justify="center" align="center" style={{ overflow: "hidden" }}>

            {edgeState.visible && (
                <div
                    style={{
                        position: 'absolute', left: edgeState.x, top: edgeState.y, zIndex: 1000, height: edgeState.height
                    }}
                >
                    <Space.Compact style={{ width: '100%', height: "100%" }}>
                        <Input
                            value={edgeState.value}
                            onChange={(e) => setEdgeState({ ...edgeState, value: e.target.value })}
                            onPressEnter={onConfirmEdge}
                            style={{ width: edgeState.width, height: "100%" }}
                            maxLength={140}
                            ref={eventInput}
                        />
                        <Button type="primary" onClick={onConfirmEdge} style={{ width: 30, height: "100%", lineHeight: 'initial', padding: 0 }}
                            icon={<RightOutlined />} />
                    </Space.Compact>
                </div>
            )}

            <div id="graph-container" ref={chartRef} style={{ flexGrowth: 1 }} />


            <div id="customTooltip" />
        </Flex>
    );
};


export default MyG6Chart;





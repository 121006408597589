import React from 'react';
import {
    Result, Button
} from 'antd';
import { useNavigate } from 'react-router-dom';

import * as ROUTES from "../../constants/routes";

import withPage from '../Results';

const Res = (props) => {

    const navigate = useNavigate();

    return <Result
        // status="500"
        // icon={<Image
        //     height={300}
        //     src="500.svg"
        //     alt="500"
        //     preview={false}
        //     fallback="Something went wrong"
        //     style={{ padding: "20px 6px 0px 2px", marginLeft: 12, marginRight: 12 }}
        // // style={{ margin: "auto", verticalAlign: "middle", display: "inline-block", height: "100%" }}
        // />}
        title="Oops"
        subTitle="Something went wrong."
        extra={<Button key="home" type="primary" onClick={() => { navigate(ROUTES.HOME) }}>Homepage</Button>}
    />;

}

export default withPage(Res);





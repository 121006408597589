import React, { useContext } from "react";

import {
    Layout, Row, Button, Typography, Alert
} from "antd";

import moment from 'moment';

// import NotMember from "./notyet-member";
// import UserCard from "./usercard";

import LandingFooter from '../Landing/footer';

// import { useIsFirstRender } from '../Hooks';

import { FirebaseContext } from '../Firebase';
import { AuthUserContext, useNetwork } from '../Contexts';


import { signOut } from "firebase/auth";

import LoginCred from './logincred';
import ToSubscribe from './toSubscribe';
import MySubscription from './mySubscription';
import DeleteAccount from './deleteAccount';
import { Capacitor } from '@capacitor/core';

const isNative = Capacitor.isNativePlatform();

const { Title } = Typography;

const { Content, Footer } = Layout;


const FillerApp = (props) => {
    return <></>
}



const App = (props) => {


    const { isOnline } = useNetwork();
    const firebase = useContext(FirebaseContext);
    const { authUser } = useContext(AuthUserContext);

    const active = authUser?.current_period_end && authUser.current_period_end * 1000 > moment().unix();

    const SubscriptionComponent = active ? MySubscription : (isNative ? FillerApp : ToSubscribe);

    const handleSignout = () => {

        localStorage.setItem('pendingMessages', null);
        signOut(firebase.auth);
    }


    return (


        <Layout
            style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: '100vh', // minHeight here only to fill the viewport initially

            }}
        >

            <Content>
                <div style={{ padding: "24px 16px 10px 16px", margin: "auto" }}>


                    {/* <CheckoutButton email={authUser?.email} level='monthly' /> */}

                    <Row type="flex" justify="start" style={{ margin: "20px 40px 0px 40px" }}>
                        <Title level={4}>{authUser?.email}</Title>

                    </Row>

                    {!isOnline && <Alert
                        type="warning"
                        message="You are offline"
                        description="Changes made will not be saved"
                        style={{ marginTop: '20px' }}
                        closable
                    />}


                    <Row type="flex" justify="center" style={{ margin: "10px 10px" }}>
                        <LoginCred {...props} />
                        <Button onClick={handleSignout}>Sign Out</Button>
                    </Row>



                    <div style={{ marginBottom: 20 }}>
                        <SubscriptionComponent authUser={authUser} />
                    </div>

                    <div style={{ marginBottom: 40 }}>
                        <DeleteAccount authUser={authUser} />
                    </div>

                </div>
            </Content>

            <Footer
                style={{
                    bottom: 0,
                    padding: 0,
                }}
            >
                <LandingFooter />
            </Footer>


        </Layout>
    );
};


export default App;
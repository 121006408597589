import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth, indexedDBLocalPersistence, initializeAuth, browserSessionPersistence } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const db = getDatabase(app);

// Get a reference to the authentication service

let auth;

if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence
    });
} else {
    auth = getAuth(app);
}


export { db, auth };


// const auth = getAuth(app);

// auth = initializeAuth(app, {
//     persistence: browserSessionPersistence
// });




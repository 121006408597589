import React, { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    Result, Button
} from 'antd';

import * as ROUTES from "../../constants/routes";


import { withFirebase } from '../Firebase';

import RecoverEmail from './RecoverEmail';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';

import withPage from '../Results';

// test locally at
// http://localhost:3000/auth/action?mode=resetPassword&oobCode=ABC123&apiKey=AIzaSy
// obviously change the email template to match the custom action url https://project.name/auth/action
// http://localhost:3000/auth/action?mode=recoverEmail&oobCode=ABC123&apiKey=AIzaSy
// http://localhost:3000/auth/action?mode=verifyEmail&oobCode=ABC123&apiKey=AIzaSy

// mode - The user management action to be completed
// oobCode - A one-time code, used to identify and verify a request
// apiKey - Your Firebase project's API key, provided for convenience

const Action = (props) => {


    const navigate = useNavigate(); // Use useNavigate hook for navigation

    const [searchParams] = useSearchParams();  // Retrieve search parameters

    // Access parameters directly
    const mode = searchParams.get('mode');
    // Get the one-time code from the query parameter.
    const actionCode = searchParams.get('oobCode');

    // console.log('mode is', mode, 'actionCode is', actionCode);

    // Get the action to complete.
    // const refer = (params && queryString.parse(params).refer) ?? null;


    // const { firebase } = props;
    // console.log('firebase from props is', firebase);

    // (Optional) Get the API key from the query parameter.
    // const apiKey = props.location.query.apiKey;

    // Handle the user management action.
    switch (mode) {
        case 'resetPassword':
            // Display email recovery handler and UI.
            return <ResetPassword actionCode={actionCode} navigate={navigate} {...props} />;
        case 'verifyEmail':
            // Display email verification handler and UI.
            return <VerifyEmail actionCode={actionCode} navigate={navigate} {...props} />;
        case 'recoverEmail':
            // Display reset password handler and UI.
            return <RecoverEmail actionCode={actionCode} navigate={navigate} {...props} />;
        default:
            // Error: invalid mode.
            return (
                <Result
                    status="warning"
                    title="The selected page mode is invalid"
                    // subTitle="Try return to the homepage?"
                    extra={[
                        <Button type="primary" key="home" onClick={() => navigate(ROUTES.LANDING)}>
                            Go Back
                        </Button>
                    ]}
                    style={{ margin: "80px auto", maxWidth: 800 }}
                />
            );
    }
}




export default withPage(withFirebase(Action)); 
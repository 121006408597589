import React from "react";
import { Layout, Alert } from 'antd';
import { useNetwork } from '../Contexts';

import Page from './page';

import LandingFooter from '../Landing/footer';


const { Content, Footer } = Layout;



const Help = (props) => {

    const { isOnline } = useNetwork();

    return (


        <Layout
            style={{
                display: 'flex',
                flexDirection: 'column',
                // height: '100%', // Use 100% height for the flex container
                minHeight: '100vh', // minHeight here only to fill the viewport initially

            }}
        >



            <Content>

                {!isOnline && <Alert
                    type="warning"
                    message="You are offline"
                    description="Some features don't work"
                    style={{ margin: '20px 24px' }}
                    closable
                />}


                <Page {...props} />

            </Content>

            <Footer
                style={{
                    // height: footerHeight,
                    position: 'sticky',
                    bottom: 0,
                    // padding: '10px 10px 20px 10px',
                    padding: 0,
                    borderTop: '1px solid #f0f0f0',
                }}
            >
                <LandingFooter />
            </Footer>


        </Layout >


    );

}

export default Help;


import Result404 from './404';
import Result403 from './403';


import React from 'react';
import {
    Layout, Flex, Image
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { siteName } from '../../constants';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core';
import * as ROUTES from "../../constants/routes";

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};

const isNative = Capacitor.isNativePlatform();

const { Header, Content, Footer } = Layout;

const headerHeight = isNative ? 120 : 80;
const headerPadding = isNative ? "40px 20px" : "0 20px";

const withPage = (Component) => {


    // not automatically redirect
    // give the user some time to think
    return function WrappedComponent(props) {

        const navigate = useNavigate(); // Use useNavigate hook for navigation

        const onClickLogo = () => {
            hapticsImpactLight();
            navigate(ROUTES.LANDING);
        }


        return (<Layout style={{ minHeight: "100vh" }}>
            <Header style={{
                height: headerHeight,
                padding: headerPadding,
            }}>

                <Flex justify='space-around' gap="large" align='end'>
                    <Image
                        height={60}
                        src="/images/logo_text.svg"
                        alt={siteName}
                        preview={false}
                        fallback={siteName}
                        style={{ display: "flex", justifyContent: 'center', marginTop: "10px", minWidth: "60px" }}
                        onClick={onClickLogo}
                    />


                </Flex>


            </Header>

            <Content>

                <Component {...props} />

            </Content>

            <Footer style={{ textAlign: 'center', padding: '20px', fontSize: "14px", background: '#00152a', position: 'sticky' }}>©{new Date().getFullYear()} {siteName} created by Spearmint Labs</Footer>
        </Layout>);
    };

};


export default withPage;


const Page403 = withPage(Result403); // Export the wrapped component
const Page404 = withPage(Result404);

export { Page404, Page403 };
import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import {
    Form, Input, Button, message, Row, Checkbox, Col, Modal
} from 'antd';
import { useSearchParams, Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { MailOutlined, LockOutlined } from '@ant-design/icons';

import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { createUser } from '../../functions';
import { siteUrl } from '../../constants';
import { FirebaseContext } from '../Firebase';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const hapticsImpactLight = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
};



const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const messages = ['OK', "Hold on...", "One sec...", "Finishing..."];

const App = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // const [loading2, setLoading2] = useState(false);
    const [captcha, setCaptcha] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [messageIndex, setMessageIndex] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();

    const firebase = useContext(FirebaseContext);
    // const location = useLocation();

    const recaptchaRef = useRef();

    const [searchParams] = useSearchParams();  // Retrieve search parameters

    useEffect(() => {

        let interval;
        // Only setup interval if loading is true
        if (loading) {
            interval = setInterval(() => {
                setMessageIndex(prevIndex => {
                    // Check if the next index would exceed the bounds of the messages array
                    if (prevIndex >= messages.length - 1) {
                        clearInterval(interval); // Clear interval if it's the last message
                        return prevIndex; // Keep the index at the last message
                    } else {
                        return prevIndex + 1; // Increment the message index
                    }
                });
            }, 2000);
        }
        else {
            // Reset messageIndex to zero when loading is false
            setMessageIndex(0);
        }

        // Cleanup function to clear the interval
        return () => {
            if (interval) {
                clearInterval(interval);
                setMessageIndex(0); // Reset the message index when the interval is cleared
            }
        };

    }, [loading]); // Only re-run the effect when `loading` changes


    const resetState = useCallback(() => {
        setLoading(false);
        setCaptcha(false);
        setAgreed(false);
        // Reset the reCaptcha
        recaptchaRef.current?.reset();
    }, [recaptchaRef]);


    useEffect(() => {
        if (!props.visible) {
            resetState();
        }
    }, [props.visible, resetState]);


    const displayError = (message, duration = 3) => {
        return messageApi.open({
            type: 'error',
            content: message,
            duration: duration,
        });
    };

    const handleCancel = () => {
        form?.resetFields();
        props.handleCancel();
    };

    const submitReCAPTCHA = async () => {
        try {
            const token = await recaptchaRef.current.executeAsync();
            setCaptcha(true);
            return token;
        } catch (error) {
            console.error('Error executing reCAPTCHA:', error);
            setCaptcha(false);
            throw error; // Propagate the error so that it can be handled in the calling function
        }
    };

    const onFinish = async (values) => {
        hapticsImpactLight();
        const { email, password } = values;
        const refer = searchParams.get('refer') || '';

        let serverResponse;

        setLoading(true);

        try {
            let recaptchaToken;

            if (!captcha) {
                recaptchaToken = await submitReCAPTCHA();
            }

            // setLoading2(true);

            const authUser = await createUserWithEmailAndPassword(firebase.auth, email, password);
            const firebaseToken = await authUser.user.getIdToken();
            serverResponse = await createUser(refer, email, recaptchaToken, firebaseToken);

            if (serverResponse && (serverResponse.status !== 200 || serverResponse.data.error)) {
                throw new Error('Server-side reCAPTCHA verification failed:' + serverResponse.data.error);
            }

            const actionCodeSettings = {
                url: siteUrl,
                handleCodeInApp: false,
            };

            await sendEmailVerification(firebase.auth.currentUser, actionCodeSettings);

            // setLoading(false);
            // setLoading2(false);

            messageApi.open({
                type: 'success',
                content: 'A verification email has been sent to you. Please confirm your account from the link in the email.',
                duration: 5,
            });

            // here I temporarily use handleCancel instead of the dedicated handleFinish
            handleCancel();

        } catch (error) {
            // setLoading(false);
            console.error(error);
            const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || '';

            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                const errorMessage = 'An account with that email already exists. Redirecting you to Sign In.';
                displayError(errorMessage, 4);
                props.goToOtherModal();

            } else {
                await displayError(formattedError, 5);
            }

            if (firebase.auth.currentUser && (serverResponse?.status !== 200 || serverResponse?.data?.error)) {
                // if auth worked but failed to create user in db, delete the auth user
                firebase.auth.currentUser.delete();
            }
            // here I simply use handleCancel instead of the dedicated handleFinish, which is for when signup was successful
            handleCancel();

        }
    };



    return (
        <div className="login-form">
            {contextHolder}

            <Modal
                open={props.visible}
                title="Create a New Account"
                onCancel={handleCancel}  //remove this so that pressing esc doesn't bring in the close of the modal
                footer={null}
                closable={true}
                maskClosable={false}
                width={440}
            >

                <Form
                    form={form}
                    name="signup"
                    onFinish={onFinish}
                    scrollToFirstError
                    className="form-container"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'not a valid email address',
                            },
                            {
                                required: true,
                                message: 'Please input your email',
                            },
                        ]}
                    >
                        <Input
                            allowClear
                            // name="email"
                            prefix={<MailOutlined style={{ opacity: 0.3 }} />}
                            placeholder="Email address to send verification to"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password',
                            },
                            {
                                min: 10, message: 'Password must be at least 10 characters long'
                            },
                            {
                                max: 20, message: 'Password must be at most 20 characters long'
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            // name="password"
                            prefix={<LockOutlined style={{ opacity: 0.3 }} />}
                            type="password"
                            placeholder="Password 10 characters or longer"
                            autoComplete="off"
                        />
                    </Form.Item>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={sitekey}
                    />

                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Accept agreement to continue')),
                            },
                        ]}
                    // {...tailFormItemLayout}
                    >
                        <Checkbox checked={agreed}
                            onChange={e => setAgreed(e.target.checked)}>
                            I agree to the <Link to="/terms">Terms</Link>
                        </Checkbox>
                    </Form.Item>
                    {/* <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" loading={loading}
                                disabled={!agreed}
                                style={{ width: '60%' }}
                            >
                                Create Account
                            </Button>
                        </Form.Item> */}
                    <Row justify="space-between" style={{ marginBottom: 20 }}>
                        <Col span={10}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loading}
                                disabled={!agreed}
                                style={{ width: '100%' }}
                            >
                                {loading ? messages[messageIndex] : 'OK'}
                            </Button>
                        </Col>
                        <Col span={10}>
                            <Button
                                className="login-form-button"
                                onClick={handleCancel}
                                style={{ width: '100%' }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                    <Form.Item>
                        Already have an account?
                        <Button type="link" onClick={props.goToOtherModal}>Sign In</Button>
                    </Form.Item>
                </Form>
            </Modal>

        </div >
    );
};

export default App;






{/* <Form.Item
    name="confirm"
    // label="Confirm Password"
    dependencies={['password']}
    hasFeedback
    rules={[
        {
            required: true,
            message: 'Please confirm your password',
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
        }),
    ]}
>

    <Input.Password
        name="confirm"
        prefix={<SyncOutlined style={{ opacity: 0.3 }}  />}
        type="password"
        placeholder="Input the password again"
    />
</Form.Item >  */}
import React from 'react';

const AuthUserContext = React.createContext(null);   // default export

export const LanguageContext = React.createContext({
    language: null,
    setLanguage: () => { }
});

export const withLanguageConsumer = (Component) => (props) => (
    <LanguageContext.Consumer>
        {language => <Component {...props} language={language} />}
    </LanguageContext.Consumer>
);



export const TooltipContext = React.createContext({
    tooltip: null,
    setTooltip: () => { }
});

export const withTooltipConsumer = (Component) => (props) => (
    <TooltipContext.Consumer>
        {tooltip => <Component {...props} tooltip={tooltip} />}
    </TooltipContext.Consumer>
);





export default AuthUserContext;
import React from 'react';
import { Tour } from 'antd';
import { MenuOutlined, BulbOutlined } from '@ant-design/icons';

export const CustomTour = (props) => {



    const steps = [
        {
            title: 'Write down anything you\'d like to record',
            description: 'The Parrot will figure out what time it happened and save it for you.',
            cover: (
                <img
                    alt="example.gif"
                    src="/images/example1.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'Feel free to omit the time altogether',
            description: 'The Parrot will assume the event happened or ended just now.',
            cover: (
                <img
                    alt="example2.gif"
                    src="/images/example2.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'You can record the start and end time separately',
            description: 'e.g. say "started" doing something and "finished" or "done" doing something. After a few seconds, the parrot will automatically link them if they aren\'t too far apart. Or you can link them manually.',
            cover: (
                <img
                    alt="example3.gif"
                    src="/images/example3.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'You can use previous events as reference',
            description: 'The Parrot understands context and will use that to figure out when the new event happened.',
            cover: (
                <img
                    alt="example4.gif"
                    src="/images/example4.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            // target: () => props.ref1.current,
        },
        {
            title: 'Remove or edit records in your notes',
            description: 'You can also teach the Parrot by checking a box.',
            cover: (
                <img
                    alt="example5.gif"
                    src="/images/example5.gif"
                    style={{ marginTop: 8 }}
                />
            ),
            nextButtonProps: { onClick: props.onFinishTour }  // this is the second last step, but consider it finished if click next -- so that if user closes the tour by cross, it will not show again
            // target: () => props.ref2.current,
        },
        {
            title: 'That\'s all for this page! There are more tips on other pages.',
            description: <> To see these tips again, press the <MenuOutlined /> button at the top right corner of the page, then press <BulbOutlined /></>,
            nextButtonProps: { onClick: props.onFinishTour }
        },
    ];

    return <Tour
        open={props.open}
        steps={steps}
        onClose={props.onCloseTour}
        disabledInteraction={true}
        style={{ maxWidth: props.screenWidth }}
    />

};


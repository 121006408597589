import React, { Component } from "react";

import { Button } from "antd";

import PasswordChangeForm from "../PasswordChange";


class PwChangeApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      pwchangeVisible: false,
    };
  }

  render() {

    // console.log('PwChangeApp props', this.props);

    return (

      <>
        <PasswordChangeForm
          visible={this.state.pwchangeVisible}
          handleCancel={() => this.setState({ pwchangeVisible: false })}
          authUser={this.props.authUser}
          onSubmit={() => { }}
        />

        <Button type="link" onClick={() => this.setState({ pwchangeVisible: true })}>
          Change Password
        </Button>

      </>


    );
  }
}


export default PwChangeApp;
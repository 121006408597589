import React, { useState, useContext, useEffect, useRef } from 'react';
import { Form, Input, Button, message, Row, Col, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../Firebase';
import { sendPasswordResetEmail } from "firebase/auth";

const MESSAGE_DURATION = 3; // Define message duration as a constant

const PasswordForgetForm = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);

  // Initialize message API
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!props.visible) {
      setLoading(false);
    }
  }, [props.visible]);

  // Display a message with a specific type, content, and duration
  const displayMessage = (type, content, duration = MESSAGE_DURATION) => {
    return messageApi.open({
      type,
      content,
      duration,
    });
  };

  const handleCancel = () => {
    // this.setState({ alert: false });
    form.resetFields();
    props.handleCancel();
  };

  // Handle form submission
  const onFinish = async (values) => {
    const { email } = values;

    setLoading(true);

    try {
      await sendPasswordResetEmail(firebase.auth, email);
      displayMessage('success', 'If that account exists, password reset email has been sent.', 3);
      form.resetFields();
      props.handleCancel();
    } catch (error) {
      console.error('Password reset error', error);
      const formattedError = error.code?.replace('auth/', '').replace('-', ' ') || '';
      displayMessage('error', formattedError);
      // here I just use handleCancel instead of a dedicated handleFinish
      props.handleCancel();
    }
  };

  return (
    <div className="login-form">
      {contextHolder}
      <Modal
        open={props.visible}
        title="Password Reset"
        onCancel={handleCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        width={440}
      >
        <Form
          onFinish={onFinish}
          name="password_forget"
          form={form}
          className="form-container"

        >
          <Form.Item
            name="email"
            rules={[{ type: 'email', message: 'Not a valid email address' }, { required: true, message: ' ' }]}
            validateTrigger={['onBlur']}

          >
            <Input
              allowClear
              name="email"
              prefix={<MailOutlined style={{ opacity: 0.3 }} />}
              placeholder="Email address"
            />
          </Form.Item>

          <Form.Item>
            <Row justify="space-between" gutter={8} style={{ marginBottom: 20 }}>
              <Col span={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                // style={{ width: '100%' }}
                >
                  Reset My Password
                </Button>
              </Col>
              <Col span={10}>
                <Button
                  type="default"
                  className="login-form-button"
                  onClick={handleCancel}
                // style={{ width: '100%' }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PasswordForgetForm;


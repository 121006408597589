import moment from 'moment-timezone';


export const formatDurationCompact = (durationInMinutes) => {
    // Calculate days, hours, and minutes
    const days = Math.floor(durationInMinutes / (24 * 60));
    const hours = Math.floor((durationInMinutes % (24 * 60)) / 60);
    const minutes = durationInMinutes % 60;

    // Format the string in compact format
    let formattedDuration = '';
    if (days > 0) {
        formattedDuration += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are any days
        formattedDuration += `${hours}h `;
    }
    if (minutes > 0) {// Include minutes if there are any minutes
        formattedDuration += `${minutes}m`;
    }

    return formattedDuration.trim();
}



export const formatMarkdownContent = (eDetails, timeFormat = 'ddd, YYYY-MM-DD, h:mm A', omit = null) => {
    return eDetails.map((record) => {
        const startTimeFormatted = moment.unix(record.time).format(timeFormat);
        let recordString = `- "**${record.event}**"`;

        if (record.end_time) {
            const endTimeFormatted = moment.unix(record.end_time).format(timeFormat);

            const shortenTimeFormat = timeFormat.includes('h:mm A') ? 'h:mm A' : 'h:mma';

            const startDateOnly = moment.unix(record.time).format('ddd, YYYY-MM-DD');
            const endDateOnly = moment.unix(record.end_time).format('ddd, YYYY-MM-DD');



            const roundedDurationInMinutes = Math.round((record.end_time - record.time) / 60);

            recordString += ` (**${formatDurationCompact(roundedDurationInMinutes)}**,`;

            // Check if start date and end date are the same and omitting is requested
            if (startDateOnly === endDateOnly && omit) {
                if (omit === 'start') {
                    recordString += ` **${moment.unix(record.time).format(shortenTimeFormat)}** - **${endTimeFormatted}**)`;
                } else if (omit === 'end') {
                    recordString += ` **${startTimeFormatted}** - **${moment.unix(record.end_time).format(shortenTimeFormat)}**)`;
                }
            } else {
                // Include both start and end if they are different or no omitting is requested
                recordString += ` **${startTimeFormatted}** - **${endTimeFormatted}**)`;
            }



        } else {
            recordString += ` (**${startTimeFormatted}**)`;
        }

        return recordString;
    }).join('\n');
};




// utility function to be used upon componnent mount
// const oneTimeReverseMappingEdits = async () => {


//     if (uid && db) {
//         const uEditsRef = ref(db, `u_edits/${uid}`);

//         // Fetch this user's edits
//         get(uEditsRef).then((snapshot) => {
//             const updates = {};

//             // even though snapshot is not an array, firebase allows the forEach method to be used on it
//             snapshot.forEach(sourceSnapshot => {
//                 const sourceId = sourceSnapshot.key;

//                 // Accessing 'span' directly from the snapshot
//                 const spanSnapshot = sourceSnapshot.child('span');
//                 if (spanSnapshot.exists()) {
//                     spanSnapshot.forEach(targetSnapshot => {
//                         const targetId = targetSnapshot.key;

//                         // Construct the reverse mapping path relative to the base reference `uEditsRef`
//                         const reversePath = `${targetId}/of_span/${sourceId}`;
//                         // Set the reverse mapping to true (or any value that signifies a connection)
//                         updates[reversePath] = true;  // Adding the reverse path
//                     });
//                 }
//             });

//             console.log('updates:', updates);

//             // Perform the batch update at the base reference to include all paths
//             update(uEditsRef, updates).then(() => {
//                 console.log('Reverse mappings updated successfully');
//             }).catch(error => {
//                 console.error('Error updating reverse mappings:', error);
//             });

//         }).catch(error => {
//             console.error('Error fetching user edits:', error);
//         });

//     }
// };



// ran compareRecords and turns out only old records have no corresponding chat keys since they were not associated by the app at that time
// const compareRecords = async () => {
//     const userChatRef = ref(db, `u_chats/${uid}`);
//     get(userChatRef).then((chatSnapshot) => {
//         const chatData = chatSnapshot.val();
//         let chatResponseIds = {}; // Using an object instead of an array

//         Object.entries(chatData).forEach(([chatKey, chat]) => {
//             if (chat.response && typeof chat.response === 'object') {
//                 Object.entries(chat.response).forEach(([_, recordId]) => {
//                     chatResponseIds[recordId] = chatKey; // Storing chatKey associated with each recordId
//                 });
//             }
//         });

//         const userRecordsRef = ref(db, `u_records/${uid}`);
//         get(userRecordsRef).then((recordsSnapshot) => {
//             const recordsData = recordsSnapshot.val();
//             let recordIds = {}; // Using an object instead of an array
//             Object.entries(recordsData).forEach(([recordId, val]) => {
//                 recordIds[recordId] = val.message_id; // Storing uid associated with each recordId for simplicity
//             });

//             // Comparing both sets of IDs to find unmatched ones
//             const onlyInChats = Object.entries(chatResponseIds).filter(([id, cid]) => !recordIds.hasOwnProperty(id));
//             const onlyInRecords = Object.entries(recordIds).filter(([id, cid]) => !chatResponseIds.hasOwnProperty(id));

//             console.log("IDs only in chats with chat keys:", onlyInChats);
//             console.log("IDs only in records with chat ids:", onlyInRecords);
//         });
//     });
// };



// this may not be needed after the chat message queue modification

// const handleSendOneMessage = async (messageObj) => {

//     const startTimestamp = Date.now();
//     setResponseTime(null);

//     const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//     const firebaseToken = await user.getIdToken();

//     // Generate the current UTC Unix timestamp in milliseconds
//     const utcTimestamp = messageObj.time;
//     const msg = messageObj.text;
//     // setChat(prevChat => [...prevChat, { ...messageObj, status: 'sending' }]);

//     try {
//         const res = await sendChat(msg, timezone, firebaseToken, utcTimestamp);

//         if (res?.status === 200) {

//             const message = res.data && res.data.message;
//             const recorded = Array.isArray(message);

//             const cmessage = recorded ? message.map(m => m.key) : message;
//             // const details = recorded ? message : [message];

//             // // Update the status of the last chat item
//             // setChat(prevChat => prevChat.map((item, index) =>
//             //     index === prevChat.length - 1 ? { ...item, response: cmessage, status: 'sent' } : item
//             // ));

//             // add a chat item to the chat list
//             setChat(prevChat => [...prevChat, { ...messageObj, response: cmessage, status: 'sent' }]);

//             // const newVal = { details, textTime: utcTimestamp, text: msg, timezone, recorded };

//             // setEventDetailsMap(prev => ({ ...prev, [utcTimestamp]: newVal }));

//             setEventDetailsMap(prev => ({ ...prev, [utcTimestamp]: message }));

//             // const displayMessage = `recorded "${details[0].event}" (${moment.unix(details[0].time).format('h:mma')}` + (details[0].end_time ? `-${moment.unix(details[0].end_time).format('h:mma')})` : ')') + (details.length > 1 ? ` and ${details.length - 1} other event(s)` : '');

//             if (recorded) {

//                 showMsg(message, utcTimestamp, 'success', 4, <><CheckCircleFilled fill='green' />Recorded</>);
//             } else {
//                 showWarning('Didn\'t manage to record', 'warning', 2);
//                 console.error(message);
//             }



//             setResponseTime(Date.now() - startTimestamp);
//         }
//     } catch (error) {
//         console.error('Error sending message:', error);
//         setChat(prevChat => prevChat.map((item, index) =>
//             index === prevChat.length - 1 ? { ...item, status: 'error' } : item
//         ));
//     }

// };












import React, { useState, useContext, useEffect } from 'react';
import { Typography, Row, Button } from 'antd';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { FirebaseContext } from '../Firebase';

import moment from 'moment-timezone';

import { createPortalSession } from '../../functions';


// import { useNavigate } from 'react-router-dom';
const { Text, Title } = Typography;

// const STRIPE_CUS_PORTAL_REDIRECT_URL = process.env.NODE_ENV === 'production' ?
//     `https://billing.stripe.com/p/login/eVa6rBevZa6O7QI144` : `https://billing.stripe.com/p/login/test_eVa29C6KP3Ok4j6288`;


const messages = ['', "Just a moment...", "Hold on...", "Finishing up..."];

const MySubscription = (props) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [messageIndex, setMessageIndex] = useState(0);

    const firebase = useContext(FirebaseContext);
    const user = firebase.auth.currentUser;

    // useEffect(() => {
    //     let interval;

    //     if (loading && messageIndex < messages.length - 1) {
    //         // Setup the interval only if loading and there are more messages to show
    //         interval = setInterval(() => {
    //             setMessageIndex(prevIndex => {
    //                 if (prevIndex < messages.length - 1) {
    //                     return prevIndex + 1;
    //                 } else {
    //                     clearInterval(interval); // Clear the interval when no more messages are left to show
    //                     return prevIndex; // Return current index to avoid further changes
    //                 }
    //             });
    //         }, 1000);  // Update the message every 1 second
    //     }

    //     // Cleanup function to clear the interval
    //     return () => {
    //         if (interval) {
    //             clearInterval(interval);  // Clear the interval if it was set
    //         }
    //     };
    // }, [loading]);  // Include messageIndex and messages.length to properly track changes

    useEffect(() => {

        let interval;
        // Only setup interval if loading is true
        if (loading) {
            interval = setInterval(() => {
                setMessageIndex(prevIndex => {
                    // Check if the next index would exceed the bounds of the messages array
                    if (prevIndex >= messages.length - 1) {
                        clearInterval(interval); // Clear interval if it's the last message
                        return prevIndex; // Keep the index at the last message
                    } else {
                        return prevIndex + 1; // Increment the message index
                    }
                });
            }, 2000);
        }
        else {
            // Reset messageIndex to zero when loading is false
            setMessageIndex(0);
        }

        // Cleanup function to clear the interval
        return () => {
            if (interval) {
                clearInterval(interval);
                setMessageIndex(0); // Reset the message index when the interval is cleared
            }
        };

    }, [loading]); // Only re-run the effect when `loading` changes


    const handleManagePortal = async () => {

        setLoading(true);
        setError('');

        try {

            const firebaseToken = await user.getIdToken();

            const response = await createPortalSession(user.uid, firebaseToken);
            if (response.status === 200 && response.data?.url) {
                const portal_url = response.data.url;

                // Perform URL validation here if necessary
                // if (!portal_url.startsWith('https://stripe.com')) {
                //     throw new Error('Invalid portal URL received.');
                // }

                if (Capacitor.isNativePlatform()) {
                    try {
                        await Browser.open({ url: portal_url });
                    } catch (error) {
                        console.error('Failed to open browser:', error);
                        throw new Error('Failed to open the native browser.');
                    }
                } else {
                    // Fallback for web platforms
                    window.location.href = portal_url;
                }

            } else {
                console.error('Failed to start portal:', response.data?.error);
                setError(response.data?.error || 'Failed to retrieve portal URL.');
            }
        } catch (error) {
            console.error('Error during starting portal:', error);
            setError('An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }

    };






    return (
        <>
            <Row type="flex" justify="center" style={{ marginTop: 40 }}>
                <Title level={3}>My Subscription</Title>
            </Row>

            <Row type="flex" justify="center">
                <Text type="secondary">{props.authUser?.level} subscription</Text>
            </Row>

            <Row type="flex" justify="center">
                <Text> expires  {moment.unix(props.authUser.current_period_end).format('MMM D, YYYY')
                } ({moment.unix(props.authUser.current_period_end).fromNow()})</Text>
            </Row>

            <Row type="flex" justify="center" style={{ marginTop: 40 }}>
                <Button type="primary" onClick={handleManagePortal} loading={loading} disabled={loading}>Manage My Subscription</Button>
            </Row>

            <Row type="flex" justify="center">
                <p style={{ color: '#00a9a8' }}>{loading ? messages[messageIndex] : ' '}</p>
            </Row>
        </>
    )

}


export default MySubscription;
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

const HourInput = ({ value, onChange }) => {
    const [internalValue, setInternalValue] = useState('');
    const [focused, setFocused] = useState(false);

    // Sync external value changes with internal state
    useEffect(() => {
        // console.log('value is', value);
        setInternalValue(value !== null && value !== undefined ? value.toString() : '');

    }, [value]);

    const handleChange = (e) => {
        const newValue = e.target.value.replace(/[^\d]/g, '');
        if (newValue === '') {
            setInternalValue('');
        }

        if (newValue >= 0 && newValue <= 9) {
            setInternalValue(newValue);
            onChange(newValue);  // Call the provided onChange handler
        }
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
        if (internalValue === '') {
            setInternalValue('0');
            onChange(0);
        }
    };

    const displayValue = focused ? internalValue : `${internalValue}h`;

    return (
        <Input
            value={displayValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            maxLength={2} // 1 for the number, 1 for 'h'
            style={{ width: 30, padding: '0 5px' }}  // Matches the original InputNumber width
        />
    );
};

export default HourInput;


